<template>
  <div
    class="page"
    data-page="form"
  >
    <FormHeader @toggle:drawer="toggleDrawer" />
    <div class="container pt-4">
      <div
        class="page-form-row row"
        :style="{ display: $store.getters.getLoading ? 'none' : 'block' }"
      >
        <template v-if="form">
          <div class="col-3 card nav-card">
            <FormNavigation
              :sections="form.Sections"
              :is-root="true"
            />
          </div>
          <div
            ref="mainCard"
            class="col-6 card main-card"
          >
            <Carousel v-model:activeSlide="activeSlide">
              <template #slides="slotProps">
                <Slide
                  v-for="(page, index) in form.Fields"
                  :key="page.Id"
                  v-model:activeSlide="activeSlide"
                  :index="index"
                  :styles="(slotProps || {}).styles || {}"
                  :disable-footer="true"
                >
                  <Field
                    :definition="page"
                    @update:value="onChange"
                  />
                </Slide>
              </template>
            </Carousel>
          </div>
          <div class="col-3 card">
            <OfficerCard />
          </div>
          <div class="nav-card-mobile">
            <FormNavigation
              :sections="form.Sections"
              :is-root="true"
              :is-mobile="true"
            />
          </div>
        </template>
      </div>
    </div>

    <LocalizationPicker />
    <FooterDisclaimer />

    <Drawer
      v-model:position="drawerPosition"
      :size="260"
      :open="drawerOpen"
      @toggle:drawer="toggleDrawer"
    >
      <OfficerCard />
      <div class="btn-logout-container">
        <button 
          class="btn btn-block btn-default btn-logout"
          @click="logoutClick"
        >
          <Localize :text="`{{s1003_menu_logout}}`" />
        </button>
      </div>
    </Drawer>
  </div>
</template>

<script>
import FormHeader from '@/components/FormHeader.vue'
import Carousel from '@/components/FormCarousel.vue'
import Slide from '@/components/FormCarouselSlide.vue'
import FormNavigation from '@/components/FormNavigation.vue'
import Drawer from '@/components/FormDrawer.vue'
import OfficerCard from '@/components/OfficerCard.vue'
import Field from '@/fields/FormField.vue'
import FooterDisclaimer from '@/components/FooterDisclaimer.vue'
import Localize from '@/components/TextLocalize.vue'
import LocalizationPicker from '@/components/LocalizationPicker.vue'

export default {
  name: 'PageForm',
  components: {
    FormHeader,
    Carousel,
    Slide,
    FormNavigation,
    Drawer,
    OfficerCard,
    Field,
    FooterDisclaimer,
    Localize,
    LocalizationPicker
  },
  data: () => {
    return {
      drawerOpen: false,
      drawerPosition: 'right',

      updateHeartbeat: null,
      fieldUpdates: []
    }
  },
  computed: {
    form: function () {
      return this.$store.getters.getForm
    },
    activeSlide: function () {
      return this.$store.getters.getActivePageIndex
    }
  },
  watch: {

  },
  mounted: function () {
    var $this = this

    $this.updateHeartbeat = setInterval(() => { $this.$store.dispatch('flushUpdates') }, 10000)
  },
  unmounted: function () {
    clearInterval(this.updateHeartbeat)
  },
  methods: {
    toggleDrawer: function () {
      this.drawerOpen = !this.drawerOpen
    },
    onChange: function ($event) {
      var base = this.$store.getters.getFormValue($event.Id)
      var copy = []

      // Copy the form value so that we aren't keeping a reference pointer to something we are going to modify
      for (var i = 0; i < base.length; i++)
        copy.push(base[i])
      
      this.$store.commit('addUpdate', { 
        BorrowerID: $event.Borrower,
        FieldID: $event.Id,
        Values: copy
      })
    },
    logoutClick: function () {
      var $this = this

      $this.$store.dispatch('logout')
        .then(function () {
          $this.$store.commit('updateBorrowers', [])
          $this.$store.commit('becomeBorrower', null)
          $this.$store.commit('resetForm')
          $this.$store.dispatch('go', 'start')
        })
    }
  }
}
</script>

<style scoped lang="scss">
.page[data-page='form'] {

  .page-form-row {
    padding-bottom: 100px;
  }

  .nav-card {
    @media (max-width: 992px) {
      display: none;
    }
  }

  .nav-card-mobile {
    display: none;
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: white;
    //height: 65px;
    box-shadow: 6px 6px 6px 6px #d8dada;
    z-index: 1;

    @media (max-width: 992px) {
      display: block;
    }
  }

  .btn-logout-container {
    text-align: center;

    .btn-logout {
      width: 85%;
    }
  }
}
</style>
