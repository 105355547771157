<template>
  <div
    class="input-wrapper"
    :class="{ invalid: !isValid }"
  >
    <label
      v-if="safeDefinition.Text && !safeDefinition._HideTitle"
      :for="indexedId"
      :title="localized(safeDefinition.Text)"
    ><Localize :text="safeDefinition.Text" /></label>
    <div
      class="input-currency-wrapper show-placeholder"
      :class="{ 'active-placeholder': (displayValue || '').length > 0 }"
    >
      <input
        :id="indexedId"
        :required="safeDefinition.Required"
        type="number"
        :placeholder="localized(safeDefinition.Placeholder)"
        class="input-currency"
        step="0.01"
        :value="displayValue"
        @input="onChange"
      >
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

const Localize = defineAsyncComponent(function () { return import('@/components/TextLocalize.vue') })
import Lib from '@/services/fields.js'
import Globals from '@/services/globals.js'
import Terms from '@/services/terms.js'

export default {
  name: 'FieldCurrency',
  components: {
    Localize
  },
  props: {
    definition: {
      required: true,
      validator: function (value) {
        if (process.env.NODE_ENV == 'development' && !value)
          return true

        if ((value || {}).Type !== Globals.FIELD_TYPES.FIELD_TYPE_CURRENCY)
          return false

        if (typeof (value || {}).Id !== 'string')
          return false

        if (typeof(value || {}).Text !== 'string')
          return false

        return true
      }
    },
    groupIndex: {
      type: Number,
      default: 0
    }
  },
  emits: [
    'update:value'
  ],
  data: () => {
    return {
      touched: false
    }
  },
  computed: {
    safeDefinition: function () {
      return Lib.safeDefinition(this)
    },
    isValid: function () {
      return Lib.isValid(this)
    },
    displayValue: function () {
      return Lib.getDisplayValue(this.safeDefinition, this.groupIndex)
    },
    indexedId: function () {
      return Lib.indexedId(this.safeDefinition, this.groupIndex)
    }
  },
  methods: {
    onChange: function ($event) {
      Lib.updateDisplayValue(this.safeDefinition, this.groupIndex, $event.target.value, this.$emit)
      this.touched = true
    },
    localized: function (str) {
      return Terms.replace(str)
    }
  }
}
</script>