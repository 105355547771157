<template>
  <Slide
    :active-slide="activeSlide"
    class="slide-co-borrowers"
    :index="slideIndex"
    :button-callback="onComplete"
    :button-label="`{{s1003_start_continue}}`"
    :styles="slideStyles"
  >
    <div class="container">
      <h2 class="bold">
        <Localize :text="`{{s1003_system_coborrower_description1}}`" />
      </h2>

      <template
        v-for="(id, index) in borrowerId"
        :key="id"
      >
        <div class="borrower-wrapper">
          <div class="borrower-title">
            <div
              class="borrower-delete float-right"
              @mousedown="removeBorrower(index)"
            >
              <i class="fa-solid fa-minus-circle" /> <Localize :text="`{{s1003_system_coborrower_remove}}`" />
            </div>

            <h4><Localize :text="`{{s1003_system_borrower}}`" /> {{ index + 2 }}</h4>
          </div>

          <div class="slide-left no-label">
            <FieldText 
              :definition="fields.firstName" 
              :locked-borrower="id"
              @update:value="onFirstNameChange($event, id)"
            />
          </div>

          <div class="slide-right no-label">
            <FieldText 
              :definition="fields.lastName" 
              :locked-borrower="id"
              @update:value="onLastNameChange($event, id)"
            />
          </div>

          <div class="slide-left no-label">
            <FieldText 
              :definition="fields.email" 
              :locked-borrower="id"
            />
          </div>

          <div class="slide-right no-label">
            <FieldText 
              :definition="fields.phone" 
              :locked-borrower="id"
            />
          </div>

          <label :for="'smart1003_borrower_married_to' + id"><Localize :text="`{{s1003_system_coborrower_married}}`" /></label>
          <FieldMultipleChoice
            :definition="fields.married"
            :locked-borrower="id"
            :options-override="filteredSpouses.bind(this, id)"
            @update:value="onMarriedToChange(id)"
          />

        <!--
          <label :for="'start_coborrower_behalf' + id">Are you filling out the application on behalf of this borrower? Or should we invite them to fill it out themselves?</label>
          <FieldMultipleChoice 
            :definition="fields.behalf" 
            :locked-borrower="id"
          />
        -->
        </div>
      </template>

      <div class="additional-borrower-wrapper text-center">
        <a
          href="#"
          @click.prevent
          @mousedown="addBorrower"
        ><Localize :text="`{{s1003_system_coborrower_add}}`" /></a>
      </div>

      <div class="agreements-wrapper">
        <Localize :text="`{{s1003_start_privacy_policy_description}}`" /> <a @click="togglePrivacyPolicy"><Localize :text="`{{s1003_start_privacy_policy_title}}`" /></a>
        <Localize :text="`{{s1003_start_edisclose_description}}`" /> <a @click="toggleEConsent"><Localize :text="`{{s1003_start_edisclose_title}}`" /></a>
      </div>
    </div>
  </Slide>
</template>

<script>
import { defineAsyncComponent } from 'vue'

import lib from '@/services/fields.js'

import Definitions from '@/services/definitions.js'
import FieldMultipleChoice from '@/fields/input/FieldMultipleChoice.vue'
import FieldText from '@/fields/input/FieldText.vue'
import Slide from '@/components/FormCarouselSlide.vue'
const Localize = defineAsyncComponent(function () { return import('@/components/TextLocalize.vue') })

export default {
  name: 'SlideCoBorrower',
  components: {
    FieldMultipleChoice,
    FieldText,
    Slide,
    Localize
  },
  props: {
    activeSlide: {
      type: Number,
      default: 0
    },
    slideStyles: {
      type: Object,
      default: function () {
         return {}
      }
    },
    slideIndex: {
      type: Number,
      default: 0
    }
  },
  emits: [
    'update:borrowerid',
    'update:privacypolicy',
    'update:econsent',
    'update:continue',
    'update:back'
  ],
  data: () => {
    return {
      fields: {
        firstName: Definitions.start.createCoborrowerFirstName(),
        //middleName: definitions.start.createCoborrowerMiddleName(),
        lastName: Definitions.start.createCoborrowerLastName(),
        //suffix: definitions.start.createCoborrowerSuffix(),
        email: Definitions.start.createCoborrowerEmail(),
        phone: Definitions.start.createCoborrowerPhone(),
        married: Definitions.start.createCoborrowerMarried(),
        behalf: Definitions.start.createCoborrowerBehalf()
      }
    }
  },
  computed: {
    companyName: function () {
      return this.$store.getters.getCompanyName
    },
    borrowers: function () {
      var result = []
      var borrowers = this.$store.getters.getBorrowers

      // Skip primary borrower
      for (var i = 1; i < borrowers.length; i++)
        result.push(borrowers[i])

      return result
    },
    borrowerId: function () {
      var result = []

      for (var i = 0; i < this.borrowers.length; i++)
        result.push(this.borrowers[i].ID)

      return result
    }
  },
  methods: {
    togglePrivacyPolicy: function () {
      this.$emit('update:privacypolicy')
    },
    toggleEConsent: function () {
      this.$emit('update:econsent')
    },
    addBorrower: function() {
      this.$store.dispatch('addBorrower')
    },
    removeBorrower: function(index) {
      var removeId = this.borrowerId[index]

      this.$store.commit('removeBorrower', removeId)
      this.$store.commit('removeBorrowerValues', removeId)

      if (this.borrowerId.length === 0)
        this.$emit('update:back')
    },
    getBorrowerInfo: function (borrowerId) {
      return this.$store.getters.getFormValueAtIndex('smart1003_borrower_info', 0, borrowerId) || {
        FirstName: null,
        MiddleName: null,
        LastName: null,
        Suffix: null,
        Email: null,
        Phone: null
      }
    },
    onFirstNameChange: function ($event, borrowerId) {
      this.$store.commit('setFormValueAtIndex', { 
        BorrowerID: borrowerId, 
        FieldID: 'smart1003_borrower_info', 
        Value: {...this.getBorrowerInfo(borrowerId), ...{ FirstName: $event.Value }} 
      })
    },
    onMiddleNameChange: function ($event, borrowerId) {
      this.$store.commit('setFormValueAtIndex', { 
        BorrowerID: borrowerId, 
        FieldID: 'smart1003_borrower_info', 
        Value: {...this.getBorrowerInfo(borrowerId), ...{ MiddleName: $event.Value }} 
      })
    },
    onLastNameChange: function ($event, borrowerId) {
      this.$store.commit('setFormValueAtIndex', { 
        BorrowerID: borrowerId, 
        FieldID: 'smart1003_borrower_info', 
        Value: {...this.getBorrowerInfo(borrowerId), ...{ LastName: $event.Value }} 
      })
    },
    onSuffixChange: function ($event, borrowerId) {
      this.$store.commit('setFormValueAtIndex', { 
        BorrowerID: borrowerId, 
        FieldID: 'smart1003_borrower_info', 
        Value: {...this.getBorrowerInfo(borrowerId), ...{ Suffix: $event.Value }} 
      })
    },
    onMarriedToChange: function(firstPartnerId) {
      // married_to value can be 'no', so use string matching throughout
      firstPartnerId = firstPartnerId + ''

      var borrowers = this.$store.getters.getBorrowers
      var secondPartnerId = this.$store.getters.getFormValueAtIndex('smart1003_borrower_married_to', 0, firstPartnerId) + ''

      // Update partner to point back to self
      this.$store.commit('setFormValueAtIndex', { 
        BorrowerID: secondPartnerId, 
        FieldID: 'smart1003_borrower_married_to', 
        GroupIndex: 0, 
        Value: firstPartnerId 
      })

      for (var i = 0; i < borrowers.length; i++) {
        var borrowerId = borrowers[i].ID + ''

        if (borrowerId === firstPartnerId || borrowerId === secondPartnerId)
          continue // Already up to date
        else {
          // Clear anyone outside the pair who is married to a member of the pair
          var currentlyMarriedTo = this.$store.getters.getFormValueAtIndex('smart1003_borrower_married_to', 0, borrowerId) + ''

          if (currentlyMarriedTo === firstPartnerId || currentlyMarriedTo === secondPartnerId) {
            this.$store.commit('setFormValueAtIndex', {
              BorrowerID: borrowerId, 
              FieldID: 'smart1003_borrower_married_to', 
              GroupIndex: 0, 
              Value: 'no' 
            })
          }
        }
      }
    },
    filteredSpouses: function(borrowerId) {
      var filtered = [{ Text: 'No', Value: 'no' }]
      var borrowers = this.$store.getters.getBorrowers

      for (var i = 0; i < borrowers.length; i++) {
        if (borrowers[i].ID === borrowerId)
          continue

        filtered.push({
          Text: 'Yes, they are married to ' 
            + ((this.$store.getters.getFormValueAtIndex('smart1003_borrower_info', 0, borrowers[i].ID) || {}).FirstName || '') 
            + ' ' 
            + ((this.$store.getters.getFormValueAtIndex('smart1003_borrower_info', 0, borrowers[i].ID) || {}).LastName || ''),
          Value: borrowers[i].ID
        })
      }

      return filtered
    },
    onComplete: function ($event) {
      this.$emit('update:continue', $event)
    },
    validate: function () {
      var validation = []

      for (var prop in this.fields)
        validation = validation.concat(lib.validate(this.fields[prop], 0, this.borrowers))

      if (validation.length === 0)
        return true
      
      for (var i = 0; i < validation.length; i++)
        this.$store.commit('openToast', validation[i])

      return false
    }
  }
}
</script>

<style scoped lang="scss">
.borrower-title {
  margin-bottom: 0;

  h4 {
    font-weight: bold;
  }

  .borrower-delete {
    cursor: pointer;
  }
}

label {
  display: block;
  margin-top: 10px;
}

.borrower-wrapper {
  margin-bottom: 1rem;
}

.additional-borrower-wrapper {
  padding-top: 1rem;
}

.slide-left, .slide-right {
  display: inline-block;
  width: 50%;
  vertical-align: top;

  &.no-label {
    padding-top: 0.5rem;
  }
}

.slide-left {
  padding-right: 1rem;
}

.slide-right {
  padding-left: 1rem;
}
</style>
