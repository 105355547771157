import network from './network'
import store from '../store/store'
import Utils from '@/services/utils.js'

/* eslint-disable no-console, no-irregular-whitespace */
const vm = {
  loadCompany: function(companyid) {
    if (!companyid) 
      return new Promise(function (resolve, reject) {
        resolve(null)
      })

    return network.api('Company/PublicDetails', {
      CompanyID: companyid
    })
    .then(r => {
      if ((((r || {}).data || {}).Companies || []).length > 0)
        return r.data.Companies[0]
      return null
    })
  },

  loadOfficer: function (companyid, officerid) {
    if (!companyid || !officerid)
      return new Promise(function (resolve, reject) {
        resolve({ Profile: null, Theme: null, Config: null })
      })

    return network.api('User/PublicProfile', {
      CompanyID: companyid,
      UserID: officerid
    })
    .then(r => {
      if (!!((r || {}).data || {}).Profile)
        return r.data
      return null
    })
  },

  searchOfficer: function (name, zip) {
    return network.api('User/PublicList', {
      CompanyID: store.getters.getCompany.CompanyID,
      Search: name,
//      Zip: zip,
      Count: 5
    })
    .then(r => {
       return (r || {}).data
    })
  },

  getConfig: function (companyid, officerid) {
    if (!companyid)
      return new Promise(function (resolve, reject) {
        resolve(null)
      })

    return network.api('Config/General/Lookup', {
      CompanyID: companyid,
      UserID: officerid,
      Compile: true
    })
    .then(r => {
      return ((r || {}).data || {}).Config
    })
  },
  
  loadForm: function (companyid, officerid, realtorid) {
    var args = {
      FormName: 'Smart1003',
      UserID: officerid,
      CompanyID: companyid
    }

    if (realtorid)
      args.AdditionalData = {
        RealtorID: realtorid
      }

    return network.api('Form/Structure', args)
    .then(r => {
      return r
    })
  },

  loadSave: function () {
    var args = {
      FormName: 'Smart1003',
      LoanID: store.getters.getLoanId
    }

    return network.api('Form/Values', args)
    .then(r => {
      return r
    })
  },

  submitFields: function (fields) {
    var args = {
      FormName: 'Smart1003',
      LoanID: store.getters.getLoanId,
      Updates: fields
    }

    return network.api('Form/SubmitField', args)
  },

  submitForm: function (linkdata, device) {
    return network.api('Form/Submit', {
      FormName: 'Smart1003',
      SubmitData: {
        CompanyID: store.getters.getCompany.CompanyID,
        UserID: (store.getters.getOfficer || {}).UserID || null,
        LoanID: store.getters.getLoanId,
        LinkData: linkdata,
        Values: {
          smart1003_borrower_device: Utils.getDeviceCode()
        }
      }
    })
    .then(r => {
      return (r || {}).data
    })
  },

  searchAddressFragment: function (fragment, latitude, longitude) {
    return new Promise((resolve, reject) => {
      if (((fragment || "").length || 0) < 1) {
        resolve([])
        return
      }

      var url = process.env.VUE_APP_AZURE_MAPS_URL + '&query=' + encodeURI(fragment)

      if (latitude)
        url = url + '&lat=' + latitude
      if (longitude)
        url = url + '&lon=' + longitude

        network.get(url)
          .then(function (result) {
            var suggestions = []

            for (var i = 0; i < (((result || {}).data || {}).results || []).length || 0; i++) {
              if (suggestions.length > 4)
                break

              if (result.data.results[i].type !== 'Point Address' && result.data.results[i].type !== 'Address Range')
                continue

              suggestions.push({ Text: result.data.results[i].address.freeformAddress, Value: result.data.results[i].address })
            }

            resolve(suggestions)
          })
          .catch(function (err) {
            reject(err)
          })
    })
  },

  getLoanExists: function () {
    return vm.searchLoanWithArguments({
      CompanyID: store.getters.getCompany.CompanyID,
      LoanID: store.getters.getLoanId,
      Category: [2],
      ApplicationSubmitted: false
    })
  },

  searchLoanExists: function (officerId) {
    return vm.searchLoanWithArguments({
      CompanyID: store.getters.getCompany.CompanyID,
      LoanOfficerID: (store.getters.getOfficer || {}).UserID || null,
      Category: [2],
      ApplicationSubmitted: false
    })
  },

  searchLoanWithArguments: function (args) {
    return network.api('Loan/MinList', args)
      .then(response => {
        var loans = ((response || {}).data || {}).Loans || []

        if (loans.length > 0)
          return loans[0]
        return null
      })
  },

  getSessionSummary: function () {
    return network.api('Session/Summary')
      .then(r => {
        return (r || {}).data
      })
  },

  login: function (username, password, twofactor) {
    return network.api('User/Login', {
      Email: username,
      Password: password,
      TwoFactor: twofactor,
      CompanyID: store.getters.getCompany.CompanyID
    })
    .then(r => {
      return (r || {}).data
    })
  },

  loginWithCode: function (code) {
    return network.api('Session/RedeemToken', {
      Key: code
    })
    .then(r => {
      return (r || {}).data
    })
  },

  logout: function () {
    return network.api('User/Logout')
      .then(r => {
        return (r || {}).data
      })
  },

  registerBorrower: function () {
    var argBorrowers = vm.getAPIBorrowerList()

    return network.api('User/Register', {
      CompanyID: store.getters.getCompany.CompanyID,
      UserID: (store.getters.getOfficer || {}).UserID || null,
      RealtorID: store.getters.getRealtorId,

      Role: 10,

      FirstName: argBorrowers[0].FirstName,
      MiddleName: argBorrowers[0].MiddleName,
      LastName: argBorrowers[0].LastName,
      Suffix: argBorrowers[0].Suffix,
      Email: argBorrowers[0].Email,
      Phone: argBorrowers[0].Phone,

      Borrowers: argBorrowers
    }, true)
    .then(r => {
      return (r || {}).data
    })
  },

  createLoan: function () {
    return network.api('Loan/BorrowerLoanCreate', {
      CompanyID: store.getters.getCompany.CompanyID,
      OfficerID: (store.getters.getOfficer || {}).UserID || null,
      RealtorID: store.getters.getRealtorId,
      Borrowers: vm.getAPIBorrowerList()
    })
    .then(r => {
      return (r || {}).data
    })
  },

  getAPIBorrowerList: function () {
    var borrowers = store.getters.getBorrowers
    var list = []

    for (var i = 0; i < borrowers.length; i++) {
      var borrowerInfo = store.getters.getFormValueAtIndex('smart1003_borrower_info', 0, borrowers[i].ID) || {}

      list.push({
        ReferenceID: borrowers[i].ID,
        LoanType: store.getters.getFormValueAtIndex('smart1003_loan_details_loan_type', 0, borrowers[i].ID) || '',
        MarriedToReference: store.getters.getFormValueAtIndex('smart1003_borrower_married_to', 0, borrowers[i].ID) || '',

        FirstName: borrowerInfo.FirstName,
        MiddleName: borrowerInfo.MiddleName,
        LastName: borrowerInfo.LastName,
        Suffix: borrowerInfo.Suffix,
        Email: store.getters.getFormValueAtIndex('smart1003_borrower_email', 0, borrowers[i].ID) || '',
        Phone: store.getters.getFormValueAtIndex('smart1003_borrower_phone', 0, borrowers[i].ID) || ''
      })
    }

    return list
  },

  invitationStatus: function (code) {
    return network.api('Invitation/Status', {
      InvitationCode: code
    })
    .then(r => {
      return (r || {}).data
    })
  },

  acceptInvitation: function (args) {
    return network.api('Invitation/Respond', {
      Accept: true,
      InvitationCode: args.Code,
      ExtraParams: {
        Password: args.Password
      }
    })
    .then(r => {
      return (r || {}).data
    })
  },

  APIGetDeviceRedirect: function () {
    return network.api('Link/GetPlatformLink', {
      LoanID: store.getters.getLoanId,
      Type: Utils.getDeviceCode()
    })
    .then(r => {
      return (r || {}).data
    })
  }
}

export default vm