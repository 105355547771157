<template>
  <div
    class="modal-backdrop"
    :class="{ open: open }"
    @click="closeModal"
  >
    <div
      class="modal"
      @click="modalClick"
    >
      <div class="modal-header clearfix">
        <slot name="header" />
      </div>
      <div class="modal-body clearfix">
        <slot name="body" />
      </div>
      <div class="modal-footer clearfix">
        <slot name="footer">
          <button
            type="button"
            class="close-button"
            @click="closeModal"
          >
            <Localize :text="`{{s1003_system_close}}`" />
          </button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

const Localize = defineAsyncComponent(function () { return import('@/components/TextLocalize.vue') })

export default {
  name: 'FormModal',
  components: {
    Localize
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'toggle:modal'
  ],
  data: function() {
    return {}
  },
  computed: {

  },
  created: function() {

  },
  methods: {
    closeModal: function() {
      this.$emit('toggle:modal')
    },
    modalClick: function ($event) {
      $event.stopPropagation()
    }
  }
}
</script>

<style scoped lang="scss">
.modal-backdrop {
  position: fixed;
  transition: 0.4s all;
  overflow: hidden;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  visibility: hidden;
  z-index: 1000;
  //display: none;

  .modal {
    //display: none;
    position: fixed;
    width: 600px;
    max-width: 90vw;
    max-height: 85vh;
    top: -100px;
    opacity: 0;
    transition: 0.4s all;
    overflow-y: auto;
    margin: 0 auto;
    visibility: hidden;
    background-color: white;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;

/*
    .modal-header {
      padding: 5px 10px;
      min-height: 20px;
    }
*/

    .modal-body {
      padding: 5px 10px;
      border-top: 1px solid #d8dada;
      border-bottom: 1px solid #d8dada;
    }

    .modal-footer {
      padding: 5px 10px;
    }

    .close-button {
      float: right;
    }
  }

  &.open {
    //display: block;
    background: rgba(78, 78, 78, 0.3);
    backdrop-filter: blur(2px);
    visibility: visible;

    .modal {
      top: 50px;
      //display: block;
      opacity: 1;
      visibility: visible;
    }
  }

  .clearfix::after {
    content: "";
    clear: both;
    display: table;
  }
}
</style>
