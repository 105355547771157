<template>
  <div />
</template>

<script>
import Globals from '@/services/globals.js'

export default {
  name: 'FieldHidden',
  components: {

  },
  props: {
    definition: {
      required: true,
      validator: function (value) {
        if (process.env.NODE_ENV == 'development' && !value)
          return true
          
        if ((value || {}).Type !== Globals.FIELD_TYPES.FIELD_TYPE_HIDDEN)
          return false

        if (typeof (value || {}).Id !== 'string')
          return false

        return true
      }
    },
    groupIndex: {
      type: Number,
      default: 0
    }
  },
  data: () => {
    return {

    }
  },
  computed: {

  },
  methods: {

  }
}
</script>
