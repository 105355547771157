import axios from 'axios'
import store from '../store/store'

/* eslint-disable no-console, no-irregular-whitespace */
export default {
  api: function(path, args, disableInterception) {
    var sessionId = store.getters.getSessionToken
    var config = undefined

    if (!!sessionId)
      config = { headers: { 'X-PAM-S': sessionId } }

    return axios.post(process.env.VUE_APP_API_BASE_URL + path, args, config)
      .then(r => {
        if (r.status !== 200) {
          store.commit('openToast', {
            text: '{{s1003_errors_request}}',
            type: 'error'
          })

          return r
	      }
	
	      if (!disableInterception && !r.data.Success) {
          store.commit('openToast', {
            text: r.data.Message || '{{s1003_errors_api}}',
            type: r.data.Code === 2009 ? 'success' : 'error'
          })

          return r
	      }

        return r
      })
      .catch(error => {
        store.commit('openToast', {
          text: '{{s1003_errors_transport}} ' + error,
          type: 'error'
        })

        console.log('{{s1003_errors_transport}} ', error)
      })
  },
  get: function(url) {
    return axios.get(url)
      .then(r => {
        if (r.status !== 200) {
          store.commit('openToast', {
            text: '{{s1003_errors_request}}',
            type: 'error'
          })

          return
        }

        return r
      })
      .catch(error => {
        store.commit('openToast', {
          text: '{{s1003_errors_transport}} ' + error,
          type: 'error'
        })

        console.log('{{s1003_errors_transport}} ', error)
      })
  },
  post: function(url, args) {
    return axios.post(url, args)
      .then(r => {
        if (r.status !== 200) {
          store.commit('openToast', {
            text: '{{s1003_errors_request}}',
            type: 'error'
          })

          return
        }

        return r
      })
      .catch(error => {
        store.commit('openToast', {
          text: '{{s1003_errors_transport}} ' + error,
          type: 'error'
        })

        console.log('{{s1003_errors_transport}} ', error)
      })
  }
}
