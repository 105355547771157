<template>
  <div
    class="input-wrapper"
    :class="{ invalid: !isValid }"
  >
    <label
      v-if="safeDefinition.Text && !safeDefinition._HideTitle"
      :for="indexedId"
      :title="localized(safeDefinition.Text)"
    >
      <Localize :text="safeDefinition.Text" />
    </label>
    <div v-if="!isLong">
      <input
        :id="indexedId"
        :required="safeDefinition.Required"
        :placeholder="localized(safeDefinition.Placeholder)"
        :value="displayValue"
        type="text"
        class="input-text"
        @input="onChangeEvent"
        @focus="onFocus"
        @blur="onBlur"
      >
      <div
        v-if="(suggestions || []).length > 0"
        class="input-suggestions"
        :class="{ shown: focused }"
      >
        <div
          v-for="suggestion in suggestions"
          :key="suggestion.Value"
          class="input-suggestions-suggestion"
          @mousedown="onSuggestion(suggestion)"
        >
          <Localize :text="suggestion.Text" />
        </div>
      </div>
    </div>
    <div v-if="isLong">
      <textarea
        :id="indexedId"
        :required="safeDefinition.Required"
        :placeholder="localized(safeDefinition.Placeholder)"
        :value="displayValue"
        class="input-text"
        rows="5"
        @input="onChangeEvent"
        @focus="onFocus"
        @blur="onBlur"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

import Lib from '@/services/fields.js'
import Globals from '@/services/globals.js'
import Utils from '@/services/utils.js'
import Terms from '@/services/terms.js'
const Localize = defineAsyncComponent(function () { return import('@/components/TextLocalize.vue') })

export default {
  name: 'FieldText',
  components: {
    Localize
  },
  props: {
    definition: {
      required: true,
      validator: function (value) {
        if (process.env.NODE_ENV == 'development' && !value)
          return true

        if ((value || {}).Type !== Globals.FIELD_TYPES.FIELD_TYPE_TEXT)
          return false

        if (typeof (value || {}).Id !== 'string')
          return false

        if (typeof (value || {}).Text !== 'string')
          return false

        return true
      }
    },
    suggestions: {
      type: Array,
      default: function () {
        return []
      }
    },
    groupIndex: {
      type: Number,
      default: 0
    },
    lockedBorrower: {
      type: Number,
      default: 0
    }
  },
  emits: [
    'update:value',
    'update:suggestion'
  ],
  data: () => {
    return {
      touched: false,
      focused: false,
      localValueBackup: null
    }
  },
  computed: {
    safeDefinition: function () {
      return Lib.safeDefinition(this)
    },
    isValid: function () {
      return Lib.isValid(this)
    },
    displayValue: function () {
      var val = Lib.getDisplayValue(this.safeDefinition, this.groupIndex, this.lockedBorrower || this.$store.getters.getCurrentBorrowerId)

      if (!this.focused) {
        if (this.safeDefinition.ValidationType === Globals.FIELD_TEXT_VALIDATION.FIELD_TEXT_VALIDATION_TYPE_SSN)
          val = Utils.formatMaskedSSN(val)
        if (this.safeDefinition.ValidationType === Globals.FIELD_TEXT_VALIDATION.FIELD_TEXT_VALIDATION_TYPE_PASSWORD)
          if ((val || '').length > 0)
            val = '****'
      }

      return val
    },
    indexedId: function () {
      var index = this.groupIndex
      if (this.lockedBorrower)
        index = index + "_" + this.lockedBorrower

      return Lib.indexedId(this.safeDefinition, index)
    },
    isLong: function () {
      return this.safeDefinition.Long
    }
  },
  methods: {
    onChangeEvent: function($event) {
      this.onChange($event.target.value)
    },
    onChange: function(val) {
      switch(this.safeDefinition.ValidationType) {
        case Globals.FIELD_TEXT_VALIDATION.FIELD_TEXT_VALIDATION_TYPE_PHONE: 
          val = Utils.formatPhone(val) 
          break
        case Globals.FIELD_TEXT_VALIDATION.FIELD_TEXT_VALIDATION_TYPE_SSN: 
          val = Utils.formatSSN(val) 
          this.localValueBackup = null
          break
        case Globals.FIELD_TEXT_VALIDATION.FIELD_TEXT_VALIDATION_TYPE_ZIP: 
          val = Utils.formatZip(val)
          break
      }

      Lib.updateDisplayValue(this.safeDefinition, this.groupIndex, val, this.$emit, this.lockedBorrower || this.$store.getters.getCurrentBorrowerId)
      this.touched = true
    },
    onSuggestion: function(suggestion) {
      this.$emit('update:suggestion', suggestion.Value)
    },
    onFocus: function () {
      if (this.safeDefinition.ValidationType === Globals.FIELD_TEXT_VALIDATION.FIELD_TEXT_VALIDATION_TYPE_SSN) {
        this.localValueBackup = Lib.getDisplayValue(this.safeDefinition, this.groupIndex, this.lockedBorrower || this.$store.getters.getCurrentBorrowerId)
        Lib.updateDisplayValue(this.safeDefinition, this.groupIndex, null, this.$emit, this.lockedBorrower || this.$store.getters.getCurrentBorrowerId)
      }

      this.focused = true
    },
    onBlur: function () {
      if (this.localValueBackup !== null) {
        Lib.updateDisplayValue(this.safeDefinition, this.groupIndex, this.localValueBackup, this.$emit, this.lockedBorrower || this.$store.getters.getCurrentBorrowerId)
        this.localValueBackup = null
      }
      
      this.focused = false
    },
    localized: function (str) {
      return Terms.replace(str)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/_variables.scss';

.input-wrapper {
  position: relative;

  .input-suggestions {
    display: none;

    &.shown {
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      z-index: 100;
      border-left: solid 1px $medium-gray;
      border-right: solid 1px $medium-gray;
      border-bottom: solid 1px $medium-gray;
      background: $white;
      .input-suggestions-suggestion {
        cursor: pointer;
        padding: 12px 12px;
        &:nth-child(even) {
          background: $light;
        }
        &:hover {
          background: $medium-gray;
        }
      }
    }
  }
}
</style>
