<template>
  <div v-if="(sections || []).length > 0">
    <ul
      v-if="!isMobile"
      :class="isRoot ? 'section_nav' : 'subsections'"
    >
      <li
        v-for="(section, index) in (sections || [])"
        :ref="'navList_' + section.Id"
        :key="index"
        :class="{ active: isActive(section.Id), complete: isComplete(section.Id) }"
      >
        <span
          :class="isRoot ? 'section-wrapper' : 'subsection-wrapper'"
          :title="localized(section.Name)"
          @click="navClick(section.Id)"
        >
          <i
            v-if="isComplete(section.Id)"
            class="fa-regular fa-check-circle m-r-10"
          />
          <i
            v-else
            class="fa-regular fa-circle m-r-10"
          />
          <Localize :text="section.Name" />
        </span>
        <FormNavigation
          :sections="section.Subsections"
          :parent-active="parentActive || isActive(section.Id)"
        />
      </li>
    </ul>
  
    <div
      v-if="isMobile"
      class="mobile_nav"
    >
      <!--
      <div class="mobile-nav-row indicator-row">
        <div
          v-for="(section, index) in (sections || [])"
          :key="index"
          class="mobile_nav_col"
          :class="{ active: isActive(section.Id) }"
          :title="localized(section.MobileName || section.Name)"
          @click="navClick(section.Id)"
        >
          <div>
            <i
              v-if="isComplete(section.Id)"
              class="fa-regular fa-check-circle"
            />
            <i
              v-else
              class="fa-regular fa-circle"
            />
          </div>
        </div>
      </div>
      -->
      <div class="mobile-nav-row">
        <div
          v-for="(section, index) in (sections || [])"
          :key="index"
          class="mobile_nav_col"
          :class="{ active: isActive(section.Id), complete: isComplete(section.Id) }"
          :title="localized(section.MobileName || section.Name)"
          @click="navClick(section.Id)"
        >
          <div>
            <!-- <Localize :text="section.MobileName || section.Name" /> -->
            <i :class="section.Icon" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

import Terms from '@/services/terms.js'
const Localize = defineAsyncComponent(function () { return import('@/components/TextLocalize.vue') })

export default {
  name: 'FormNavigation',
  components: {
    Localize
  },
  props: {
    sections: {
      type: Array,
      default: function () {
        return []
      }
    },
    parentActive: {
      type: Boolean,
      default: false
    },
    isRoot: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      nativeHeight: 0,
      loaded: false
    }
  },
  computed: {

  },
  methods: {
    isActive: function (id) {
      return this.$store.getters.isNavActive(id)
    },
    isComplete: function (id) {
      return this.$store.getters.isSectionComplete(id)
    },
    navClick: function (id) {
      this.$store.dispatch('formGo', id)
    },
    localized: function (str) {
      return Terms.replace(str)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/_variables.scss';

ul.section_nav {
  border-radius: 3px;
  overflow: hidden;
  list-style: none;
  padding: 0;
  margin: 0;
  > li {
    max-height: 0px;
    min-height: calc(24px + 1.5em);
    overflow: hidden;
    padding: 12px 0 12px 12px;
    line-height: 1.5em;
    transition: max-height 0.2s;
    cursor: pointer;
    &:first-child {
      min-height: calc(32px + 1.5em);
      padding-top: 20px;
    }
    &:last-child {
      min-height: calc(32px + 1.5em);
      padding-bottom: 20px;
    }
    &.active {
      // Hack that might need to be adjusted later
      max-height: 200px;
      transition: max-height 0.4s;
      transition-delay: 0.2s;
      background-color: $lighter-gray;
      .section-wrapper {
        color: $primary;
      }

      ul.subsections {
        > li {
          &:after {
            position: absolute;
            top: -20%;
            left: 0;
            height: 100%;
            content: '';
            width: 12px;
            border-left: solid 1px $medium-gray;
            border-bottom: solid 1px $medium-gray;
          }
        }
      }
    }

    .section-wrapper {
      width: 100%;
      display: inline-block;
      padding: 12px 0;
      margin: -12px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    ul.subsections {
      position: relative;
      list-style: none;
      padding: 5px 0 0 30px;
      margin: 0;
      > li {
        position: relative;
        padding: 18px 0 0 20px;
        &.active {
          color: $primary;
        }
        //&:after {
        //  position: absolute;
        //  top: -20%;
        //  left: 0;
        //  height: 100%;
        //  content: '';
        //  width: 12px;
        //  border-left: solid 1px $medium-gray;
        //  border-bottom: solid 1px $medium-gray;
        //}
        &:first-child {
          padding: 10px 0 0 20px;
        }
      }
    }
  }
}

.mobile_nav {
  border-radius: 3px;
  overflow: hidden;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  height: 65px;
  font-size: 12px;

  .mobile-nav-row {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .mobile_nav_col {
      flex-basis: 0;
      flex-grow: 1;
      border-left: 1px solid #d8dada;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      :first-child {
        border-left: none;
      }

      &.active {
        background-color: $lighter-gray;
        color: $primary;
      }
    }

    &.indicator-row {
        i {
          margin-top: 8px;
        }
      }

     +.mobile-nav-row {
      flex-grow: 1;
    }
  }
}

i {
  position: relative;
  top: -2px;
  font-size: 1.5em;
  vertical-align: middle;
}

.complete {
  color: $primary;
}
</style>