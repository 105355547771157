<template>
  <div :class="{ loading: appLoading, loaded: !appLoading }">
    <div
      v-if="appLoading || networkLoading"
      class="loading-dots-wrapper"
    >
      <div class="loading-dots centered">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
    <div class="inner">
      <router-view v-slot="{ Component }">
        <transition
          name="fade"
          mode="out-in"
        >
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
    <div
      class="toast-wrapper"
      :class="{ active: $store.getters.getToasts.length > 0 }"
    >
      <template
        v-for="(toast, index) in $store.getters.getToasts"
        :key="index"
      >
        <Toast
          :data="toast"
          @update:timeout="(value) => toast.timeout = value"
        />
      </template>
    </div>
  </div>
</template>

<script>
import Toast from '@/components/FormToast.vue'

export default {
  name: 'App',
  components: {
    Toast
  },
  computed: {
    appLoading: function () {
      return !this.$store.getters.getReady
    },
    networkLoading: function () {
      return this.$store.getters.getLoading
    }
  }
}
</script>

<style lang="scss">
#app {
  position: relative;
  min-height: 100%; /* For full-page loading animation */
  background-image: url('./assets/1003-background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  > .inner {
    opacity: 0;
    transition: opacity 1s;
    pointer-events: none;
  }
  &.loaded {
    > .inner {
      opacity: 1;
      pointer-events: auto;
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
    overflow: hidden;
  }
  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }

  .toast-container {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
