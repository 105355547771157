<template>
  <div
    v-show="isVisible"
    class="field-wrapper"
    :style="gridProperties"
  >
    <FieldAddress
      v-if="isAddressField"
      :definition="definition"
      :group-index="groupIndex"
      @update:value="onValueUpdate"
    />
    <FieldBasicInfo
      v-else-if="isBasicField"
      :definition="definition"
      :group-index="groupIndex"
      @update:value="onValueUpdate"
    />
    <FieldBoolean
      v-else-if="isBooleanField"
      :definition="definition"
      :group-index="groupIndex"
      @update:value="onValueUpdate"
    />
    <FieldCurrency
      v-else-if="isCurrencyField"
      :definition="definition"
      :group-index="groupIndex"
      @update:value="onValueUpdate"
    />
    <FieldDate
      v-else-if="isDateField"
      :definition="definition"
      :group-index="groupIndex"
      @update:value="onValueUpdate"
    />
    <FieldMultipleChoice
      v-else-if="isMultipleChoiceField"
      :definition="definition"
      :group-index="groupIndex"
      @update:value="onValueUpdate"
    />
    <FieldEmploymentHistory
      v-else-if="isEmploymentHistoryField"
      :definition="definition"
      @update:value="onValueUpdate"
    />
    <FieldGroup
      v-else-if="isGroupField"
      :definition="definition"
      :group-index="groupIndex"
      :expanded="groupExpanded"
      :removeprompt="removeprompt"
      @update:value="onValueUpdate"
      @update:expanded="onExpandedUpdate"
      @update:remove="onRemoveUpdate"
    />
    <FieldHidden
      v-else-if="isHiddenField"
      :definition="definition"
      :group-index="groupIndex"
      @update:value="onValueUpdate"
    />
    <FieldHubScreen
      v-else-if="isHubField"
      :definition="definition"
      @update:value="onValueUpdate"
    />
    <FieldInteger
      v-else-if="isIntegerField"
      :definition="definition"
      :group-index="groupIndex"
      @update:value="onValueUpdate"
    />
    <FieldLink
      v-else-if="isLinkField"
      :definition="definition"
    />
    <FieldRepeatingGroup
      v-else-if="isRepeatingGroupField"
      :definition="definition"
      @update:value="onValueUpdate"
    />
    <FieldResidenceHistory
      v-else-if="isResidenceHistoryField"
      :definition="definition"
      @update:value="onValueUpdate"
    />
    <FieldReview
      v-else-if="isReviewScreenField"
      :definition="definition"
      @update:value="onValueUpdate"
    />
    <FieldScreen
      v-else-if="isScreenField"
      :definition="definition"
      @update:value="onValueUpdate"
    />
    <FieldText
      v-else-if="isTextField"
      :definition="definition"
      :group-index="groupIndex"
      :suggestions="suggestions"
      @update:value="onValueUpdate"
      @update:suggestion="onSuggestionUpdate"
    />
    <div
      v-else
      class="unknown-field"
    >
      {{ safeDefinition.Type }}: <Localize :text="safeDefinition.Text" />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

import Globals from '@/services/globals.js'

import FieldAddress from '@/fields/input/FieldAddress.vue'
import FieldBasicInfo from '@/fields/input/FieldBasicInfo.vue'
import FieldBoolean from '@/fields/input/FieldBoolean.vue'
import FieldCurrency from '@/fields/input/FieldCurrency.vue'
import FieldDate from '@/fields/input/FieldDate.vue'
import FieldMultipleChoice from '@/fields/input/FieldMultipleChoice.vue'
import FieldEmploymentHistory from '@/fields/FieldEmploymentHistory.vue'
import FieldGroup from '@/fields/FieldGroup.vue'
import FieldHidden from '@/fields/input/FieldHidden.vue'
import FieldHubScreen from '@/fields/FieldHubScreen.vue'
import FieldInteger from '@/fields/input/FieldInteger.vue'
import FieldLink from '@/fields/FieldLink.vue'
import FieldRepeatingGroup from '@/fields/FieldRepeatingGroup.vue'
import FieldResidenceHistory from '@/fields/FieldResidenceHistory.vue'
import FieldReview from '@/fields/FieldReviewScreen.vue'
import FieldScreen from '@/fields/FieldScreen.vue'
import FieldText from '@/fields/input/FieldText.vue'

const Localize = defineAsyncComponent(function () { return import('@/components/TextLocalize.vue') })
import Lib from '@/services/fields.js'

export default {
  name: 'FormField',
  components: {
    FieldAddress,
    FieldBasicInfo,
    FieldBoolean,
    FieldCurrency,
    FieldDate,
    FieldMultipleChoice,
    FieldEmploymentHistory,
    FieldGroup,
    FieldHidden,
    FieldHubScreen,
    FieldInteger,
    FieldLink,
    FieldRepeatingGroup,
    FieldResidenceHistory,
    FieldReview,
    FieldScreen,
    FieldText,
    Localize
  },
  props: {
    definition: {
      required: true,
      validator: function (value) {
        if (typeof (value || {}).Id !== 'string')
          return false

        return true
      }
    },
    groupIndex: {
      type: Number,
      default: 0
    },
    removeprompt: {
      type: String,
      default: ''
    },
    suggestions: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  emits: [
    'update:remove',
    'update:value',
    'update:suggestion'
  ],
  data: function () {
    return {
      groupExpanded: true
    }
  },
  computed: {
    safeDefinition: function () {
      return Lib.safeDefinition(this)
    },
    isVisible: function () {
      return Lib.isVisible(this.safeDefinition, this.groupIndex)
    },
    gridProperties: function () {
      if (!this.safeDefinition.Grid)
        return {}

      var rowEnd = 'span 1'
      if (this.isAddressField)
        rowEnd = 'span 3'

      return {
        'grid-column-start': this.safeDefinition.Grid.X + 1,
        'grid-column-end': 'span ' + this.safeDefinition.Grid.Span,
        'grid-row-start': this.safeDefinition.Grid.Y + 1,
        'grid-row-end': rowEnd
      }
    },
    isAddressField: function () {
      return this.safeDefinition.Type === Globals.FIELD_TYPES.FIELD_TYPE_ADDRESS
    },
    isBasicField: function () {
      return this.safeDefinition.Type === Globals.FIELD_TYPES.FIELD_TYPE_USER_INFO
    },
    isBooleanField: function () {
      return this.safeDefinition.Type === Globals.FIELD_TYPES.FIELD_TYPE_BOOLEAN
    },
    isCurrencyField: function () {
      return this.safeDefinition.Type === Globals.FIELD_TYPES.FIELD_TYPE_CURRENCY
    },
    isDateField: function () {
      return this.safeDefinition.Type === Globals.FIELD_TYPES.FIELD_TYPE_DATE
    },
    isMultipleChoiceField: function () {
      return this.safeDefinition.Type === Globals.FIELD_TYPES.FIELD_TYPE_MULTIPLE_CHOICE
    },
    isEmploymentHistoryField: function () {
      return this.safeDefinition.Type === Globals.FIELD_TYPES.FIELD_TYPE_EMPLOYMENT_HISTORY
    },
    isGroupField: function () {
      return this.safeDefinition.Type === Globals.FIELD_TYPES.FIELD_TYPE_GROUP
    },
    isHiddenField: function () {
      return this.safeDefinition.Type === Globals.FIELD_TYPES.FIELD_TYPE_HIDDEN
    },
    isHubField: function () {
      return this.safeDefinition.Type === Globals.FIELD_TYPES.FIELD_TYPE_HUB_SCREEN
    },
    isIntegerField: function () {
      return this.safeDefinition.Type === Globals.FIELD_TYPES.FIELD_TYPE_INTEGER
    },
    isLinkField: function () {
      return this.safeDefinition.Type === Globals.FIELD_TYPES.FIELD_TYPE_LINK
    },
    isRepeatingGroupField: function () {
      return this.safeDefinition.Type === Globals.FIELD_TYPES.FIELD_TYPE_REPEATING_GROUP
    },
    isResidenceHistoryField: function () {
      return this.safeDefinition.Type === Globals.FIELD_TYPES.FIELD_TYPE_RESIDENCE_HISTORY
    },
    isReviewScreenField: function () {
      return this.safeDefinition.Type === Globals.FIELD_TYPES.FIELD_TYPE_REVIEW_SCREEN
    },
    isScreenField: function () {
      return this.safeDefinition.Type === Globals.FIELD_TYPES.FIELD_TYPE_SCREEN
    },
    isTextField: function () {
      return this.safeDefinition.Type === Globals.FIELD_TYPES.FIELD_TYPE_TEXT
    }
  },
  methods: {
    onExpandedUpdate: function ($event) {
      this.groupExpanded = $event
    },
    onRemoveUpdate: function ($event) {
      this.$emit('update:remove', $event)
    },
    onValueUpdate: function ($event) {
      this.$emit('update:value', $event)
    },
    onSuggestionUpdate: function ($event) {
      this.$emit('update:suggestion', $event)
    }
  }
}
</script>

<style scoped lang="scss">
.field-wrapper {
  &.hidden {
    display: none;
  }
}
</style>
