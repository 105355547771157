<template>
  <Slide
    :active-slide="activeSlide"
    class="slide-lo-search"
    :index="slideIndex"
    :disable-footer="true"
    :styles="slideStyles"
  >
    <div class="container">
      <h2 class="bold">
        <Localize :text="`{{s1003_system_officer_search1}}`" />
      </h2>
      <p class="margin-b">
        <Localize :text="`{{s1003_system_officer_search2}}`" />
      </p>
      <!--
      <div class="slide-left">
        <FieldText
          :definition="nameField"
          :locked-borrower="1" 
        />
      </div>
      <div class="slide-middle" style="display: none;">Or</div>
      <div class="slide-right" style="display: none;">
        <label for="loan_officer_zip">Do you know their zip code?</label>
        <FieldText 
          :definition="zipField" 
          :locked-borrower="1"
        />
      </div>
      <div class="search-button-wrapper">
        <button type="button" class="btn-primary" @click="searchOfficer">
          <i class="fa-regular fa-search m-r-5" /> Search
        </button>
      </div>
      -->
      <div class="intro-search">
        <div>
          <FieldText 
            :definition="nameField"
            :locked-borrower="1" 
          />
        </div>
        <div class="search-button-wrapper">
          <button
            type="button"
            class="btn-primary"
            @click="searchOfficer"
          >
            <i class="fa-regular fa-search m-r-5" /> <Localize :text="`{{s1003_system_search}}`" />
          </button>
        </div>
      </div>

      <div
        v-if="showResults"
        class="results"
      >
        <h4 class="bold">
          <Localize :text="`{{s1003_system_officer_top5}}`" />
        </h4>
        <template
          v-for="officer in results"
          :key="officer.UserID"
        >
          <div class="lo-search-result">
            <div
              v-if="officer.Avatar"
              class="officer-photo"
              :style="{ 'background-image': 'url(' + officer.Avatar + ')' }"
            />
            <div
              v-else
              class="officer-photo"
            />
            <div class="officer-info">
              <div class="title bold">
                <span>{{ officer.FullName }}</span><span v-if="officer.Title"> - {{ officer.Title }}</span>
              </div>
              <div
                v-if="officer.NMLS"
                class="contact-nmls"
              >
                <Localize :text="`{{s1003_system_nmls}}`" /> {{ officer.NMLS }}
              </div>
              <div
                v-if="officer.Phone"
                class="contact-phone"
              >
                <i class="fa-solid fa-phone" /> {{ officer.Phone }}
              </div>
              <div
                v-if="officer.Email"
                class="contact-email"
              >
                <i class="fa-solid fa-envelope" /> {{ officer.Email }}
              </div>
              <div
                v-if="(officer.Address || {}).Zip"
                class="contact-zip"
              >
                <i class="fa-solid fa-globe" /> {{ officer.Address.Zip }}
              </div>
            </div>
            <div class="officer-select">
              <button
                type="button"
                class="btn-primary"
                @click="selectOfficer(officer)"
              >
                <Localize :text="`{{s1003_system_select}}`" />
              </button>
            </div>
          </div>
        </template>

        <div v-if="results.length == 0">
          <div class="lo-search-result">
            <Localize :text="`{{s1003_system_officer_none}}`" />
          </div>
        </div>
      </div>
      <div class="search-back">
        <button
          class="btn btn-default"
          @click="onBack"
        >
          <Localize :text="`{{s1003_common_back}}`" />
        </button>
      </div>
    </div>
  </Slide>
</template>

<script>
import { defineAsyncComponent } from 'vue'

import ApiClient from '@/services/apiclient.js'
import FieldLib from '@/services/fields.js'
import Definitions, {START_FIELD_SEARCH_ZIP} from '@/services/definitions.js'

import FieldText from '@/fields/input/FieldText.vue'
import Slide from '@/components/FormCarouselSlide.vue'
const Localize = defineAsyncComponent(function () { return import('@/components/TextLocalize.vue') })

export default {
  name: 'SlideOfficerSearch',
  components: {
    FieldText,
    Slide,
    Localize
  },
  props: {
    activeSlide: {
      type: Number,
      default: 0
    },
    slideStyles: {
      type: Object,
      default: function () {
         return {}
      }
    },
    slideIndex: {
      type: Number,
      default: 0
    }
  },
  emits: [
    'update:continue',
    'update:back'
  ],
  data: () => {
    return {
      nameField: null, // Loaded on mount for terms
      zipField: START_FIELD_SEARCH_ZIP,

      loading: false,
      showResults: false,
      results: []
    }
  },
  computed: {

  },
  mounted: function() {
    this.nameField = Definitions.start.createSearchName()
  },
  methods: {
    searchOfficer: function() {
      var $this = this

      var name = FieldLib.getDisplayValue($this.nameField, 0, 1)
      var zip = FieldLib.getDisplayValue($this.zipField, 0, 1)

      ApiClient.searchOfficer(name, zip)
        .then(function (r) {
          $this.results = r.Users || []
          $this.showResults = true
        })
    },
    selectOfficer: function (officer) {
      var $this = this

      $this.$store.dispatch('initOfficer', officer)
        .then(function () {
          $this.$emit('update:continue')
        })
    },
    onBack: function () {
      this.$emit('update:back')
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/_variables.scss';

p.margin-b {
  margin-bottom: 2rem;
}

.slide-left, .slide-right, .slide-middle {
  display: inline-block;
  width: 45%;
  vertical-align: middle;
}

.slide-middle {
  color: $black;
  width: 10%;
  text-align: center;
  font-weight: bold;
}

.search-button-wrapper {
  text-align: right;

  button {
    .svg-inline--fa {
      margin-right: 6px;
    }
  }
}

.results {
  padding: 1.4rem 0;

  .lo-search-result {
    position: relative;
    padding: 1rem 0;
    border-top: solid 1px $light;

    &:last-child {
      border-bottom: solid 1px $light;
    }

    .officer-info {
      .title {
        span:first-child {
          font-size: 1.2rem;
          line-height: 1.2rem;
        }
      }
    }

    .officer-photo {
      margin-right: 25px;
    }

    .officer-select {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .contact-nmls, .contact-phone, .contact-email {
      font-size: 0.9rem;
      margin-bottom: 3px;
    }
  }
}

.intro-search {
  display: flex;
  justify-content: space-between;
    
  >:first-child {
    flex-grow: 1;
  }
  >:last-child {
    margin-left: 10px;
  }
}

.search-back {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>
