/* eslint-disable no-console, no-irregular-whitespace */
const globals = {
  LIST_STATES: [
    { Value: "AL", Text: "{{state_alabama}}" },
    { Value: "AK", Text: "{{state_alaska}}" },
    { Value: "AS", Text: "{{state_samoa}}" },
    { Value: "AZ", Text: "{{state_arizona}}" },
    { Value: "AR", Text: "{{state_arkansas}}" },
    { Value: "CA", Text: "{{state_california}}" },
    { Value: "CO", Text: "{{state_colorado}}" },
    { Value: "CT", Text: "{{state_connecticut}}" },
    { Value: "DE", Text: "{{state_delaware}}" },
    { Value: "DC", Text: "{{state_dc}}" },
    { Value: "FM", Text: "{{state_micronesia}}" },
    { Value: "FL", Text: "{{state_florida}}" },
    { Value: "GA", Text: "{{state_georgia}}" },
    { Value: "GU", Text: "{{state_guam}}}}" },
    { Value: "HI", Text: "{{state_hawaii}}" },
    { Value: "ID", Text: "{{state_idaho}}" },
    { Value: "IL", Text: "{{state_illinois}}" },
    { Value: "IN", Text: "{{state_indiana}}" },
    { Value: "IA", Text: "{{state_iowa}}" },
    { Value: "KS", Text: "{{state_kansas}}" },
    { Value: "KY", Text: "{{state_kentucky}}" },
    { Value: "LA", Text: "{{state_louisiana}}" },
    { Value: "ME", Text: "{{state_maine}}" },
    { Value: "MH", Text: "{{state_marshall_islands}}" },
    { Value: "MD", Text: "{{state_maryland}}" },
    { Value: "MA", Text: "{{state_massachusetts}}" },
    { Value: "MI", Text: "{{state_michigan}}" },
    { Value: "MN", Text: "{{state_minnesota}}" },
    { Value: "MS", Text: "{{state_mississipi}}" },
    { Value: "MO", Text: "{{state_missouri}}" },
    { Value: "MT", Text: "{{state_montana}}" },
    { Value: "NE", Text: "{{state_nebraska}}" },
    { Value: "NV", Text: "{{state_nevada}}" },
    { Value: "NH", Text: "{{state_new_hampshire}}" },
    { Value: "NJ", Text: "{{state_new_jersey}}" },
    { Value: "NM", Text: "{{state_new_mexico}}" },
    { Value: "NY", Text: "{{state_new_york}}" },
    { Value: "NC", Text: "{{state_north_carolina}}" },
    { Value: "ND", Text: "{{state_north_dakota}}" },
    { Value: "MP", Text: "{{state_mariana}}" },
    { Value: "OH", Text: "{{state_ohio}}" },
    { Value: "OK", Text: "{{state_oklahoma}}" },
    { Value: "OR", Text: "{{state_oregon}}" },
    { Value: "PW", Text: "{{state_palau}}" },
    { Value: "PA", Text: "{{state_pennsylvania}}" },
    { Value: "PR", Text: "{{state_puerto_rico}}" },
    { Value: "RI", Text: "{{state_rhode_island}}" },
    { Value: "SC", Text: "{{state_south_carolina}}" },
    { Value: "SD", Text: "{{state_south_dakota}}" },
    { Value: "TN", Text: "{{state_tennessee}}" },
    { Value: "TX", Text: "{{state_texas}}" },
    { Value: "UT", Text: "{{state_utah}}" },
    { Value: "VT", Text: "{{state_vermont}}" },
    { Value: "VI", Text: "{{state_virgin_islands}}" },
    { Value: "VA", Text: "{{state_virginia}}" },
    { Value: "WA", Text: "{{state_washington}}" },
    { Value: "WV", Text: "{{state_west_virginia}}" },
    { Value: "WI", Text: "{{state_wisconsin}}" },
    { Value: "WY", Text: "{{state_wyoming}}" }
  ],

  FIELD_TYPES: {
    FIELD_TYPE_MULTIPLE_CHOICE: 2,
    FIELD_TYPE_USER_INFO: 3,
    FIELD_TYPE_DATE: 4,
    FIELD_TYPE_INTEGER: 5,
    FIELD_TYPE_ADDRESS: 6,
    FIELD_TYPE_GROUP: 7,
    FIELD_TYPE_CURRENCY: 8,
    FIELD_TYPE_TEXT: 13,
    FIELD_TYPE_REPEATING_GROUP: 21,
    FIELD_TYPE_HIDDEN: 22,
    FIELD_TYPE_BOOLEAN: 23,
    FIELD_TYPE_LINK: 24,
    FIELD_TYPE_BORROWER_LIST: 25,
    FIELD_TYPE_RESIDENCE_HISTORY: 26,
    FIELD_TYPE_EMPLOYMENT_HISTORY: 27,
    FIELD_TYPE_PERCENT: 100,
    FIELD_TYPE_SCREEN: 1000,
    FIELD_TYPE_HUB_SCREEN: 1001,
    FIELD_TYPE_REVIEW_SCREEN: 1500
  },

  FIELD_TEXT_VALIDATION: {
    FIELD_TEXT_VALIDATION_TYPE_NORMAL: 0,
    FIELD_TEXT_VALIDATION_TYPE_EMAIL: 1,
    FIELD_TEXT_VALIDATION_TYPE_PHONE: 2,
    FIELD_TEXT_VALIDATION_TYPE_SSN: 3,
    FIELD_TEXT_VALIDATION_TYPE_ZIP: 4,
    FIELD_TEXT_VALIDATION_TYPE_PASSWORD: 5
  },

  SCREEN_LAYOUT: {
    SCREEN_LAYOUT_GRID: 1,
    SCREEN_LAYOUT_LIST: 2
  },

  LINK_TYPE: {
    LINK_TYPE_INTERNAL: 1,
    LINK_TYPE_EXTERNAL: 2,
    LINK_TYPE_SUBMIT: 3
  },

  HUB_TYPE: {
    HUB_TYPE_BORROWER: 1,
    HUB_TYPE_LOAN: 2
  },

  AUTH_ROLE: {
    NONE: 0,
    BORROWER: 10,
    REALTOR: 25,
    PROCESSOR: 50,
    LOANOFFICER: 100,
    MANAGER: 200,
    ADMIN: 500
  },

  install: (app, options) => {
    app.config.globalProperties.LIST_STATES = globals.LIST_STATES
    app.config.globalProperties.FIELD_TYPES = globals.FIELD_TYPES
    app.config.globalProperties.FIELD_TEXT_VALIDATION = globals.FIELD_TEXT_VALIDATION
  }
}

export default globals
