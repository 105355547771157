<template>
  <label 
    class="toggle-switch"
    :class="toggleClass"
    @click="onUpdate"
  >
    <span
      :id="id"
      class="toggle-slider"
    >
      <span class="toggle-title"><Localize :text="title" /></span>
    </span>
  </label>
</template>

<script>
import { defineAsyncComponent } from 'vue'

const Localize = defineAsyncComponent(function () { return import('@/components/TextLocalize.vue') })
import Utils from '@/services/utils.js'

export default {
  name: 'SliderCheckbox',
  components: {
    Localize
  },
  props: {
    value: Boolean,
    id: {
      type: String,
      default: Utils.random() + ""
    }
  },
  emits: [
    'update:value'
  ],
  data: function() {
    return {

    }
  },
  computed: {
    title: function () {
      if (this.value)
        return '{{s1003_common_yes}}'
      return '{{s1003_common_no}}'
    },
    toggleClass: function () {
      if (this.value)
        return 'on'
      return ''
    }
  },
  methods: {
    onUpdate: function () {
      this.$emit('update:value', !this.value)
    }
  }
}
</script>

<style scoped lang="scss">
.toggle-switch {
  float: right;
  position: relative;
  width: 62px;
  height: 24px;
  > .toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 24px;
    &:before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
    .toggle-title {
      position: absolute;
      font-size: 16px;
      color: white;
      top: 6px;
      left: 28px;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      text-transform: none;
    }
  }
  &.on {
    .toggle-slider {
      background-color: #2196f3;
      .toggle-title {
        left: 6px;
      }
    }
    .toggle-slider:before {
      -webkit-transform: translateX(38px);
      -ms-transform: translateX(38px);
      transform: translateX(38px);
    }
  }
}
</style>
