<template>
  <div class="field-screen">
    <div class="container">
      <h2 v-if="safeDefinition.Text">
        <Localize :text="safeDefinition.Text" />
      </h2>
      <div v-if="safeDefinition.Description">
        <Localize :text="safeDefinition.Description" />
      </div>
    </div>
    <div
      class="field-screen-body"
      :class="bodyClass"
    >
      <legend />
      <template
        v-for="field in safeDefinition.Body"
        :key="'body_' + field.Id"
      >
        <Field
          :definition="field"
          @update:value="onChange"
        />
      </template>
    </div>
    <div class="field-screen-footer">
      <div class="container">
        <template
          v-for="field in safeDefinition.Footer"
          :key="'footer_' + field.Id"
        >
          <FieldLink
            :definition="field" 
            @update:pagecomplete="onPageComplete"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

import FieldLink from '@/fields/FieldLink.vue'
const Localize = defineAsyncComponent(function () { return import('@/components/TextLocalize.vue') })
import FieldLib from '@/services/fields.js'
const Field = defineAsyncComponent(function () { return import('@/fields/FormField.vue') })
import Globals from '@/services/globals.js'

const LAYOUT_GRID = 1
const LAYOUT_LIST = 2

export default {
  name: 'FieldScreen',
  components: {
    Field,
    FieldLink,
    Localize
  },
  props: {
    definition: {
      required: true,
      validator: function (value) {
        if (process.env.NODE_ENV == 'development' && !value)
          return true

        if ((value || {}).Type !== Globals.FIELD_TYPES.FIELD_TYPE_SCREEN)
          return false

        if (typeof (value || {}).Id !== 'string')
          return false

        if (typeof(value || {}).Text !== 'string')
          return false

        return true
      }
    }
  },
  emits: [
    'update:value'
  ],
  data: () => {
    return {
      
    }
  },
  computed: {
    safeDefinition: function () {
      return FieldLib.safeDefinition(this)
    },
    fieldType: function () {
      return "FormField"
    },
    isGridLayout: function () {
      return this.safeDefinition.Layout === LAYOUT_GRID
    },
    isListLayout: function () {
      return this.safeDefinition.Layout === LAYOUT_LIST
    },
    bodyClass: function () {
      return {
        'container': true,
        'layout-grid': this.isGridLayout,
        'layout-list': this.isListLayout
      }
    }
  },
  methods: {
    onChange: function ($event) {
      this.$emit('update:value', $event)
    },
    onPageComplete: function ($event) {
      FieldLib.updateDisplayValue(this.safeDefinition, 0, true, this.$emit)
    }
  }
}
</script>