<template>
  <div
    class="slide"
    :class="{ active: isActive }"
    :style="styles"
  >
    <div class="slide-inner">
      <slot />
      <div class="slide-footer">
        <div class="container">
          <div 
            v-if="!disableFooter"
            class="slide-footer-wrapper" 
          >
            <button
              type="button"
              class="btn-primary"
              @click="buttonCallback"
            >
              <Localize :text="buttonLabel" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

const Localize = defineAsyncComponent(function () { return import('@/components/TextLocalize.vue') })

export default {
  name: 'FormCarouselSlide',
  components: {
    Localize
  },
  props: {
    index: {
      type: Number,
      default: 0
    },
    activeSlide: {
      type: Number,
      default: 0
    },
    styles: {
      type: Object,
      default: function () {
        return {}
      }
    },
    disableFooter: {
      type: Boolean,
      default: false
    },
    buttonCallback: {
      type: Function,
      default: function() {
        this.$emit('update:activeSlide', this.activeSlide + 1)
      }
    },
    buttonLabel: {
      validator: function (value) {
        if (typeof value === 'string' && value.length > 0)
          return true
        if (typeof value === 'function')
          return true
        return false
      },
      default: '{{s1003_start_continue}}'
    }
  },
  emits: [
    'update:activeSlide'
  ],
  data: function() {
    return {}
  },
  computed: {
    isActive: function() {
      return this.activeSlide == this.index
    }
  }
}
</script>
