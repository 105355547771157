<template>
  <li
    v-if="!hidden"
    :class="{ active: isActive }"
    :style="styles"
    @click="tabClick"
  >
    <div class="tab-inner">
      <i
        v-if="icon"
        :class="icon"
      />
      <div class="tab-inner-title">
        <div
          v-if="title"
          class="title"
        >
          <Localize :text="title" />
        </div>
        <div
          v-if="subtitle"
          class="sub"
        >
          <Localize :text="subtitle" />
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { defineAsyncComponent } from 'vue'

const Localize = defineAsyncComponent(function () { return import('@/components/TextLocalize.vue') })

export default {
  name: 'FormCarouselTab',
  components: {
    Localize
  },
  props: {
    index: {
      type: Number,
      default: 0
    },
    activeSlide: {
      type: Number,
      default: 0
    },
    styles: {
      type: Object,
      default: function () {
        return {}
      }
    },
    hidden: Boolean,
    icon: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: ""
    }
  },
  emits: [
    'update:activeSlide'
  ],
  data: function() {
    return {}
  },
  computed: {
    isActive: function() {
      return this.activeSlide == this.index
    }
  },
  methods: {
    tabClick: function(tab) {
      this.$emit('update:activeSlide', this.index)
    }
  }
}
</script>
