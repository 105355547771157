<template>
  <div
    class="toast"
    :class="css"
    @click="closeToast"
    @mouseover="hoverToast"
    @mouseout="leaveToast"
  >
    <div class="toast-close">
      <i class="fa-solid fa-times" />
    </div>
    <div class="toast-body">
      <Localize :text="data.text" />
    </div>
    <div class="toast-loader">
      <div class="toast-loader-bar" />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

const Localize = defineAsyncComponent(function () { return import('@/components/TextLocalize.vue') })
import Utils from '@/services/utils.js'

export default {
  name: 'FormToast',
  components: {
    Localize
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          type: 'error',
          id: Utils.random(),
          timeout: 6000,
          text: ''
        }
      }
    }
  },
  emits: [
    'update:timeout'
  ],
  data: function() {
    return {
      loading: true,
      timeout: false
    }
  },
  computed: {
    css: function () {
      var css = {}
      css[this.data.type] = true
      css["active"] = !this.loading

      return css
    }
  },
  created: function() {
    const $this = this

    // Activate
    setTimeout(function() {
      $this.loading = false
    }, 100)
  },
  methods: {
    closeToast: function() {
      this.$store.commit('closeToast', this.data.id)
    },
    hoverToast: function() {
      this.$emit('update:timeout', null)
    },
    leaveToast: function() {
      this.$emit('update:timeout', new Date().getTime() + 6000)
    }
  }
}
</script>
