import Vuex from 'vuex'

import Auth from '@/store/auth.js'
import Borrower from '@/store/borrower.js'
import Company from '@/store/company.js'
import Form from '@/store/form.js'
import Init from '@/store/init.js'
import Loan from '@/store/loan.js'
import Navigation from '@/store/nav.js'
import Officer from '@/store/officer.js'
import Realtor from '@/store/realtor.js'
import Toasts from '@/store/toasts.js'
import Update from '@/store/update.js'

export default new Vuex.Store({
  modules: {
    auth: Auth,
    borrower: Borrower,
    company: Company,
    form: Form,
    init: Init,
    loan: Loan,
    navigation: Navigation,
    officer: Officer,
    realtor: Realtor,
    toasts: Toasts,
    update: Update
  },
  state: {
    isReady: false,
    isLoading: false,
    loginAction: null,
    activeLocalization: 'en-US'
  },
  getters: {
    getLogo: (state, getters) => {
      if (getters.getOfficerTheme)
        return getters.getOfficerTheme.App.Logos.App
      else if (getters.getCompanyTheme)
        return getters.getCompanyTheme.App.Logos.App
      else
        return '../assets/logo_tmp.png'
    },
    getReady: (state) => {
      return state.isReady
    },
    getLoading: (state) => {
      return state.isLoading
    },
    getLoginAction: (state) => {
      return state.loginAction
    },
    getLocalization: (state) => {
      return state.activeLocalization
    }
  },
  mutations: {
    setLoading: (state, value) => {
      state.isLoading = value
    },
    setReady: (state, value) => {
      state.isReady = value
    },
    setLoginAction: (state, value) => {
      state.loginAction = value
    },
    setLocalization: (state, value) => {
      state.activeLocalization = value
      localStorage.setItem("PAM_LANG", value)
    }
  },
  actions: {
    runHeartbeat: ({ dispatch }) => {
      dispatch('toastHeartbeat')
    },
    initLocalization: ({ getters, commit }) => {
      var stored = localStorage.getItem("PAM_LANG")

      if (stored) {
        commit('setLocalization', stored)
        return
      }

      var config = getters.getOfficerConfig || getters.getCompanyConfig
      var languages = (config.Localization || {}).LocalizationLanguages || []

      for (var i = 0; i < languages.length; i++)
        if (languages[i].IsDefault) {
          commit('setLocalization', languages[i].Code)
          return
        }

      commit('setLocalization', 'en-US')
    }
  }
})
