<template>
  <Slide
    class="slide-lo-yesno"
    :active-slide="activeSlide"
    :index="slideIndex"
    :disable-footer="true"
    :styles="slideStyles"
  >
    <div class="container">
      <h2 class="bold">
        <Localize :text="`{{s1003_system_officer_search4}}`" />
      </h2>
      <p class="margin-b">
        <Localize :text="`{{s1003_system_officer_search3}}`" />
      </p>
      <FieldBoolean
        :definition="buttonDefinition"
        :locked-borrower="1"
        @update:value="updateOfficerValue"
      />
    </div>
  </Slide>
</template>

<script>
import { defineAsyncComponent } from 'vue'

import FieldBoolean from '@/fields/input/FieldBoolean.vue'
import Slide from '@/components/FormCarouselSlide.vue'
import Definitions from '@/services/definitions.js'
const Localize = defineAsyncComponent(function () { return import('@/components/TextLocalize.vue') })

export default {
  name: 'SlideHasOfficer',
  components: {
    FieldBoolean,
    Slide,
    Localize
  },
  props: {
    activeSlide: {
      type: Number,
      default: 0
    },
    slideStyles: {
      type: Object,
      default: function () {
         return {}
      }
    },
    slideIndex: {
      type: Number,
      default: 0
    }
  },
  emits: [
    'update:continue'
  ],
  data: () => {
    return {
      buttonDefinition: null
    }
  },
  computed: {

  },
  mounted: function() {
    this.buttonDefinition = Definitions.start.createHasOfficer()
  },
  methods: {
    updateOfficerValue: function(value) {
      this.$emit('update:continue', value)
    }
  }
}
</script>

<style scoped lang="scss">
p.margin-b {
  margin-bottom: 2rem;
}
</style>
