import ApiClient from '@/services/apiclient.js'
import Globals from '@/services/globals.js'

const vm = {
  state: {
    form: {},
    submitResult: null,
    values: {}
  },
  getters: {
    getForm: function (state) {
      return state.form
    },
    getFormValues: function (state) {
      return state.values
    },
    getSubmitResult: function (state) {
      return state.submitResult
    },
    getFormValue: function (state, getters) {
      return function (fieldId, borrowerId) {
        return (state.values[borrowerId || getters.getCurrentBorrowerId] || {})[fieldId] || [null]
      }
    },
    getFormValueAtIndex: function (state, getters) {
      return function (fieldId, groupIndex, borrowerId) {
        return ((state.values[borrowerId || getters.getCurrentBorrowerId] || {})[fieldId] || [null])[groupIndex || 0]
      }
    },
    isPageComplete: function (state, getters) {
      return function (page, borrowerId) {
        return getters.getFormValueAtIndex(page.Id, 0, borrowerId) === true
      }
    },
    isSectionComplete: function (state, getters) {
      return function (id) {
        var form = getters.getForm
        var borrowers = getters.getBorrowers
        var complete = true

        // Loan Hub Handlong
        var borrowerList = borrowers
        for (var i = 0; i < form.Fields.length; i++) {
          if (form.Fields[i].HubType === Globals.HUB_TYPE.HUB_TYPE_LOAN)
            borrowerList = [borrowers[0]]
        }

        for (var i = 0; i < form.Fields.length; i++) {
          if (form.Fields[i].SectionID == id) {
            if (form.Fields[i].Type == Globals.FIELD_TYPES.FIELD_TYPE_HUB_SCREEN) {
              complete = complete && getters.isPageComplete(form.Fields[i], getters.getPrimaryBorrower.ID)
            }

            if (form.Fields[i].Type == Globals.FIELD_TYPES.FIELD_TYPE_SCREEN) {
              for (var j = 0; j < borrowerList.length; j++) {
                complete = complete && getters.isPageComplete(form.Fields[i], borrowerList[j].ID)
              }
            }

            if (form.Fields[i].Type == Globals.FIELD_TYPES.FIELD_TYPE_REVIEW_SCREEN) {
              complete = complete && getters.isPageComplete(form.Fields[i], getters.getPrimaryBorrower.ID)
            }
          }
        }

        return complete
      }
    },
    getFieldById: function (state, getters) {
      return function (id, baseFields) {
        var search = function (fields, id) {
          for (var i = 0; i < (fields || []).length; i++) {
            switch (fields[i].Type) {
              case Globals.FIELD_TYPES.FIELD_TYPE_SCREEN:
              case Globals.FIELD_TYPES.FIELD_TYPE_HUB_SCREEN:
                if (fields[i].Id === id)
                  return fields[i]

                var result = search(fields[i].Body || [], id)
                if (result !== null)
                  return result
                continue

              case Globals.FIELD_TYPES.FIELD_TYPE_GROUP:
                if (fields[i].Id === id)
                  return fields[i]

                var result = search(fields[i].Fields || [], id)

                if (result !== null)
                  return result
                continue

              case Globals.FIELD_TYPES.FIELD_TYPE_RESIDENCE_HISTORY:
              case Globals.FIELD_TYPES.FIELD_TYPE_EMPLOYMENT_HISTORY:
              case Globals.FIELD_TYPES.FIELD_TYPE_REPEATING_GROUP:
                if (fields[i].Id === id)
                  return fields[i]

                var result = null

                result = search(fields[i].InitialFields || [], id)

                if (result !== null)
                  return result

                result = search(fields[i].SubsequentFields || [], id)

                if (result !== null)
                  return result

                continue
              default:
                if (fields[i].Id === id)
                  return fields[i]
                continue
            }
          }

          return null
        }

        return search(baseFields || state.form.Fields, id)
      }
    },
    getFieldChain: function (state) {
      return function (id) {
        var search = function (fields, id) {
          for (var i = 0; i < (fields || []).length; i++) {
            switch (fields[i].Type) {
              case Globals.FIELD_TYPES.FIELD_TYPE_SCREEN:
              case Globals.FIELD_TYPES.FIELD_TYPE_HUB_SCREEN:
              case Globals.FIELD_TYPES.FIELD_TYPE_REVIEW_SCREEN:
                if (fields[i].Id === id)
                  return [fields[i]]

                var result = search(fields[i].Body || [], id)

                if (result !== null) {
                  result.push(fields[i])
                  return result
                }
                continue

              case Globals.FIELD_TYPES.FIELD_TYPE_GROUP:
                if (fields[i].Id === id)
                  return [fields[i]]

                var result = search(fields[i].Fields || [], id)

                if (result !== null) {
                  result.push(fields[i])
                  return result
                }
                continue

              case Globals.FIELD_TYPES.FIELD_TYPE_RESIDENCE_HISTORY:
              case Globals.FIELD_TYPES.FIELD_TYPE_EMPLOYMENT_HISTORY:
              case Globals.FIELD_TYPES.FIELD_TYPE_REPEATING_GROUP:
                if (fields[i].Id === id)
                  return [fields[i]]

                var result = null

                result = search(fields[i].InitialFields || [], id)

                if (result !== null) {
                  result.push(fields[i])
                  return result
                }

                result = search(fields[i].SubsequentFields || [], id)

                if (result !== null) {
                  result.push(fields[i])
                  return result
                }

                continue
              default:
                if (fields[i].Id === id)
                  return [fields[i]]
                continue
            }
          }

          return null
        }

        return search(state.form.Fields, id)
      }
    }
  },
  mutations: {
    setForm: function (state, form) {
      state.form = form
    },
    setFormValues: function (state, values) {
      state.values = values
    },
    setSubmitResult: function (state, result) {
      state.submitResult = result
    },
    setFormValue: function (state, args) {
      /*
      if (!args.BorrowerID) {
        console.trace('missing borrower id')
        throw('missing borrower id')
      }
      */

      state.values[args.BorrowerID] = state.values[args.BorrowerID] || {}
      state.values[args.BorrowerID][args.FieldID] = args.Value
    },
    setFormValueAtIndex: function (state, args) {
      args.GroupIndex = args.GroupIndex || 0

      /*
      if (!args.BorrowerID) {
        console.trace('missing borrower id')
        throw('missing borrower id')
      }
      */

      state.values[args.BorrowerID] = state.values[args.BorrowerID] || {}
      state.values[args.BorrowerID][args.FieldID] = state.values[args.BorrowerID][args.FieldID] || []

      while (state.values[args.BorrowerID][args.FieldID].length <= args.GroupIndex)
        state.values[args.BorrowerID][args.FieldID].push(null)

      state.values[args.BorrowerID][args.FieldID][args.GroupIndex] = args.Value
    },
    removeBorrowerValues: function (state, borrowerId) {
      delete state.values[borrowerId]
    },
    resetForm: function (state) {
      state.values = {}
    }
  },
  actions: {
    loadForm: function ({ getters, commit, dispatch }) {
      if (!getters.getSessionToken)
        throw "{{s1003_errors_session}}"

      commit('setLoading', true)

      return Promise.all([
        dispatch('loadFormDefinition'),
        dispatch('loadFormValues')
      ])
      .then(function() {
        if (!getters.getPrimaryBorrower) {
          commit('setSessionToken', null)
          commit('setLoading', false)
          dispatch('go', 'error')
          return
        }

        dispatch('formNavInit', getters.getForm)

        // Fetch form, init borrowers and form storage with loaded data
        commit('becomeBorrower', getters.getPrimaryBorrower.ID)
        dispatch('go', 'form')

        setTimeout(function () {
          commit('setLoading', false)
        }, 1)
      })
    },
    loadFormDefinition: function ({ getters, commit, dispatch }) {
      const companyid = (getters.getCompany || {}).CompanyID || null
      const officerid = (getters.getOfficer || {}).UserID || null
      const realtorid = getters.getRealtorId

      commit('setLoading', true)

      return ApiClient.loadForm(companyid, officerid, realtorid)
        .then(response => {
          if (typeof response == 'object' && response.data && response.data.Success) {
            // Initialize app data
            commit('setForm', response.data.FormStructure)
          } else {
            dispatch('go', 'error')
            //console.log('Error: Invalid API response.')
          }
        })
        .catch(error => {
          dispatch('go', 'error')
          //console.log('Error: Invalid API response.', error)
        })
    },
    loadFormValues: function ({ getters, commit }) {
      return ApiClient.loadSave()
        .then(response => {
          if (typeof response == 'object' && response.data && response.data.Success) {
            var internalId = 1
            var borrowers = []
            var values = {}

            for (var borrowerId in response.data.Values) {
              // Update to loaded form values
              values[internalId] = response.data.Values[borrowerId]
              values[internalId]['smart1003_borrower_id'] = [borrowerId]

              // Update to loaded borrowers
              borrowers.push({
                ID: internalId,
                FirstName: (values[internalId]['smart1003_borrower_info'] || [{}])[0].FirstName || null,
                LastName: (values[internalId]['smart1003_borrower_info'] || [{}])[0].LastName || null,
                EmployerName: (values[internalId]['smart1003_borrower_employer_name'] || [null])[0]
              })

              internalId += 1
            }

            commit('updateBorrowers', borrowers)
            commit('setFormValues', values)
          }
          else {
            dispatch('go', 'error')
            //console.log('Error: Invalid API response.')
          }
        })
    }
  }
}

export default vm
