<template>
  <Slide
    :active-slide="activeSlide"
    class="slide-password"
    :index="slideIndex"
    :button-callback="onCompletePassword"
    :styles="slideStyles"
    :disable-footer="true"
  >
    <div class="container">
      <h2 class="bold">
        <Localize :text="`{{s1003_start_login_reason}}`" />
      </h2>
      <div class="start-layout-grid">
        <div style="grid-area: 1 / 1 / span 1 / span 1;">
          <FieldText
            :definition="fields.password"
            :locked-borrower="1"
          />
        </div>
        <div style="grid-area: 1 / 2 / span 1 / span 1;">
          <FieldText
            :definition="fields.confirm"
            :locked-borrower="1"
          />
        </div>
      </div>
      <div class="slide-footer login-footer">
        <div class="slide-footer-wrapper">
          <div class="login-footer-buttons">
            <button
              class="btn btn-primary"
              @click="onBack"
            >
              <Localize :text="`{{s1003_common_back}}`" />
            </button>
            <button 
              class="btn btn-primary"
              @click="onCompletePassword"
            >
              <Localize :text="`{{s1003_start_continue}}`" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </Slide>
</template>

<script>
import { defineAsyncComponent } from 'vue'

import ApiClient from '@/services/apiclient.js'
import Utils from '@/services/utils.js'
import {START_FIELD_CREATE_PASSWORD_PASSWORD, START_FIELD_CREATE_PASSWORD_CONFIRM} from '@/services/definitions.js'

import FieldText from '@/fields/input/FieldText.vue'
import Slide from '@/components/FormCarouselSlide.vue'
const Localize = defineAsyncComponent(function () { return import('@/components/TextLocalize.vue') })


export default {
  name: 'SlideCreatePassword',
  components: {
    FieldText,
    Slide,
    Localize
  },
  props: {
    activeSlide: {
      type: Number,
      default: 0
    },
    slideStyles: {
      type: Object,
      default: function () {
         return {}
      }
    },
    slideIndex: {
      type: Number,
      default: 0
    }
  },
  emits: [
    'update:continue',
    'update:back'
  ],
  data: () => {
    return {
      fields: {
        password: START_FIELD_CREATE_PASSWORD_PASSWORD,
        confirm: START_FIELD_CREATE_PASSWORD_CONFIRM
      },
      invitationId: null
    }
  },
  computed: {

  },
  mounted: function() {

  },
  methods: {
    onCompletePassword: function () {
      var $this = this

      var passOne = $this.$store.getters.getFormValueAtIndex('start_create_password_password', 0, 1) || ''
      var passTwo = $this.$store.getters.getFormValueAtIndex('start_create_password_confirm', 0, 1) || ''

      var error = Utils.validatePassword(passOne, passTwo)

      if (error)
        $this.$store.commit('openToast', { text: error, type: 'error' })
      else {
        $this.$store.commit('setLoading', true)

        ApiClient.acceptInvitation({
          Code: $this.$store.getters.getInvitationCode,
          Password: passOne,
        })
        .then(function (r) {
          $this.$store.commit('setSessionToken', r.AcceptData.SessionID)
          $this.$emit('update:continue')
          $this.$store.commit('setLoading', false)
        })
      }
    },
    onBack: function () {
      this.$emit('update:back')
    }
  }
}
</script>