<template>
  <FieldRepeatingGroup 
    :definition="definition"
    @update:value="onUpdate"
  />
</template>

<script>
import FieldRepeatingGroup from '@/fields/FieldRepeatingGroup.vue'
import FieldLib from '@/services/fields.js'
import Globals from '@/services/globals.js'
import { DateTime } from 'luxon'

export default {
  name: 'FieldEmployment',
  components: {
    FieldRepeatingGroup
  },
  props: {
    definition: {
      required: true,
      validator: function (value) {
        if (process.env.NODE_ENV == 'development' && !value)
          return true
          
        if ((value || {}).Type !== Globals.FIELD_TYPES.FIELD_TYPE_EMPLOYMENT_HISTORY)
          return false

        if (typeof (value || {}).Id !== 'string')
          return false

        return true
      }
    }
  },
  emits: [
    'update:value'
  ],
  data: () => {
    return {

    }
  },
  computed: {
    safeDefinition: function () {
      return FieldLib.safeDefinition(this)
    }
  },
  methods: {
    onUpdate: function ($event) {
      var monthsHistory = 0

      // 0 = Initial Fields (can be missing but will not be the case for Employment or Residence history)
      // 1-x = Subsequent Fields
      for (var i = 0; i < (FieldLib.getDisplayValue(this.safeDefinition, 0) + 1); i++) {
        var duration = this.getDurationForIndex(i)

        if (duration > monthsHistory)
          monthsHistory = duration
      }

      this.$store.commit('setFormValueAtIndex', { BorrowerID: this.$store.getters.getCurrentBorrowerId, FieldID: this.safeDefinition.Id + "_history", GroupIndex: 0, Value: monthsHistory})

      this.$emit('update:value', $event)
    },
    getDurationForIndex(index) {
      var start = this.parseDate(this.$store.getters.getFormValueAtIndex('smart1003_borrower_employer_start', index))

      if (start && start.isValid)
        return DateTime.now().diff(start).as('months')
      return 0
    },
    parseDate: function (dateString) {
      var ds = (dateString || '').trim()
      if (typeof ds === 'string' && ds.length === 10)
        return DateTime.fromFormat(ds, 'LL/dd/yyyy')
      return null
    }
  }
}
</script>