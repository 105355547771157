import ApiClient from '@/services/apiclient.js'
import Globals from '@/services/globals.js'

const vm = {
  state: {
    fieldUpdates: [],
  },
  getters: {

  },
  mutations: {
    addUpdate: function (state, update) {
      state.fieldUpdates.push(update)
    }
  },
  actions: {
    flushUpdates: function({ state, getters }) {
      var fieldUpdates = state.fieldUpdates.splice(0, state.fieldUpdates.length)
      var apiUpdates = []
      var knownKeys = []

      // If we are sending a loan type update ensure that we are sending all related fields so that it can be calculated accurately
      var appendLoanType = false
      for (var i = 0; i < fieldUpdates.length; i++) {
        if (fieldUpdates[i].FieldID === 'smart1003_loan_details_loan_type' || fieldUpdates[i].FieldID === 'smart1003_refinance_details_type' || fieldUpdates[i].FieldID === 'smart1003_construction_details_type')
          appendLoanType = true
      }

      if (appendLoanType) {
        fieldUpdates.push({
          BorrowerID: getters.getPrimaryBorrower.ID,
          FieldID: 'smart1003_loan_details_loan_type',
          Values: getters.getFormValue('smart1003_loan_details_loan_type', getters.getPrimaryBorrower.ID)
        })
        fieldUpdates.push({
          BorrowerID: getters.getPrimaryBorrower.ID,
          FieldID: 'smart1003_refinance_details_type',
          Values: getters.getFormValue('smart1003_refinance_details_type', getters.getPrimaryBorrower.ID)
        })
        fieldUpdates.push({
          BorrowerID: getters.getPrimaryBorrower.ID,
          FieldID: 'smart1003_construction_details_type',
          Values: getters.getFormValue('smart1003_construction_details_type', getters.getPrimaryBorrower.ID)
        })
      }

      // Ensure that we have only one update per field ID in the result set and that it is the most recent one
      for (var i = fieldUpdates.length - 1; i >= 0; i--) {
        if (knownKeys.indexOf(fieldUpdates[i].FieldID) !== -1)
          continue

        // Replace internal ID with API ID
        fieldUpdates[i].BorrowerID = getters.getFormValueAtIndex('smart1003_borrower_id', 0, fieldUpdates[i].BorrowerID)

        // Remove placeholder for repeating groups with no initial fields
        // Note this currently clobbers the list field count value, but that's not important for save.  If need to fix in the future, maybe init j to 1?
        var chain = getters.getFieldChain(fieldUpdates[i].FieldID)
        var descendantOfInitiallyEmptyRepeatingGroup = false
        for (var j = 0; j < (chain || []).length; j++) {
          if (chain[j].Type === Globals.FIELD_TYPES.FIELD_TYPE_RESIDENCE_HISTORY ||
              chain[j].Type === Globals.FIELD_TYPES.FIELD_TYPE_EMPLOYMENT_HISTORY ||
              chain[j].Type === Globals.FIELD_TYPES.FIELD_TYPE_REPEATING_GROUP) {
                if ((chain[j].InitialFields || []).length === 0)
                  descendantOfInitiallyEmptyRepeatingGroup = true
              }
        }

        if (descendantOfInitiallyEmptyRepeatingGroup)
          fieldUpdates[i].Values = fieldUpdates[i].Values.splice(1, fieldUpdates[i].Values.length)

        for (var j = 0; j < fieldUpdates[i].Values.length; j++)
          if (typeof fieldUpdates[i].Values[j] === 'object' && fieldUpdates[i].Values[j] !== null)
            fieldUpdates[i].Values[j] = JSON.stringify(fieldUpdates[i].Values[j])

        apiUpdates.unshift(fieldUpdates[i])
        knownKeys.push(fieldUpdates[i].FieldID)
      }

      // Submit to API
      if (apiUpdates.length > 0)
        return ApiClient.submitFields(apiUpdates)
      return new Promise((resolve, reject) => { resolve() })
    }
  }
}

export default vm
