<template>
  <header id="page-header">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <img
            class="brand-logo"
            :src="logo"
            @click="logoClick"
          >
          <nav class="header-nav">
            <ul>
              <li class="active">
                <Localize :text="`{{s1003_start_application}}`" />
              </li>
            </ul>
          </nav>

          <div
            class="header-user mobile"
            @click="hamburgerClick"
          >
            <i class="fa-regular fa-bars" />
          </div>
          <div 
            v-if="hasSession"
            class="header-user desktop dropdown dropdown-right"
          >
            <button 
              type="button" 
              class="dropdown-btn"
            >
              {{ sessionEmail }}
            </button>
            <div class="dropdown-content">
              <!-- <a href="#">My Account</a> -->
              <a @click="logoutClick"><Localize :text="`{{s1003_menu_logout}}`" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { defineAsyncComponent } from 'vue'

const Localize = defineAsyncComponent(function () { return import('@/components/TextLocalize.vue') })

export default {
  name: 'FormHeader',
  components: {
    Localize
  },
  emits: [
    'toggle:drawer'
  ],
  computed: {
    logo: function () {
      return this.$store.getters.getLogo
    },
    hasSession: function () {
      return !!this.$store.getters.getSessionToken
    },
    sessionEmail: function () {
      return this.$store.getters.getSessionEmail
    }
  },
  methods: {
    logoClick: function() {
      this.$store.commit('resetForm')
      this.$store.dispatch('go', 'start')
    },
    hamburgerClick: function () {
      this.$emit('toggle:drawer')
    },
    logoutClick: function () {
      var $this = this

      $this.$store.dispatch('logout')
        .then(function () {
          $this.$store.commit('updateBorrowers', [])
          $this.$store.commit('becomeBorrower', null)
          $this.$store.commit('resetForm')
          $this.$store.dispatch('go', 'start')
        })
    }
  }
}
</script>

<style scoped lang="scss">
header {
  .brand-logo {
    cursor: pointer;
  }
}
</style>
