<template>
  <div 
    v-if="optionList.length > 1"
    class="localization-picker-container"
  >
    <select
      :value="activeLocalization"
      @input="onChange"
    >
      <option
        v-for="option in optionList"
        :key="option.Code"
        :value="option.Code"
      >
        {{ option.Name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'LocalizationPicker',
  components: {

  },
  props: {

  },
  computed: {
    activeLocalization: function () {
      return this.$store.getters.getLocalization
    },
    optionList: function () {
      var config = (this.$store.getters.getOfficerConfig || {}).Localization ||
      (this.$store.getters.getCompanyConfig || {}).Localization || {}

      return config.LocalizationLanguages || []
    }
  },
  methods: {
    onChange: function ($event) {
      this.$store.commit('setLocalization', $event.target.value)
    }
  }
}
</script>

<style scoped lang="scss">
.localization-picker-container {
  display: inline-block;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 100;

  @media (max-width: 992px) {
      bottom: 80px;
  }
}
</style>