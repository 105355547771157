<template>
  <div class="field-hubscreen">
    <div class="container">
      <h2 v-if="safeDefinition.Text">
        <Localize :text="safeDefinition.Text" />
      </h2>
      <div v-if="safeDefinition.Description">
        <Localize :text="safeDefinition.Description" />
      </div>
      <div class="field-hubscreen-body">
        <div
          v-for="field in safeDefinition.Body"
          :key="'hubsection_' + field.Id"
          class="field-hubscreen-body-section"
        >
          <legend>
            <Localize :text="field.Text" />
          </legend>

          <ul class="borrower-list">
            <li
              v-for="borrower in borrowerList"
              :key="'borrower_' + borrower.ID"
            >
              <FieldLink
                :definition="field.Fields[0]"
                :borrower="borrower"
                :show-name="isBorrowerHub"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="field-hubscreen-footer">
      <div class="container">
        <template
          v-for="field in safeDefinition.Footer" 
          :key="'footer_' + field.Id"
        >
          <FieldLink
            :definition="field"
            @update:pagecomplete="onPageComplete"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

import FieldLink from '@/fields/FieldLink.vue'
import FieldLib from '@/services/fields.js'
import Globals from '@/services/globals.js'
const Localize = defineAsyncComponent(function () { return import('@/components/TextLocalize.vue') })

export default {
  name: 'FieldHubScreen',
  components: { 
    FieldLink,
    Localize
  },
  props: {
    definition: {
      required: true,
      validator: function (value) {
        if (process.env.NODE_ENV == 'development' && !value)
          return true

        if ((value || {}).Type !== Globals.FIELD_TYPES.FIELD_TYPE_HUB_SCREEN)
          return false

        if (typeof (value || {}).Id !== 'string')
          return false

        if (typeof(value || {}).Text !== 'string')
          return false

        return true
      }
    }
  },
  emits: [
    'update:value'
  ],
  data: () => {
    return {
      
    }
  },
  computed: {
    safeDefinition: function () {
      return FieldLib.safeDefinition(this)
    },
    isBorrowerHub: function () {
      return this.safeDefinition.HubType === Globals.HUB_TYPE.HUB_TYPE_BORROWER
    },
    isLoanHub: function () {
      return this.safeDefinition.HubType === Globals.HUB_TYPE.HUB_TYPE_LOAN
    },
    borrowerList: function () {
      var list = this.$store.getters.getBorrowers

      if (this.isLoanHub && list.length > 1)
        list = list.slice(0, 1)

      return list
    }
  },
  methods: {
    onPageComplete: function ($event) {
      FieldLib.updateDisplayValue(this.safeDefinition, 0, true, this.$emit, this.$store.getters.getPrimaryBorrower.ID)
    }
  }
}
</script>