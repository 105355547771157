<template>
  <div
    v-if="!loading"
    class="disclaimers container"
  >
    <div class="row">
      <div class="col-2 sidebar-left" />
      <div class="col-8">
        <div
          v-if="activeConfig"
          class="disclaimers-container"
        >
          <div
            v-if="showFooterDisclaimer"
            class="disclaimers-text"
          >
            <!-- eslint-disable-next-line -->
            <div v-html="footerDisclaimerText" />
          </div>
          <div class="disclaimers-logos">
            <img
              v-if="showFDIC"
              src="https://s3-us-west-2.amazonaws.com/pam3-public/fdic_member.svg"
              :alt="altFDIC"
            >
            <img
              v-if="showEHO"
              src="https://s3-us-west-2.amazonaws.com/pam3-public/equal_housing_opportunity.svg"
              :alt="altEHO"
            >
            <img
              v-if="showEHL"
              src="https://s3-us-west-2.amazonaws.com/pam3-public/equal_housing_lender.svg"
              :alt="altEHL"
            >
          </div>
        </div>
      </div>
      <div class="col-2 sidebar-right" />
    </div>
  </div>
</template>

<script>
import Terms from '@/services/terms.js'

export default {
  name: 'FooterDisclaimer',
  components: {

  },
  props: {
    
  },
  computed: {
    activeConfig: function () {
      return this.$store.getters.getOfficerConfig || this.$store.getters.getCompanyConfig
    },
    showFooterDisclaimer: function () {
      return this.activeConfig.Disclaimers.ShowDisclaimerFooter
    },
    footerDisclaimerText: function () {
      var localized = this.activeConfig.Disclaimers.DisclaimerFooterLocalized

      for (var i = 0; i < localized.length; i++)
        if (localized[i].Lang === this.$store.getters.getLocalization)
          return localized[i].Value

      return null 
    },
    showFDIC: function () {
      return this.activeConfig.Disclaimers.ShowFDICLogo
    },
    showEHO: function () {
      return this.activeConfig.Disclaimers.ShowEqualHousingOpportunityLogo
    },
    showEHL: function () {
      return this.activeConfig.Disclaimers.ShowEqualHousingLenderLogo
    },
    loading: function () {
      return this.$store.getters.getLoading
    },
    altFDIC: function () {
      return Terms.replace("{{s1003_system_fdic}}")
    },
    altEHO: function () {
      return Terms.replace("{{s1003_system_eho}}")
    },
    altEHL: function () {
      return Terms.replace("{{s1003_system_ehl}}")
    }
  }
}
</script>

<style scoped lang="scss">
  .disclaimers {
    margin-top: 40px;

    .disclaimers-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .disclaimers-text {
        text-align: center;
        margin-bottom: 20px;
      }

      .disclaimers-logos {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 200px;

        img {
          max-height: 50px;
          max-width: auto;
        }
      }
    }

    @media (max-width: 992px) {
      padding-bottom: 70px;
    }
  }
</style>
