<template>
  <div :class="cardClass">
    <div class="inner">
      <div
        v-if="officerAvatar"
        class="officer-photo"
        :style="{ 'background-image': 'url(' + officerAvatar + ')' }"
      />
      <div v-else />

      <div class="officer-info">
        <h2 class="bold">
          {{ officerName }}
        </h2>

        <div
          v-if="officerTitle"
          class="sub text-gray"
        >
          {{ officerTitle }}
        </div>

        <div
          v-if="officerNMLS"
          class="sub text-gray"
        >
          <a
            href="https://nmlsconsumeraccess.org/"
            target="_blank"
          ><Localize :text="`{{s1003_system_nmls}}`" /> {{ officerNMLS }}</a>
        </div>
      </div>

      <hr>

      <div class="officer-contact">
        <div
          v-if="officerPhone"
          class="contact-phone"
        >
          <a :href="'tel:' + officerPhone">
            <i class="fa-solid fa-phone" />
            {{ officerPhone }}
          </a>
        </div>

        <div
          v-if="officerEmail"
          class="contact-email"
        >
          <a :href="'mailto:' + officerEmail">
            <i class="fa-solid fa-envelope" />
            {{ officerEmail }}
          </a>
        </div>

        <div
          v-if="officerWebsite"
          class="contact-website"
        >
          <a
            :href="officerWebsite"
            target="_blank"
          >
            <i class="fa-solid fa-globe" />
            {{ officerWebsite }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

const Localize = defineAsyncComponent(function () { return import('@/components/TextLocalize.vue') })

export default {
  name: 'OfficerCard',
  components: {
    Localize
  },
  props: {
    introBackground: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    officerAvatar: function () {
      return (this.$store.getters.getOfficer || {}).Avatar || null
    },
    officerName: function () {
      var officer = this.$store.getters.getOfficer
      var company = this.$store.getters.getCompany

      if (officer)
        return officer.FullName
      else if (company) {
	      if (company.ManagingCompany)
          return company.ManagingCompany.Name
	      else
          return company.Name
      }
      else
        return null
    },
    officerTitle: function () {
      return (this.$store.getters.getOfficer || {}).Title || null
    },
    officerEmail: function () {
      var officer = this.$store.getters.getOfficer
      var company = this.$store.getters.getCompany

      if (officer)
        return officer.Email
      else if (company)
        return company.PublicContact.Email
      return null
    },
    officerPhone: function () {
      var officer = this.$store.getters.getOfficer
      var company = this.$store.getters.getCompany

      if (officer)
        return officer.Phone
      else if (company)
        return company.PublicContact.Phone
      return null
    },
    officerNMLS: function () {
      var officer = this.$store.getters.getOfficer
      var company = this.$store.getters.getCompany

      if (officer)
        return officer.NMLS
      if (company) {
        if (company.ManagingCompany)
          return company.ManagingCompany.NMLS
        else
          return company.NMLS
      }
      return null
    },
    officerWebsite: function () {
      return (this.$store.getters.getOfficer || {}).Website || null
    },
    cardClass: function () {
      if (this.introBackground)
        return 'officer-card intro-officer-card'
      return 'officer-card form-officer-card'
    }
  }
}
</script>