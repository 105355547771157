import router from '@/services/router.js'
import Globals from '@/services/globals.js'

const vm = {
  state: {
    coordinates: [],
    history: [],

    form: false,

    heartbeatTimer: null
  },
  getters: {
    // Searches the section tree for the node specified by id and returns it along with all it's parents
    getSectionChain: function (state, getters) {
      return function (id, sections) {
        if (!Array.isArray(sections))
          sections = state.form.Sections

        for (var i = 0; i < sections.length; i++) {
          if (sections[i].Id === id)
            return [sections[i]]

          var result = getters.getSectionChain(id, sections[i].Subsections || [])
	        if (result.length > 0) {
            result.push(sections[i])
            return result
	        }
        }

        return []
      }
    },
    // Searches the section tree for the node specified by id
    getSection: function (state, getters) {
      return function (id, sections) {
        var chain = getters.getSectionChain(id, sections)

        if (chain.length > 0)
          return chain[0]

        return null
      }
    },
    // Returns the section tree
    getSectionList: function (state) {
      return state.form.Sections
    },
    // Returns the node in the section tree specified by id and all nodes which are children of that node
    getSectionFamily: function (state, getters) {
      return function (id, section, include) {
        include = include || false
        section = section || { Subsections: state.form.Sections }

        var family = []

        if (include || section.Id === id) {
          family.push(section)
          include = true
        }

        for (var i = 0; i < (section.Subsections || []).length; i++)
          family = family.concat(getters.getSectionFamily(id, section.Subsections[i], include))

        return family
      }
    },
    getSectionBorrowerScope: function (state, getters) {
      return function (id) {
        var scope = true

        var chain = getters.getSectionChain(id)
        if (chain.length > 0) {
          var pages = getters.getSectionFamilyPages(chain[chain.length - 1].Id)
      
          for (var i = 0; i < pages.length; i++) {
            if (pages[i].Type === Globals.FIELD_TYPES.FIELD_TYPE_HUB_SCREEN) {
              if (pages[i].HubType === Globals.HUB_TYPE.HUB_TYPE_LOAN)
                scope = false
	          }
          if (pages[i].Type === Globals.FIELD_TYPES.FIELD_TYPE_REVIEW_SCREEN)
            scope = false
	        }
        }

        return scope
      }
    },
    // Returns the page specified by id, making the assumption that pages are only specified on the root level of the form
    getPage: function (state, getters) {
      return function (id) {
        var index = getters.getPageIndex(id)

        if (index === null)
          return null

        return state.form.Fields[index]
      }
    },
    // Returns the index of the page specified by id in the list of pages, making the assumption that pages are only specified on the root level of the form
    getPageIndex: function (state) {
      return function (id) {
        for (var i = 0; i < state.form.Fields.length; i++)
          if (state.form.Fields[i].Id === id)
            return i

        return null
      }
    },
    // Returns the id of the page specified by our current nav coordinates
    getActivePageId: function (state, getters) {
      if (state.coordinates.length < 1)
        return null

      return state.coordinates[0]
    },
    // Returns the index of the page specified by our current nav coordinates
    getActivePageIndex: function (state, getters) {
      if (state.coordinates.length < 1)
        return 0

      return getters.getPageIndex(state.coordinates[0]) || 0
    },
    // Returns the list of pages, making the assumption that pages are only specified on the root level of the form
    getPageList: function (state) {
      return state.form.Fields
    },
    getSectionFamilyPages: function (state, getters) {
      return function (id) {
        var family = getters.getSectionFamily(id)
        var pages = getters.getPageList
        var results = []

        for (var i = 0; i < pages.length; i++)
          for (var j = 0; j < family.length; j++)
            if (pages[i].SectionID === family[j].Id)
              results.push(pages[i])

        return results
      }
    },
    // Returns whether the id value is contained in our current nav coordinates
    isNavActive: function (state) {
      return function (id) {
        return state.coordinates.indexOf(id) !== -1
      }
    },
    // Returns the entire form structure
    getFormStructure: function (state) {
      return state.form
    }
  },
  mutations: {
    navSetForm: function (state, form) {
      state.form = form
    },
    navSetCoordinates: function (state, coordinates) {
      state.coordinates = coordinates
      state.history.push(coordinates)
    }
  },
  actions: {
    formNavInit: function ({ commit, dispatch }, data) {
      commit('navSetForm', data)
      return dispatch('formGo', data.FormStart)
    },
    formGo: function ({ state, getters, commit }, id) {
      return new Promise((resolve, reject) => {
        var section
        var page
        var coords = []

        if ((section = getters.getSection(id)) !== null) {
          page = getters.getPage(section.Page)
	      }
        else
          page = getters.getPage(id)

        if (!page)
          return

        coords = [page.Id]

        var sections = getters.getSectionChain(page.SectionID)
        for (var i = 0; i < sections.length; i++)
          coords.push(sections[i].Id)

        // Clear page complete flag
        if (page.Type === Globals.FIELD_TYPES.FIELD_TYPE_SCREEN)
          commit('setFormValueAtIndex', { BorrowerID: getters.getCurrentBorrowerId, FieldID: page.Id, GroupIndex: 0, Value: false })
        if (page.Type === Globals.FIELD_TYPES.FIELD_TYPE_HUB_SCREEN) {
          commit('setFormValueAtIndex', { BorrowerID: getters.getPrimaryBorrower.ID, FieldID: page.Id, GroupIndex: 0, Value: false })
          commit('becomeBorrower', getters.getPrimaryBorrower.ID)
        }
        if (page.Type === Globals.FIELD_TYPES.FIELD_TYPE_REVIEW_SCREEN) {
          commit('setFormValueAtIndex', { BorrowerID: getters.getPrimaryBorrower.ID, FieldID: page.Id, GroupIndex: 0, Value: false })
          commit('becomeBorrower', getters.getPrimaryBorrower.ID)
        }

        commit('navSetCoordinates', coords)

        resolve()
      })
    },
    formGoBack: function ({ state, getters, commit }, id) {
      return new Promise((resolve, reject) => {
        if (state.history.length > 1) {
          state.history.pop() // Throw away current page
          var coords = state.history.pop() // Get previous page, will get re-pushed

          commit('navSetCoordinates', coords)
	      }

        resolve()
      })
    },
    go: function ({ state, dispatch }, route, params) {
      if (state.heartbeatTimer !== null)
        clearInterval(state.heartbeatTimer)

      state.heartbeatTimer = setInterval(function () { 
        dispatch('runHeartbeat') 
      }, 1000)

      router.push({ 
        name: route,
        query: router.currentRoute.value.query,
	      params: params
      })
    }
  }
}

export default vm
