<template>
  <FieldRepeatingGroup 
    ref="wrapped"
    :definition="definition" 
    :allow-add="false"
    :allow-remove="false"
    @update:value="onUpdate"
  />
</template>

<script>
import FieldRepeatingGroup from '@/fields/FieldRepeatingGroup.vue'
import FieldLib from '@/services/fields.js'
import Globals from '@/services/globals.js'

export default {
  name: 'FieldResidenceHistory',
  components: {
    FieldRepeatingGroup
  },
  props: {
    definition: {
      required: true,
      validator: function (value) {
        if (process.env.NODE_ENV == 'development' && !value)
          return true

        if ((value || {}).Type !== Globals.FIELD_TYPES.FIELD_TYPE_RESIDENCE_HISTORY)
          return false

        if (typeof (value || {}).Id !== 'string')
          return false

        return true
      }
    }
  },
  emits: [
    'update:value'
  ],
  data: () => {
    return {
      
    }
  },
  computed: {
    safeDefinition: function () {
      return FieldLib.safeDefinition(this)
    }
  },
  methods: {
    onUpdate: function ($event) {
      var monthsHistory = 0
      var blankDurationIndexes = []

      // 0 = Initial Fields (can be missing but will not be the case for Employment or Residence history)
      // 1-x = Subsequent Fields
      for (var i = 0; i < (FieldLib.getDisplayValue(this.safeDefinition, 0) + 1); i++) {
        var previousHistory = monthsHistory

        monthsHistory += (this.$store.getters.getFormValueAtIndex('smart1003_borrower_residence_months', i) || 0) * 1
        monthsHistory += (this.$store.getters.getFormValueAtIndex('smart1003_borrower_residence_years', i) || 0) * 12

        if (i > 0 && previousHistory === monthsHistory)
          blankDurationIndexes.push(i)
      }

      this.$store.commit('setFormValueAtIndex', { BorrowerID: this.$store.getters.getCurrentBorrowerId, FieldID: this.safeDefinition.Id + "_history", GroupIndex: 0, Value: monthsHistory })

      // If we have more than one blank item, remove the last one
      if (blankDurationIndexes.length > 1) {
        this.$refs.wrapped.onRemove(blankDurationIndexes[blankDurationIndexes.length - 1])
      }
      // If no history then pass event through
      // If history exists but is insufficent, add a blank item if one does not exist, otherwise pass event through
      else if (monthsHistory > 0 && monthsHistory < 24) {
        if (blankDurationIndexes.length < 1)
          this.$refs.wrapped.onAdd()
        else
          this.$emit('update:value', $event)
      }
      // If history exists and is sufficient, remove any blank items if they exist, otherwise pass event through
      else {
        if (blankDurationIndexes.length > 0)
          this.$refs.wrapped.onRemove(blankDurationIndexes[0])
        else
          this.$emit('update:value', $event)
      }
    }
  }
}
</script>