<template>
  <div class="field-screen">
    <div class="container">
      <h2 v-if="safeDefinition.Text">
        <Localize :text="safeDefinition.Text" />
      </h2>
      <div v-if="safeDefinition.Description">
        <Localize :text="safeDefinition.Description" />
      </div>
    </div>
    <div class="custom-review-submit container">
      <div
        v-for="borrower in borrowers"
        :key="borrower.ID"
        class="borrower-review"
      >
        <legend>
          {{ borrower.FirstName }} {{ borrower.LastName }}
        </legend>
        <template 
          v-for="section in structure.Sections"
          :key="section.Id"
        >
          <div v-if="showSectionForBorrower(section, borrower)">
            <div
              v-for="subsection in section.Subsections"
              :key="subsection.Id"
            >
              <div
                class="section-review"
              >
                <button
                  type="button"
                  class="btn-default-outline"
                  @click="onEdit(subsection.Page, borrower)"
                >
                  <i class="fa-regular fa-pencil" />
                </button>
                <label><Localize :text="subsection.Name" /></label>
                <div v-if="getPageCompleteForBorrower(subsection.Page, borrower)">
                  <div 
                    v-for="data in getPageFields(subsection.Page, borrower)"
                    :key="data.ID"
                    class="field-review"
                  >
                    <div v-if="data.Value">
                      <b><Localize :text="data.Title" /></b>: <Localize :text="data.Value" />
                    </div>
                  </div>
                </div>
                <div v-if="!getPageCompleteForBorrower(subsection.Page, borrower)">
                  <Localize :text="`{{s1003_review_incomplete}}`" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>

      <div class="field-screen-body layout-list">
        <template
          v-for="field in safeDefinition.Body"
          :key="'body_' + field.Id"
        >
          <Field
            :definition="field"
            @update:value="onChange"
            @click="creditAuthClick"
          />
        </template>
      </div>
    </div>
    <div class="field-screen-footer">
      <div class="container">
        <template
          v-for="field in safeDefinition.Footer"
          :key="field.Id"
        >
          <FieldLink 
            :definition="field" 
            @update:pagecomplete="onPageComplete"
          />
        </template>
      </div>
    </div>

    <FormModal 
      :open="creditAuthOpen" 
      @toggle:modal="toggleCreditAuth"
    >
      <template #body>
        <Localize :text="creditAuthText" />
      </template>
    </FormModal>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { DateTime } from 'luxon'

const Localize = defineAsyncComponent(function () { return import('@/components/TextLocalize.vue') })
import FormModal from '@/components/FormModal.vue'

import FieldLib from '@/services/fields.js'
import Globals from '@/services/globals.js'
import Utils from '@/services/utils.js'

const Field = defineAsyncComponent(function () { return import('@/fields/FormField.vue') })
import FieldLink from '@/fields/FieldLink.vue'

export default {
  name: 'FieldReviewScreen',
  components: {
    Field,
    FieldLink,
    FormModal,
    Localize
  },
  props: {
    definition: {
      required: true,
      validator: function (value) {
        if (process.env.NODE_ENV == 'development' && !value)
          return true

        if ((value || {}).Type !== Globals.FIELD_TYPES.FIELD_TYPE_REVIEW_SCREEN)
          return false

        if (typeof (value || {}).Id !== 'string')
          return false

        if (typeof(value || {}).Text !== 'string')
          return false

        return true
      }
    }
  },
  emits: [
    'update:value'
  ],
  data: () => {
    return {
      creditAuthOpen: false
    }
  },
  computed: {
    safeDefinition: function () {
      return FieldLib.safeDefinition(this)
    },
    structure: function () {
      return this.$store.getters.getForm
    },
    borrowers: function () {
      return this.$store.getters.getBorrowers
    },
    creditAuthText: function () {
      if (this.$store.getters.getOfficerConfig)
        return this.$store.getters.getOfficerConfig.Disclaimers.CreditAuthorization
      else if (this.$store.getters.getCompanyConfig)
        return this.$store.getters.getCompanyConfig.Disclaimers.CreditAuthorization
      return null 
    }
  },
  methods: {
    onEdit: function (pageId, borrower) {
      this.$store.commit('becomeBorrower', borrower.ID)
      this.$store.dispatch('formGo', pageId)
    },
    showSectionForBorrower: function (section, borrower) {
      if (this.$store.getters.getPage(section.Page).HubType === Globals.HUB_TYPE.HUB_TYPE_LOAN)
        return borrower.ID === this.borrowers[0].ID
      return true
    },
    getPageCompleteForBorrower: function (pageId, borrower) {
      return this.$store.getters.getFormValueAtIndex(pageId, 0, borrower.ID) === true
    },
    getPageFields: function (pageId, borrower) {
      var fields = []
      var page = this.$store.getters.getPage(pageId) || {}

      for (var i = 0; i < (page.Body || []).length; i++)
        fields = fields.concat(this.getFieldValues(page.Body[i], borrower, 0))

      return fields
    },
    getFieldValues: function (field, borrower, index) {
      var values = [] 
      index = index || 0

      if(!FieldLib.isVisible(field, index))
        return values

      switch (field.Type) {
        case Globals.FIELD_TYPES.FIELD_TYPE_GROUP:
          for (var i = 0; i < field.Fields.length; i++)
            values = values.concat(this.getFieldValues(field.Fields[i], borrower, index))
          break

        case Globals.FIELD_TYPES.FIELD_TYPE_REPEATING_GROUP:
        case Globals.FIELD_TYPES.FIELD_TYPE_RESIDENCE_HISTORY:
        case Globals.FIELD_TYPES.FIELD_TYPE_EMPLOYMENT_HISTORY:
          for (var i = 0; i < (field.InitialFields || []).length; i++)
              values = values.concat(this.getFieldValues(field.InitialFields[i], borrower, index))

          //if (values.length > 0)
          //  values.push(values.push({ ID: field.Id, Title: '', Value: '' }))

          for (var i = 0; i < this.$store.getters.getFormValueAtIndex(field.Id, 0, borrower.ID); i++) {
            for (var j = 0; j < (field.SubsequentFields || []).length; j++) {
              values = values.concat(this.getFieldValues(field.SubsequentFields[j], borrower, i + 1))
            }

            //values.push(values.push({ ID: field.Id, Title: '', Value: '' }))
          }

          break

        case Globals.FIELD_TYPES.FIELD_TYPE_USER_INFO:
          var props = [
            { Attr: 'FirstName', Display: '{{s1003_common_first_name}}' },
            { Attr: 'MiddleName', Display: '{{s1003_personal_middle_name}}' },
            { Attr: 'LastName', Display: '{{s1003_common_last_name}}' },
            { Attr: 'Suffix', Display: '{{s1003_personal_suffix}}' }
          ]

          values = values.concat(this.decomposeValue(field, index, borrower.ID, props))

          break

        case Globals.FIELD_TYPES.FIELD_TYPE_ADDRESS:
          var props = [
            { Attr: 'Address1', Display: '{{s1003_common_street_address}}' },
            { Attr: 'Address2', Display: '{{s1003_common_address_number}}' },
            { Attr: 'City', Display: '{{s1003_common_city}}' },
            { Attr: 'State', Display: '{{s1003_common_state}}' },
            { Attr: 'Zip', Display: '{{s1003_common_zip}}' },
          ]

          values = values.concat(this.decomposeValue(field, index, borrower.ID, props))

          break

        case Globals.FIELD_TYPES.FIELD_TYPE_MULTIPLE_CHOICE:
          var value = this.$store.getters.getFormValueAtIndex(field.Id, index, borrower.ID)

          for (var i = 0; i < (field.Options || []).length; i++) {
            if ((value + '') === field.Options[i].Value) {
              value = field.Options[i].Text
              break
            }
          }

          if (this.includeValue(value))
            values.push({ ID: field.Id, Title: field.Text, Value: this.formatValue(field, value) })

          break

        default:
          var value = this.$store.getters.getFormValueAtIndex(field.Id, index, borrower.ID)

          if (this.includeValue(value))
            values.push({ ID: field.Id, Title: field.Text, Value: this.formatValue(field, value) })

          break
      }

      return values
    },
    includeValue: function (value) {
      if (value === undefined)
        return false
      if (value === null)
        return false
      if (typeof value === 'string' && value.trim() === '')
        return false

      return true
    },
    formatValue: function (field, value) {
      switch((field || {}).Type) {
        case Globals.FIELD_TYPES.FIELD_TYPE_BOOLEAN:
          if (value === true || value === 'true')
            return 'Yes'
          if (value === false || value === 'false')
            return 'No'
          break

        case Globals.FIELD_TYPES.FIELD_TYPE_DATE:
          if ((value || "").length > 0) {
            var dt = DateTime.fromFormat(value, "yyyy-MM-dd'T'hh:mm:ss")

            if (dt.isValid)
              return dt.setLocale('en-US').toLocaleString(DateTime.DATE_SHORT)
          }
          break

        case Globals.FIELD_TYPES.FIELD_TYPE_TEXT:
          if (field.ValidationType === Globals.FIELD_TEXT_VALIDATION.FIELD_TEXT_VALIDATION_TYPE_SSN)
            return Utils.formatMaskedSSN(value)
          break
      }

      return value
    },
    decomposeValue: function (field, index, borrowerId, props) {
      var values = []
      var value = this.$store.getters.getFormValueAtIndex(field.Id, index, borrowerId) || {}

      for (var i = 0; i < props.length; i++)
        if (this.includeValue(value[props[i].Attr]))
          values.push({ ID: field.Id + '_' + props[i].Attr, Title: field.Text + ' (' + props[i].Display + ')', Value: this.formatValue(field, value[props[i].Attr]) })

      return values
    },
    onChange: function ($event) {
      this.$emit('update:value', $event)
    },
    onPageComplete: function ($event) {
      FieldLib.updateDisplayValue(this.safeDefinition, 0, $event, this.$emit)
    },
    toggleCreditAuth: function () {
      this.creditAuthOpen = !this.creditAuthOpen
    },
    creditAuthClick: function ($event) {
      if ($event.target.nodeName === 'A' || $event.target.parentNode.nodeName === 'A')
        this.toggleCreditAuth()
    }
  }
}
</script>