const vm = {
  state: {
    borrowers: [],
    borrowerid: null,
    borrowercounter: 1
  },
  getters: {
    getBorrowers: function (state) {
      return state.borrowers
    },
    getPrimaryBorrower: function (state) {
      if (state.borrowers.length > 0)
        return state.borrowers[0]
      return null
    },
    getCurrentBorrower: function (state) {
      for (var i = 0; i < state.borrowers.length; i++)
        if (state.borrowers[i].ID === state.borrowerid)
          return state.borrowers[i]

      return null
    },
    getCurrentBorrowerId: function (state) {
      return state.borrowerid
    },
    getBorrowerById: function (state) {
      return function (id) {
        for (var i = 0; i < state.borrowers.length; i++)
          if (state.borrowers[i].ID === id)
            return state.borrowers[i]

        return null
      }
    }
  },
  mutations: {
    becomeBorrower: function (state, id) {
      if (!id && state.borrowers.length > 0)
        id = state.borrowers[0].ID

      state.borrowerid = id
    },
    removeBorrower: function (state, id) {
      for (var i = 0; i < state.borrowers.length; i++)
        if (state.borrowers[i].ID === id) {
          state.borrowers.splice(i, 1)
          return
	      }
    },
    updateBorrowers: function (state, borrowers) {
      state.borrowers = borrowers
      state.borrowercounter = borrowers.length + 1
    }
  },
  actions: {
    addBorrower: function ({ state }, data) {
      return new Promise((resolve, reject) => {
        var id = state.borrowercounter

        state.borrowers.push({
          ID: id,
          FirstName: (data || {}).FirstName || null,
          LastName: (data || {}).LastName || null,
          EmployerName: (data || {}).Employer || null
        })
        state.borrowercounter = state.borrowercounter + 1

        resolve(id)
      })
    },
    syncBorrower: function ({ state, getters }) {
      var borrowers = state.borrowers
      
      for (var i = 0; i < borrowers.length; i++) {
        if (borrowers[i].ID === state.borrowerid) {
          borrowers.FirstName = getters.getFormValueAtIndex('smart1003_borrower_info', 0, state.borrowerid).FirstName
          borrowers.LastName = getters.getFormValueAtIndex('smart1003_borrower_info', 0, state.borrowerid).LastName
        }
      }

      state.borrowers = borrowers
    }
  }
}

export default vm
