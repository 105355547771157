<template>
  <Slide
    :active-slide="activeSlide"
    class="slide-borrower-intro"
    :index="slideIndex"
    :button-callback="onCompleteIntroduction"
    :button-label="`{{s1003_start_continue}}`"
    :styles="slideStyles"
  >
    <div class="container">
      <h2 class="bold">
        <Localize :text="`{{s1003_start_primary_borrower_description1}}`" />
      </h2>
      <label for="loan_borrower_firstname">
        <Localize :text="`{{s1003_start_primary_borrower_description2}}`" />
      </label>
      <div class="slide-left">
        <FieldText 
          :definition="fields.firstName"
          :locked-borrower="borrowerId"
          @update:value="onFirstNameChange"
        />
      </div>
      <div class="slide-right">
        <FieldText 
          :definition="fields.lastName"
          :locked-borrower="borrowerId"
          @update:value="onLastNameChange"
        />
      </div>
      <label for="loan_borrower_email">
        <Localize :text="`{{s1003_start_primary_borrower_email}}`" />
      </label>
      <div class="slide-left">
        <FieldText 
          :definition="fields.email" 
          :locked-borrower="borrowerId"
        />
      </div>
      <div class="slide-right">
        <FieldText 
          :definition="fields.phone" 
          :locked-borrower="borrowerId"
        />
      </div>
      <label for="smart1003_loan_details_loan_type">
        <Localize :text="`{{s1003_start_primary_borrower_loan_type}}`" />
      </label>
      <FieldMultipleChoice 
        :definition="fields.loanType" 
        :locked-borrower="borrowerId"
      />
      <label for="loan_borrower_count">
        <Localize :text="`{{s1003_start_primary_borrower_jointly_title}}`" />
      </label>
      <FieldMultipleChoice 
        :definition="fields.count" 
        :locked-borrower="borrowerId"
      />
      <div 
        v-if="isSoloApplicant"
        class="agreements-wrapper"
      >
        <Localize :text="`{{s1003_start_privacy_policy_description}}`" /> <a @click="togglePrivacyPolicy"><Localize :text="`{{s1003_start_privacy_policy_title}}`" /></a>
        <Localize :text="`{{s1003_start_edisclose_description}}`" /> <a @click="toggleEConsent"><Localize :text="`{{s1003_start_edisclose_title}}`" /></a>
      </div>
    </div>
  </Slide>
</template>

<script>
import { defineAsyncComponent } from 'vue'

import lib from '@/services/fields.js'
import {START_FIELD_PRIMARY_BORROWER_FIRST_NAME, START_FIELD_PRIMARY_BORROWER_LAST_NAME, START_FIELD_PRIMARY_BORROWER_EMAIL, START_FIELD_PRIMARY_BORROWER_PHONE, START_FIELD_PRIMARY_BORROWER_LOAN_TYPE, START_FIELD_PRIMARY_BORROWER_COBORROWERS} from '@/services/definitions.js'

import FieldMultipleChoice from '@/fields/input/FieldMultipleChoice.vue'
import FieldText from '@/fields/input/FieldText.vue'
import Slide from '@/components/FormCarouselSlide.vue'
const Localize = defineAsyncComponent(function () { return import('@/components/TextLocalize.vue') })


export default {
  name: 'SlidePrimaryBorrower',
  components: {
    FieldMultipleChoice,
    FieldText,
    Slide,
    Localize
  },
  props: {
    activeSlide: {
      type: Number,
      default: 0
    },
    slideStyles: {
      type: Object,
      default: function () {
         return {}
      }
    },
    slideIndex: {
      type: Number,
      default: 0
    }
  },
  emits: [
    'update:privacypolicy',
    'update:econsent',
    'update:continue'
  ],
  data: () => {
    return {
      fields: {
        firstName: START_FIELD_PRIMARY_BORROWER_FIRST_NAME,
        lastName: START_FIELD_PRIMARY_BORROWER_LAST_NAME,
        email: START_FIELD_PRIMARY_BORROWER_EMAIL,
        phone: START_FIELD_PRIMARY_BORROWER_PHONE,
        loanType: START_FIELD_PRIMARY_BORROWER_LOAN_TYPE,
        count: START_FIELD_PRIMARY_BORROWER_COBORROWERS
      }
    }
  },
  computed: {
    isSoloApplicant: function () {
      return this.$store.getters.getFormValueAtIndex('loan_borrower_count', 0, this.borrowerId) === 'single'
    },
    isMultipleApplicant: function () {
      return this.$store.getters.getFormValueAtIndex('loan_borrower_count', 0, this.borrowerId) === 'multiple'
    },
    companyName: function () {
      return this.$store.getters.getCompanyName
    },
    borrowerId: function () {
      return (this.$store.getters.getPrimaryBorrower || {}).ID || 1
    }
  },
  methods: {
    togglePrivacyPolicy: function () {
      this.$emit('update:privacypolicy')
    },
    toggleEConsent: function () {
      this.$emit('update:econsent')
    },
    onCompleteIntroduction: function() {
      if (this.validate())
        this.$emit('update:continue', this.$store.getters.getFormValueAtIndex('loan_borrower_count', 0, this.borrowerId))
    },
    getBorrowerInfo: function () {
      return this.$store.getters.getFormValueAtIndex('smart1003_borrower_info', 0, this.borrowerId) || {
        FirstName: null,
        MiddleName: null,
        LastName: null,
        Suffix: null,
        Email: null,
        Phone: null
      }
    },
    onFirstNameChange: function ($event) {
      this.$store.commit('setFormValueAtIndex', { 
        BorrowerID: this.borrowerId, 
        FieldID: 'smart1003_borrower_info', 
        Value: {...this.getBorrowerInfo(), ...{ FirstName: $event.Value }} 
      })
    },
    onMiddleNameChange: function ($event) {
      this.$store.commit('setFormValueAtIndex', { 
        BorrowerID: this.borrowerId, 
        FieldID: 'smart1003_borrower_info', 
        Value: {...this.getBorrowerInfo(), ...{ MiddleName: $event.Value }} 
      })
    },
    onLastNameChange: function ($event) {
      this.$store.commit('setFormValueAtIndex', { 
        BorrowerID: this.borrowerId, 
        FieldID: 'smart1003_borrower_info', 
        Value: {...this.getBorrowerInfo(), ...{ LastName: $event.Value }} 
      })
    },
    onSuffixChange: function ($event) {
      this.$store.commit('setFormValueAtIndex', { 
        BorrowerID: this.borrowerId, 
        FieldID: 'smart1003_borrower_info', 
        Value: {...this.getBorrowerInfo(), ...{ Suffix: $event.Value }} 
      })
    },
    validate: function() {
      var validation = []
      var borrowers = [this.$store.getters.getPrimaryBorrower]

      for (var prop in this.fields)
        validation = validation.concat(lib.validate(this.fields[prop], 0, borrowers))

      if (validation.length === 0)
        return true
      
      for (var i = 0; i < validation.length; i++)
        this.$store.commit('openToast', validation[i])
      return false
    },
  }
}
</script>

<style scoped lang="scss">
label {
  display: block;
  margin-top: 10px;
}

.slide-left {
  display: inline-block;
  width: 50%;
  vertical-align: top;
  padding-right: 1rem;
}

.slide-right {
  display: inline-block;
  width: 50%;
  vertical-align: top;
  padding-left: 1rem;
}
</style>
