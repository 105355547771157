<template>
  <template v-if="isDisplayShort">
    <div class="field-button">
      <button
        type="button"
        class="btn-primary"
        @click="onClick"
      >
        <Localize :text="safeDefinition.Text" />
      </button>
    </div>
  </template>
  <template v-if="isDisplayLong">
    <div class="borrower-photo">
      <img
        v-if="safeDefinition.Icon"
        class="borrower-photo"
        :src="safeDefinition.Icon"
      >
      <i
        v-if="!safeDefinition.Icon"
        class="fa-duotone fa-user-circle fa-2x"
      />
    </div>
    <div class="borrower-id">
      <a
        v-if="showName"
        href="#"
        @click.prevent
      >{{ (borrower || {}).FirstName || '' }} {{ (borrower || {}).LastName || '' }}</a>
      <div
        v-if="safeDefinition.Description"
        class="sub small"
      >
        <Localize :text="safeDefinition.Description" />
      </div>
    </div>
    <div class="borrower-button">
      <template v-if="isTargetComplete">
        <button
          type="button"
          class="btn-default"
          @click="onClick"
        >
          <Localize :text="`{{s1003_common_edit}}`" />
        </button>
        <button
          type="button"
          class="btn-primary-outline"
          :title="localized(`{{s1003_system_completed}}`)"
        >
          <i class="fa-regular fa-check-circle" />
        </button>
      </template>
      <button
        v-else
        type="button"
        class="btn-primary"
        @click="onClick"
      >
        <Localize :text="`{{s1003_common_start}}`" />
      </button>
    </div>
  </template>
</template>


<script>
import { defineAsyncComponent } from 'vue'

const Localize = defineAsyncComponent(function () { return import('@/components/TextLocalize.vue') })
import ApiClient from '@/services/apiclient.js'
import Lib from '@/services/fields.js'
import Globals from '@/services/globals.js'
import Terms from '@/services/terms.js'

const DISPLAY_TYPE_SHORT = 1
const DISPLAY_TYPE_LONG = 2

const ACTION_TYPE_NONE = 1
const ACTION_TYPE_SAVE = 2

export default {
  name: 'FieldLink',
  components: {
    Localize
  },
  props: {
    definition: {
      required: true,
      validator: function (value) {
        if (process.env.NODE_ENV == 'development' && !value)
          return true

        if ((value || {}).Type !== Globals.FIELD_TYPES.FIELD_TYPE_LINK)
          return false

        if (typeof (value || {}).Id !== 'string')
          return false

        if (value.Display === DISPLAY_TYPE_SHORT) {
          if (typeof(value || {}).Text !== 'string')
            return false
        }
        else if (value.Display === DISPLAY_TYPE_LONG) {
          if (typeof(value || {}).Description !== 'string')
            return false
        }
        else {
          return false
        }

        return true
      }
    },
    borrower: {
      type: Object,
      default: null
    },
    showName: Boolean
  },
  emits: [
      'update:pagecomplete'
    ],
  data: () => {
    return {

    }
  },
  computed: {
    safeDefinition: function () {
      return Lib.safeDefinition(this)
    },
    isDisplayShort: function () {
      return this.safeDefinition.Display === DISPLAY_TYPE_SHORT
    },
    isDisplayLong: function () {
      return this.safeDefinition.Display === DISPLAY_TYPE_LONG
    },
    isTargetComplete: function () {
      if (this.safeDefinition.LinkType === Globals.LINK_TYPE.LINK_TYPE_INTERNAL)
        return this.$store.getters.isPageComplete(this.$store.getters.getPage(this.safeDefinition.Target), (this.borrower || {}).ID)

      return false
    }
  },
  methods: {
    onClick: function() {
      const $this = this

      switch ($this.safeDefinition.LinkType) {
        case Globals.LINK_TYPE.LINK_TYPE_INTERNAL:
          if ($this.safeDefinition.Action === ACTION_TYPE_SAVE) {
            if ($this.validatePage($this))
              $this.$emit('update:pagecomplete', true)
            else
              return
          }

          if ($this.borrower != null)
            $this.$store.commit('becomeBorrower', $this.borrower.ID)

          if ($this.safeDefinition.Target)
            $this.$store.dispatch('formGo', $this.safeDefinition.Target)
          break

        case Globals.LINK_TYPE.LINK_TYPE_EXTERNAL:
          window.open($this.safeDefinition.Target, '_blank')
          break

        case Globals.LINK_TYPE.LINK_TYPE_SUBMIT:
          // Validate and complete review page
          if (!$this.validatePage($this))
            return

          // Validate form
          if (!$this.validateForm($this))
            return

          // Submit form
          $this.$store.commit('setLoading', true)

          $this.$store.dispatch('flushUpdates')
            .then(() => {
              ApiClient.submitForm()
                .then(function(submitResult) {
                  if (!submitResult || !submitResult.Success || !submitResult.Result) {
                    $this.$store.commit('setLoading', false)
                    $this.$store.commit('openToast', (submitResult || {}).Message)
                    return
                  }

                  $this.$store.commit('setSubmitResult', submitResult)

                  //if (result.Result.Link) {
                  //  window.location = result.Result.Link
                  //}
                  //else {
                    $this.$store.commit('setLoading', false)
                    $this.$store.commit('setSubmitResult', submitResult.Result)
                    $this.$store.dispatch('go', 'outro')
                  //}
                })
            })
      }
    },
    validatePage: function ($this, id) {
      id = id || $this.$store.getters.getActivePageId
      var errors = Lib.validate($this.$store.getters.getPage(id))
          
      if (errors.length > 0) {
        for (var i = 0; i < errors.length; i++)
          $this.$store.commit('openToast', errors[i])
          return false
      }

      return true
    },
    validateForm: function ($this) {
      var form = $this.$store.getters.getForm
      var complete = true

      for (var i = 0; i < form.Fields.length; i++) {
        if (form.Fields[i].Type == Globals.FIELD_TYPES.FIELD_TYPE_HUB_SCREEN)
          complete = complete && $this.validatePage($this, form.Fields[i].Id)
      }

      return complete
    },
    localized: function (str) {
      return Terms.replace(str)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/_variables.scss';

    .borrower-photo {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      //background: $lighter-gray;
      .svg-inline--fa {
        font-size: 28px;
      }
      i {
        font-size: 28px;
        width: 1rem;
        height: 1rem;
      }
      i::before {
        background-color:  rgba(92, 109, 126, 0.4);
        border-radius: 50%;
      }
    }
    .borrower-id {
      padding-left: 1rem;
      a {
        display: block;
        width: 100%;
      }
      .sub {
        padding-top: 0.2rem;
      }
    }
    .borrower-button {
      flex: 1;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .svg-icon {
      width: 28px;
    }

    .btn-primary-outline {
      margin-left: 10px;
    }
</style>
