<template>
  <div class="slider-wrapper">
    <ul
      v-if="showTabs"
      class="slider-tabs"
    >
      <slot
        name="tabs"
        :styles="tabStyle"
      />
    </ul>
    <div
      class="slider"
      :class="{ 'more-padding': morePadding }"
      :style="slideContainerStyle"
    >
      <!-- TODO: We should pass data here using the v-slot mechanism, but attempting to do so wipes out all the children for some reason -->
      <slot
        name="slides"
        :styles="slideStyle"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormCarousel',
  props: {
    activeSlide: {
      type: Number,
      default: 0
    },
    morePadding: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'update:activeSlide'
  ],
  data: function() {
    return {

    }
  },
  computed: {
/*
    tabs: function () {
      var tabs = []

      for (var i = 0; i < this.$slots.tabs().length; i++)
        if (!!this.$slots.tabs()[i].props.title && !this.$slots.tabs()[i].props.hidden)
          tabs.push({
            title: this.$slots.tabs()[i].props.title,
            subtitle: this.$slots.tabs()[i].props.subtitle,
            icon: this.$slots.tabs()[i].props.icon,
            hidden: this.$slots.tabs()[i].props.tabHidden,
            slideIndex: i,
            activeSlide: this.activeSlide // This isn't actually used, it's here so that activeSlide is used as a computable dependency and the list is forced to recalculate when changing tabs
          })

      return tabs
    },
*/
    countSlides: function () {
      // We can't use this.$slots.slides().length simply, since objects generated by v-for have a different format and are contained under a single root for the v-for node
      var count = 0

      for (var i = 0; i < this.$slots.slides().length; i++) {
        var slide = this.$slots.slides()[i]
        
        // Handle v-for slides
        if (Array.isArray(slide.children)) {
          for (var j = 0; j < slide.children.length; j++)
            if (slide.children[j].type.name === 'FormCarouselSlide')
              count += 1
        }
        // Handle individual slides
        else
          count += 1
      }

      return Math.max(count, 1)
    },
    slideContainerStyle: function () {
      return {
        width: (100 * Math.max(this.countSlides, 1)) + '%',
        marginLeft: (this.activeSlide * -100) + '%'
      }
    },
    slideStyle: function () {
      return {
        width: (100 / Math.max(this.countSlides, 1)) + '%'
      }
    },
    tabStyle: function () {
      var length = 0
      if (typeof this.$slots.tabs === 'function')
        for (var i = 0; i < this.$slots.tabs().length; i++)
          if (!this.$slots.tabs()[i].props.hidden)
            length += 1

      return {
        width: (100 / Math.max(length, 1)) + '%'
      }
    },
    showTabs: function () {
      var show = false

      if (typeof this.$slots.tabs === 'function')
        for (var i = 0; i < this.$slots.tabs().length; i++)
          if (this.$slots.tabs()[i].props.index === this.activeSlide)
            show = !this.$slots.tabs()[i].props.hidden

      return show
    }
  },
  methods: {
    goTo: function(newSlideNum) {
      if (newSlideNum < this.countSlides && newSlideNum >= 0)
        this.$emit('update:activeSlide', newSlideNum)
    },
    next: function() {
      if (this.activeSlide + 1 < this.countSlides)
        this.$emit('update:activeSlide', this.activeSlide + 1)
    },
    prev: function() {
      if (this.activeSlide - 1 >= 0)
        this.$emit('update:activeSlide', this.activeSlide - 1)
    }
  }
}
</script>
