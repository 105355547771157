import { isIOS, isAndroid } from 'mobile-device-detect'

const utils = {
  random: () => {
    return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)
  },
  copyFields: function ($array, copyValue) {
    var fields = []

    for (var i = 0; i < ($array || []).length; i++)
      fields.push(utils.copyField($array[i], copyValue))

    return fields
  },
  copyField: function ($field, copyValue) {
    var $copy = {}

    for (var prop in $field) {
      if (prop === 'Fields') {
        $copy[prop] = utils.copyFields($field[prop], copyValue)
        continue
      }

      if (prop === 'InitialFields') {
        $copy[prop] = utils.copyFields($field[prop], copyValue)
        continue
      }

      if (prop === 'SubsequentFields') {
        $copy[prop] = utils.copyFields($field[prop], copyValue)
        continue
      }

      if (prop === 'Value' && !copyValue) {
        $copy[prop] = null
        continue
      }

      $copy[prop] = $field[prop]
    }

    return $copy
  },
  formatPhone: function (phone) {
    var raw = phone || ''
    if (typeof raw !== 'string')
      raw = ''

    // Only digits are valid so strip input down to digits, including our own previously applied formatting
    raw = raw.replaceAll(/[^\d]+/g, '')
    
    // Can have at most 16 digits, including country code
    if (raw.length > 16)
      raw = raw.substring(0, 16)

    // Split number into components
    var countryCodePrefix = ''
    var countryCodeSuffix = ''
    var areaCode = ''
    var prefix = ''
    var suffix = ''

    if (raw.length > 14) {
      countryCodePrefix = raw.substring(0, raw.length - 14)
      countryCodeSuffix = raw.substring(Math.max(0, raw.length - 14), raw.length - 10)
    }
    else if (raw.length > 13) {
      countryCodePrefix = raw.substring(0, raw.length - 13)
      countryCodeSuffix = raw.substring(Math.max(0, raw.length - 13), raw.length - 10)
    }
    else if (raw.length > 10)
      countryCodeSuffix = raw.substring(0, raw.length - 10)

    if (raw.length > 7)
      areaCode = raw.substring(Math.max(0, raw.length - 10), raw.length - 7)
    if (raw.length > 4)
      prefix = raw.substring(Math.max(0, raw.length - 7), raw.length - 4)
    suffix = raw.substring(Math.max(0, raw.length - 4))

    // Reassemble components into formatted number
    var result = suffix
    if (prefix.length > 0)
      result = prefix + '-' + result
    if (areaCode.length > 0)
      result = '(' + areaCode + ') ' + result
    if (countryCodeSuffix.length > 0) {
      var countryCode = countryCodeSuffix
      if (countryCodePrefix.length > 0)
        countryCode = countryCodePrefix + '-' + countryCode

      result = '+' + countryCode + ' ' + result
    }

    return result
  },
  formatSSNParts: function (ssn) {
    var raw = ssn || ''
    if (typeof raw !== 'string')
      raw = ''

    raw = raw.replaceAll(/[^\d]+/g, '')

    if (raw.length > 9)
      raw = raw.substring(0, 9)

    var first = ''
    var second = ''
    var third = ''

    if (raw.length > 5)
      third = raw.substring(5)
    if (raw.length > 3)
      second = raw.substring(3, Math.min(raw.length, 5))
    first = raw.substring(0, Math.min(raw.length, 3))

    return [first, second, third]
  },
  formatSSN: function (ssn) {
    var parts = utils.formatSSNParts(ssn)

    var result = parts[0]
    if (parts[1].length > 0)
      result = result + "-" + parts[1]
    if (parts[2].length > 0)
      result = result + "-" + parts[2]

    return result
  },
  formatMaskedSSN: function (ssn) {
    var parts = utils.formatSSNParts(ssn)

    var result = parts[0].replaceAll(/./g, '*')
    if (parts[1].length > 0)
      result = result + "-" + parts[1].replaceAll(/./g, '*')
    if (parts[2].length > 0)
      result = result + "-" + parts[2]

    return result
  },
  formatZip: function (zip) {
    var raw = zip || ''
    if (typeof raw !== 'string')
      raw = ''

    raw = raw.replaceAll(/[^\d]+/g, '')

    if (raw.length > 9)
      raw = raw.substring(0, 9)

    var prefix = ''
    var suffix = ''

    if (raw.length > 5)
      suffix = raw.substring(5)
    prefix = raw.substring(0, Math.min(raw.length, 5))

    var result = prefix
    if (suffix.length > 0)
      result = result + "-" + suffix

    return result
  },
  formatDate: function (date) {
    var raw = date || ''
    if (typeof raw !== 'string')
      raw = ''

    raw = raw.replaceAll(/[^\d]+/g, '')

    if (raw.length > 8)
      raw = raw.substring(0, 8)

    var year = ''
    var month = ''
    var day = ''

    if (raw.length > 4)
      year = raw.substring(4)
    if (raw.length > 2)
      day = raw.substring(2, Math.min(raw.length, 4))
    month = raw.substring(0, Math.min(raw.length, 2))

    var result = month
    if (day.length > 0)
      result = result + '/' + day
    if (year.length > 0)
      result = result + '/' + year
    
    return result
  },
  validatePassword: function (passwordOne, passwordTwo) {
    if (!passwordOne)
      return "{{s1003_validation_password_missing}}"

    if (!passwordTwo)
      return "{{s1003_validation_password_confirm}}"

    if (passwordOne != passwordTwo)
      return "{{s1003_validation_password_mismatch}}"

    if (passwordOne.length < 8)
      return "{{s1003_validation_password_short}}"

    if (passwordOne.length > 32)
      return "{{s1003_validation_password_long}}"

    if (!passwordOne.match('.*[a-zA-Z].*'))
      return "{{s1003_validation_password_letter}}"

    if (!passwordOne.match('.*[^a-zA-Z].*'))
      return "{{s1003_validation_password_symbol}}"

    return null
  },
  getDeviceCode: function () {
    if (isIOS)
      return 1
    if (isAndroid)
      return 2
    return 0
  }
}

export default utils
