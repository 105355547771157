<template>
  <Slide
    :active-slide="activeSlide"
    class="slide-login"
    :index="slideIndex"
    :disable-footer="true"
    :styles="slideStyles"
  >
    <div class="container">
      <h2 class="bold">
        <Localize :text="`{{s1003_start_login_cta}}`" />
      </h2>
      <div class="start-layout-grid">
        <div style="grid-area: 1 / 1 / span 1 / span 1;">
          <FieldText
            :definition="email"
            :locked-borrower="1"
          />
        </div>
        <div style="grid-area: 1 / 2 / span 1 / span 1;">
          <FieldText
            :definition="password"
            :locked-borrower="1"
          />
        </div>
        <div 
          :class="{ hidden: !showTwofactor }"
          style="grid-area: 2 / 1 / span 1 / span 2;"
        >
          <FieldText
            :definition="twofactor"
            :locked-borrower="1"
          />
        </div>
      </div>
      <div class="login-footer slide-footer">
        <div class="slide-footer-wrapper">
          <div class="login-footer-password">
            <a @click="onForgotPassword"><Localize :text="`{{s1003_start_forgot_password}}`" /></a>
          </div>
          <div class="login-footer-buttons">
            <button
              class="btn btn-primary"
              @click="onBack"
            >
              <Localize :text="`{{s1003_common_back}}`" />
            </button>
            <button 
              class="btn btn-primary"
              @click="onCompleteLogin"
            >
              <Localize :text="`{{s1003_start_login}}`" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </Slide>
</template>

<script>
import { defineAsyncComponent } from 'vue'

import {START_FIELD_LOGIN_EMAIL, START_FIELD_LOGIN_PASSWORD, START_FIELD_LOGIN_TWOFACTOR} from '@/services/definitions.js'

import FieldText from '@/fields/input/FieldText.vue'
import Slide from '@/components/FormCarouselSlide.vue'
const Localize = defineAsyncComponent(function () { return import('@/components/TextLocalize.vue') })


export default {
  name: 'SlideLogin',
  components: {
    FieldText,
    Slide,
    Localize
  },
  props: {
    activeSlide: {
      type: Number,
      default: 0
    },
    slideStyles: {
      type: Object,
      default: function () {
         return {}
      }
    },
    slideIndex: {
      type: Number,
      default: 0
    }
  },
  emits: [
  'update:continue',
  'update:back'
  ],
  data: () => {
    return {
      email: START_FIELD_LOGIN_EMAIL,
      password: START_FIELD_LOGIN_PASSWORD,
      twofactor: START_FIELD_LOGIN_TWOFACTOR,

      showTwofactor: false
    }
  },
  computed: {

  },
  methods: {
    onForgotPassword: function () {
      this.$emit('update:continue', 'forgot')
    },
    onCompleteLogin: function () {
      var $this = this

      $this.$store.dispatch('loginWithUserCredentials', {
        Username: $this.$store.getters.getFormValueAtIndex('start_login_email', 0, 1),
        Password: $this.$store.getters.getFormValueAtIndex('start_login_password', 0, 1),
        TwoFactor: $this.$store.getters.getFormValueAtIndex('start_login_twofactor', 0, 1)
      })
      .then(function (result) {
        if (result.Code === 2004)
          return
        else if (result.Code === 2009)
          $this.showTwofactor = true
        else if ($this.$store.getters.getSessionToken)
          $this.$emit('update:continue', 'login')
        else
          $this.$store.commit('openToast', { text: '{{s1003_errors_invalid_login}}', type: 'error' })
      })
    },
    onBack: function () {
      this.$emit('update:back')
    }
  }
}
</script>