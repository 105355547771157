import { DateTime } from 'luxon'
import Globals from '@/services/globals'
import Store from '@/store/store.js'

const lib = {
  safeDefinition: function (component) {
    return component.definition || {}
  },
  isValid: function (component) {
    if (!component.touched)
      return true

    var borrowers = []
    if (component.lockedBorrower)
      borrowers = [Store.getters.getBorrowerById(component.lockedBorrower)]
    else {
      if (Store.getters.getCurrentBorrower != null)
        borrowers = [Store.getters.getCurrentBorrower]
      else
        borrowers = [Store.getters.getBorrowers[0]]
    }

    return lib.validate(component.definition, component.groupIndex, borrowers).length === 0
  },
  indexedId: function (field, groupIndex) {
    return field.Id + "_" + groupIndex
  },
  getDisplayValue: function (field, groupIndex, borrowerId) {
    if (field._ParentId)
      return (Store.getters.getFormValueAtIndex(field._ParentId, groupIndex, borrowerId) || {})[field._ParentValueKey]
    return Store.getters.getFormValueAtIndex(field.Id, groupIndex, borrowerId)
  },
  updateDisplayValue: function (field, groupIndex, value, emit, borrowerId) {
    borrowerId = borrowerId || Store.getters.getCurrentBorrowerId

    if (!field._ParentId)
      Store.commit('setFormValueAtIndex', { BorrowerID: borrowerId, FieldID: field.Id, GroupIndex: groupIndex, Value: value })
    emit('update:value', { Id: field.Id, Borrower: borrowerId, Value: value })
  },
  unwrapGroupFields: function (fields) {
    if (Array.isArray(fields) && fields.length === 1 && fields[0].Type === Globals.FIELD_TYPES.FIELD_TYPE_GROUP) {
      return fields[0].Fields
    }
    
    return fields
  },

  validate: function (field, groupIndex, borrowers) {
    var result = []
    groupIndex = groupIndex || 0

    if (Array.isArray(borrowers) && borrowers.length === 0)
      return result

    if (!lib.isVisible(field, groupIndex))
      return result

    if (!borrowers)
      borrowers = [Store.getters.getCurrentBorrower]

    var createError = function (text) {
      return {
        text: text,
        type: 'error',
        fieldId: lib.indexedId(field.Id, groupIndex)
      }
    }

    if (!(field || {}).Type) {
      result.push(createError(field.Text + " {{s1003_errors_field_invalid}}"))
      return result
    }

    // Skip this if it's a composited field for Address or Basic Info - mostly for Start page
    if (field.Required && !field._ParentId && field.Type !== Globals.FIELD_TYPES.FIELD_TYPE_ADDRESS && field.Type !== Globals.FIELD_TYPES.FIELD_TYPE_USER_INFO) {
      result = lib.validateForBorrowers(result, borrowers, function (borrower) {
        var errors = []

        var val = Store.getters.getFormValueAtIndex(field.Id, groupIndex, borrower.ID)
        if (field.Type === Globals.FIELD_TYPES.FIELD_TYPE_BOOLEAN) {
          if (val !== true && val !== false)  
            errors.push(createError(field.Text + " {{s1003_validation_field_required}}"))
        }
        else {
          if (!val)
            errors.push(createError(field.Text + " {{s1003_validation_field_required}}"))
        }

        return errors
      })

      if (result.length > 0)
        return result
    }

    switch (field.Type) {
      case Globals.FIELD_TYPES.FIELD_TYPE_MULTIPLE_CHOICE:
        /*
        // Disabling this since there really shouldn't be any realistic case where the validation fails, and it's interfering with the options override for the start page

        var options = field.Options
        if (typeof field.Options === 'function')
          options = field.Options()

        return lib.validateForBorrowers(result, borrowers, function (borrower) {
          for (var i = 0; i < (options || []).length; i++) {
            if (options[i].Value + '' === (Store.getters.getFormValueAtIndex(field.Id, groupIndex, borrower.ID) || '') + '')
              return []
          }

          return [createError("Please choose a valid value for " + field.Text)]
        })
        */
       return result

      case Globals.FIELD_TYPES.FIELD_TYPE_USER_INFO:
        return lib.validateForBorrowers(result, borrowers, function (borrower) {
          var val = Store.getters.getFormValueAtIndex(field.Id, groupIndex, borrower.ID) || {}
          var errors = []

          /*
          var email = val.Email || ""
          var phone = val.Phone || ""
  
          if ((field.Required || email.length > 0) && !lib.validateEmail(email))
            errors.push(createError("Please enter an email address for " + field.Text))
          if ((field.Required || phone.length > 0) && !lib.validatePhone(phone))
            errors.push(createError("Please enter a phone number for " + field.Text))
            */
  
          if (field.Required && !val.FirstName)
            errors.push(createError("{{s1003_validation_field_firstname}} " + field.Text))
          if (field.Required && !val.LastName)
            errors.push(createError("{{s1003_validation_field_lastname}} " + field.Text))
          return errors
        })

      case Globals.FIELD_TYPES.FIELD_TYPE_DATE:
        return lib.validateForBorrowers(result, borrowers, function (borrower) {
          var errors = []

          var raw = Store.getters.getFormValueAtIndex(field.Id, groupIndex, borrower.ID) || ''
          var dt = DateTime.fromFormat(raw, 'L/dd/yyyy')
          var dt2 = DateTime.fromFormat(raw, "yyyy-MM-dd'T'hh:mm:ss")

          // Blank acceptable if not required
          if (!raw)
            return errors

          if (dt.isValid) {
            if (dt.year < 1900 || dt.year > 2200)
              errors.push(createError("{{s1003_validation_field_date_range}} " + field.Text))
          }
          else if (dt2.isValid) {
            if (dt2.year < 1900 || dt2.year > 2200)
              errors.push(createError("{{s1003_validation_field_date_range}} " + field.Text))
          }
          else {
            errors.push(createError("{{s1003_validation_field_date_valid}} " + field.Text))
          }

          return errors
        })

      case Globals.FIELD_TYPES.FIELD_TYPE_INTEGER:
        return lib.validateForBorrowers(result, borrowers, function (borrower) {
          var value = Number.parseInt(Store.getters.getFormValueAtIndex(field.Id, groupIndex, borrower.ID) || 0)
          var errors = []

          if (Number.isNaN(value)) {
            errors.push(createError("{{s1003_validation_field_number_valid}} " + field.Text + "."))
          }
          else {
            if (value && value < (field.MinValue || (Number.MIN_SAFE_INTEGER + 1)))
              errors.push(createError(field.Text + " {{s1003_validation_field_number_low}} " + field.MinValue))
            if (value && value > (field.MaxValue || (Number.MAX_SAFE_INTEGER - 1)))
              errors.push(createError(field.Text + " {{s1003_validation_field_number_high}} " + field.MaxValue))
          }

          return errors
        })

      case Globals.FIELD_TYPES.FIELD_TYPE_ADDRESS:
        return lib.validateForBorrowers(result, borrowers, function (borrower) {
          var errors = []
          var val = Store.getters.getFormValueAtIndex(field.Id, groupIndex, borrower.ID) || {}

          if (field.Required && !val.Street1)
            errors.push(createError("{{s1003_validation_field_address_street}} " + field.Text))
          if (field.Required && !val.City)
            errors.push(createError("{{s1003_validation_field_address_city}} " + field.Text))
          if (field.Required && !val.State)
            errors.push(createError("{{s1003_validation_field_address_state}} " + field.Text))
          if (field.Required && !val.Zip)
            errors.push(createError("{{s1003_validation_field_address_zip}} " + field.Text))

          return errors
        })

      case Globals.FIELD_TYPES.FIELD_TYPE_GROUP:
        for (var i = 0; i < (field.Fields || []).length; i++)
          result = result.concat(lib.validate(field.Fields[i], groupIndex, borrowers))
        return result

      case Globals.FIELD_TYPES.FIELD_TYPE_CURRENCY:
        return lib.validateForBorrowers(result, borrowers, function (borrower) {
          var errors = []

          if (Number.isNaN(Number.parseFloat(Store.getters.getFormValueAtIndex(field.Id, groupIndex, borrower.ID) || 0)))
            errors.push(createError("{{s1003_validation_field_number_valid}} " + field.Text))

          return errors
        })

      case Globals.FIELD_TYPES.FIELD_TYPE_TEXT:
        return lib.validateForBorrowers(result, borrowers, function (borrower) {
          var errors = []
          var val = null
          
          if (!!field._ParentId)
            val = (Store.getters.getFormValueAtIndex(field._ParentId, groupIndex, borrower.ID) || {})[field._ParentValueKey] || ""
          else
            val = Store.getters.getFormValueAtIndex(field.Id, groupIndex, borrower.ID) || ""

          if (!field.Required && val.length === 0)
            return errors

          switch (field.ValidationType) {
            case Globals.FIELD_TEXT_VALIDATION.FIELD_TEXT_VALIDATION_TYPE_EMAIL:
              if (!lib.validateEmail(val))
                errors.push(createError("{{s1003_validation_field_address_email}} " + field.Text))
              break
            case Globals.FIELD_TEXT_VALIDATION.FIELD_TEXT_VALIDATION_TYPE_PHONE:
              if (!lib.validatePhone(val))
                errors.push(createError("{{s1003_validation_field_address_phone}} " + field.Text))
              break
            case Globals.FIELD_TEXT_VALIDATION.FIELD_TEXT_VALIDATION_TYPE_SSN:
              if (!lib.validateSSN(val))
                errors.push(createError("{{s1003_validation_field_ssn}} " + field.Text))
              break
            case Globals.FIELD_TEXT_VALIDATION.FIELD_TEXT_VALIDATION_TYPE_ZIP:
              if (!lib.validateZip(val))
                errors.push(createError("{{s1003_validation_field_zip}} " + field.Text))
              break
            case Globals.FIELD_TEXT_VALIDATION.FIELD_TEXT_VALIDATION_TYPE_NORMAL:
            default:
              if (val.length === 0)
                errors.push(createError("{{s1003_validation_field_value}} " + field.Text))
              break
          }

          return errors
        })

      case Globals.FIELD_TYPES.FIELD_TYPE_REPEATING_GROUP:
        return lib.validateRepeatingGroup(result, field, borrowers)

      case Globals.FIELD_TYPES.FIELD_TYPE_HIDDEN:
        return result // Internally managed, always assumed to be valid

      case Globals.FIELD_TYPES.FIELD_TYPE_BOOLEAN:
        if (field.RequireTrue)
          return lib.validateForBorrowers(result, borrowers, function (borrower) {
            var errors = []
            if (Store.getters.getFormValueAtIndex(field.Id, groupIndex, borrower.ID) != true)
              errors.push(createError("{{s1003_validation_field_agreement}} " + field.Text))
            return errors
          })
        return result
        /*
        return validateForBorrowers(result, function (borrower) {
          var errors = []

          if (typeof lib.getValueForBorrower(field, borrower) !== 'boolean')
            errors.push(createError("Please enter a valid value for " + field.Text + "."))

          return errors
        })
        */

      case Globals.FIELD_TYPES.FIELD_TYPE_LINK:
        // Hub screen link validation
        /*
        if (field.LinkType === Globals.LINK_TYPE.LINK_TYPE_INTERNAL) {
          var page = Store.getters.getPage(field.Target)
          var sections = Store.getters.getSectionFamily()

          var section = null
          for (var i = 0; i < sections.length; i++)
            if (field.Target === sections[i].Page)
              section = sections[i]

          section = section || { Name: page.Text }

          return lib.validateForBorrowers(result, borrowers, function (borrower) {
            var errors = []

            if (Store.getters.getFormValueAtIndex(field.Target, groupIndex, borrower.ID) !== true)
              errors.push(createError(borrower.FirstName + " " + borrower.LastName + " needs to complete " + section.Name))

            return errors
          })
        }
        */

        return result

      //case FIELD_TYPE_MILITARY_SERVICE:
      //case FIELD_TYPE_DEMOGRAPHICS_ETHNICITY:
      //case FIELD_TYPE_DEMOGRAPHICS_GENDER:
      //case FIELD_TYPE_DEMOGRAPHICS_RACE:

      case Globals.FIELD_TYPES.FIELD_TYPE_PERCENT:
        return lib.validateForBorrowers(result, borrowers, function (borrower) {
          var errors = []

          if (Number.isNaN(Number.parseFloat(Store.getters.getFormValueAtIndex(field.Id, groupIndex, borrower.ID) || 0)))
            errors.push(createError("{{s1003_validation_field_number_valid}} " + field.Text + "."))

          return errors
        })

      case Globals.FIELD_TYPES.FIELD_TYPE_REVIEW_SCREEN:
      case Globals.FIELD_TYPES.FIELD_TYPE_SCREEN:
        for (var i = 0; i < (field.Body || []).length; i++)
          result = result.concat(lib.validate(field.Body[i], 0, borrowers))

        // Ignore footer validation since it's used for navigation, also because of this, it will form a self referential loop if trying to follow links
        //for (var j = 0; j < (field.Footer || []).length; j++)
        //  result = result.concat(lib.validate(field.Footer[j]))

        return result

      case Globals.FIELD_TYPES.FIELD_TYPE_HUB_SCREEN:
        var allBorrowers = Store.getters.getBorrowers

        if (field.HubType === Globals.HUB_TYPE.HUB_TYPE_LOAN)
          allBorrowers = [allBorrowers[0]]

        for (var i = 0; i < (field.Body || []).length; i++)
          result = result.concat(lib.validate(field.Body[i], 0, allBorrowers))

        // Ignore footer validation since it's used for navigation, also because of this, it will form a self referential loop if trying to follow links
        //for (var j = 0; j < (field.Footer || []).length; j++)
        //  result = result.concat(lib.validate(field.Footer[j]))

        return result
      case Globals.FIELD_TYPES.FIELD_TYPE_RESIDENCE_HISTORY:
        var historyErrors = lib.validateForBorrowers(result, borrowers, function (borrower) {
          var errors = []

          var history = Store.getters.getFormValueAtIndex(field.Id + "_history", groupIndex, borrower.ID) || 0
          if (history > 0 && history < 24)
            errors.push(createError("{{s1003_validation_field_residence_history}}"))

          return errors
        })

        result = result.concat(historyErrors)
        return lib.validateRepeatingGroup(result, field, borrowers)

      case Globals.FIELD_TYPES.FIELD_TYPE_EMPLOYMENT_HISTORY:
        var historyErrors = lib.validateForBorrowers(result, borrowers, function (borrower) {
          var errors = []

          var history = Store.getters.getFormValueAtIndex(field.Id + "_history", groupIndex, borrower.ID) || 0
          if (history > 0 && history < 24)
            errors.push(createError("{{s1003_validation_field_employment_history}}"))

          return errors
        })

        result = result.concat(historyErrors)
        return lib.validateRepeatingGroup(result, field, borrowers)

        /*
      case Globals.FIELD_TYPES.FIELD_TYPE_REVIEW_SCREEN:
        if (Store.getters.getFormValueAtIndex(field.Id, 0, 'form') !== true)
          result.push(createError("Please review the contents of the form before submitting."))

        return result
        */
    }

    return false
  },
  validateRepeatingGroup: function (result, field, borrowers) {
    return lib.validateForBorrowers(result, borrowers, function (borrower) {
      var errors = []

      var baseIndex = 0
      if ((field.InitialFields || []).length === 0)
        baseIndex = 1

      var size = (Store.getters.getFormValueAtIndex(field.Id, 0, borrower.ID) || 0) + baseIndex
  
      for (var i = baseIndex; i < size; i++) {
        var children = field.SubsequentFields || []
  
        if (i === 0)
          children = field.InitialFields || []
  
        for (var j = 0; j < children.length; j++)
          errors = errors.concat(lib.validate(children[j], i, borrowers))
      }
  
      return errors
    })
  },
  validateForBorrowers: function (result, borrowers, func) {
    var getBorrowerName = function (borrower) {
      if (!borrower)
        return ''

      return borrower.FirstName + ' ' + borrower.LastName
    }

    for (var i = 0; i < borrowers.length; i++) {
      var errors = func(borrowers[i])

      if (borrowers.length > 1) {
        for (var j = 0; j < errors.length; j++)
          errors[j].text = errors[j].text + " {{s1003_system_for}} " + getBorrowerName(borrowers[i])
      }

      result = result.concat(errors)
    }

    return result
  },
  validateEmail: function (str) {
    return /.*@.*\..*/.test(str)
  },
  validatePhone: function (str) {
    return /([0-9]?).*([0-9]{3}).*([0-9]{3}).*([0-9]{4})/.test(str)
  },
  validateSSN: function (str) {
    return /([0-9]{3})-?([0-9]{2})-?([0-9]{4})/.test(str)
  },
  validateZip: function (str) {
    return /([0-9]{5})-?([0-9]{4})?/.test(str)
  },
  isVisible: function (definition, groupIndex) {
    // Always off
    if (definition.Visibility === 1)
      return false
    // Always on
    if (definition.Visibility === 3)
      return false
  
    // We are not always on or always off, so check to see if we have conditional visibility
    var pass = true
        
    for (var i = 0; i < (definition.RequiredFields || []).length; i++) {
      // Required values are always sent by the API as strings, so convert for comparison
      var value = Store.getters.getFormValueAtIndex(definition.RequiredFields[i].FieldId, groupIndex) + ''
  
      if (definition.RequiredFields[i].NotValue) {
        if (value === definition.RequiredFields[i].RequiredValue)
          pass = false
      }
      else {
        if (value !== definition.RequiredFields[i].RequiredValue)
          pass = false
      }
    }
  
    return pass
  }
}

export default lib
