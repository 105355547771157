<template>
  <div
    class="page"
    data-page="start"
  >
    <div id="page-logo">
      <img
        class="brand-logo"
        :src="logo"
      >
    </div>

    <div class="container pt-4">
      <div 
        class="row"
        :style="{ display: $store.getters.getLoading ? 'none' : 'block' }"
      >
        <div class="col-2 sidebar-left" />
        <div class="col-8 card main-card">
          <Carousel
            v-model:activeSlide="activeSlide"
            :more-padding="true"
          >
            <template #tabs="slotProps">
              <Tab
                v-model:activeSlide="activeSlide"
                :index="SLIDE_WELCOME"
                icon="fas fa-rocket fa-2x"
                title="{{s1003_start_welcome}}"
                subtitle="{{s1003_start_started}}"
                :styles="(slotProps || {}).styles || {}"
              />

              <Tab
                v-model:activeSlide="activeSlide"
                :index="SLIDE_PRIMARY_BORROWER"
                icon="fas fa-user fa-2x"
                title="{{s1003_start_introduction}}"
                subtitle="{{s1003_start_primary_borrower_description1}}"
                :styles="(slotProps || {}).styles || {}"
              />

              <Tab
                v-model:activeSlide="activeSlide"
                :index="SLIDE_COBORROWERS"
                :hidden="isCoBorrowerTabHidden"
                icon="fas fa-user-friends fa-2x"
                title="{{s1003_system_coborrower_description1}}"
                subtitle="{{s1003_system_coborrower_description2}}"
                :styles="(slotProps || {}).styles || {}"
              />
            </template>

            <template #slides="slotProps">
              <SlideHasOfficer
                :active-slide="activeSlide"
                :slide-styles="(slotProps || {}).styles || {}"
                :slide-index="SLIDE_HAS_OFFICER"
                @update:continue="onHasOfficerContinue"
              />

              <SlideOfficerSearch
                :active-slide="activeSlide"
                :slide-styles="(slotProps || {}).styles || {}"
                :slide-index="SLIDE_OFFICER_SEARCH"
                @update:continue="onSelectOfficerContinue"
                @update:back="onSelectOfficerBack"
              />

              <SlideWelcome
                :active-slide="activeSlide"
                :slide-styles="(slotProps || {}).styles || {}"
                :slide-index="SLIDE_WELCOME"
                @update:create="onWelcomeContinue"
                @update:login="onWelcomeContinueLogin"
              />

              <SlidePrimaryBorrower
                ref="primaryBorrower"
                :active-slide="activeSlide"
                :slide-styles="(slotProps || {}).styles || {}"
                :slide-index="SLIDE_PRIMARY_BORROWER"
                @update:privacypolicy="togglePrivacyPolicy"
                @update:econsent="toggleEConsent"
                @update:continue="onPrimaryBorrowerContinue"
              />

              <SlideCoBorrower
                ref="coBorrower"
                :active-slide="activeSlide"
                :slide-styles="(slotProps || {}).styles || {}"
                :slide-index="SLIDE_COBORROWERS"
                @update:privacypolicy="togglePrivacyPolicy"
                @update:econsent="toggleEConsent"
                @update:continue="onCoBorrowerContinue"
                @update:back="onCoBorrowerBack"
              />

              <SlideCreatePassword
                :active-slide="activeSlide"
                :slide-styles="(slotProps || {}).styles || {}"
                :slide-index="SLIDE_PASSWORD"
                @update:continue="onCreatePasswordContinue"
                @update:back="onCreatePasswordBack"
              />

              <SlideLogin
                :active-slide="activeSlide"
                :slide-styles="(slotProps || {}).styles || {}"
                :slide-index="SLIDE_LOGIN"
                @update:continue="onLoginContinue"
                @update:back="onLoginBack"
              />
            </template>
          </Carousel>
        </div>
        <div class="col-2 sidebar-right" />
      </div>
    </div>

    <FormModal 
      :open="privacyPolicyOpen" 
      @toggle:modal="togglePrivacyPolicy"
    >
      <template #body>
        <TextLocalize :text="privacyPolicyText" />
      </template>
    </FormModal>

    <FormModal 
      :open="eConsentOpen" 
      @toggle:modal="toggleEConsent"
    >
      <template #body>
        <TextLocalize :text="econsentText" />
      </template>
    </FormModal>

    <LocalizationPicker />
    <FooterDisclaimer />
  </div>
</template>

<script>
import ApiClient from '@/services/apiclient.js'

import Carousel from '@/components/FormCarousel.vue'
import Tab from '@/components/FormCarouselTab.vue'
import FooterDisclaimer from '@/components/FooterDisclaimer.vue'
import FormModal from '@/components/FormModal.vue'
import TextLocalize from '@/components/TextLocalize.vue'
import LocalizationPicker from '@/components/LocalizationPicker.vue'

import SlideHasOfficer from '@/components/start/SlideHasOfficer.vue'
import SlideOfficerSearch from '@/components/start/SlideOfficerSearch.vue'
import SlideWelcome from '@/components/start/SlideWelcome.vue'
import SlidePrimaryBorrower from '@/components/start/SlidePrimaryBorrower.vue'
import SlideCoBorrower from '@/components/start/SlideCoBorrower.vue'
import SlideCreatePassword from '@/components/start/SlideCreatePassword.vue'
import SlideLogin from '@/components/start/SlideLogin.vue'


export default {
  name: 'PageStart',
  components: {
    Carousel,
    Tab,
    FooterDisclaimer,
    FormModal,
    TextLocalize,
    LocalizationPicker,

    SlideHasOfficer,
    SlideOfficerSearch,
    SlideWelcome,
    SlidePrimaryBorrower,
    SlideCoBorrower,
    SlideCreatePassword,
    SlideLogin,
    SlidePrimaryBorrower,
    SlideCreatePassword,
    SlideLogin
  },
  data: () => {
    return {
      isCoBorrowerTabHidden: true,
      activeSlide: 0,
      privacyPolicyOpen: false,
      eConsentOpen: false,

      SLIDE_HAS_OFFICER: 0,
      SLIDE_OFFICER_SEARCH: 1,
      SLIDE_WELCOME: 2,
      SLIDE_PRIMARY_BORROWER: 3,
      SLIDE_COBORROWERS: 4,
      SLIDE_PASSWORD: 5,
      SLIDE_LOGIN: 6
    }
  },
  computed: {
    logo: function() {
      return this.$store.getters.getLogo
    },
    privacyPolicyText: function () {
      if (this.$store.getters.getOfficerConfig)
        return this.$store.getters.getOfficerConfig.Disclaimers.PrivacyPolicy
      else if (this.$store.getters.getCompanyConfig)
        return this.$store.getters.getCompanyConfig.Disclaimers.PrivacyPolicy
      return null 
    },
    econsentText: function () {
      if (this.$store.getters.getOfficerConfig)
        return this.$store.getters.getOfficerConfig.Disclaimers.EConsent
      else if (this.$store.getters.getCompanyConfig)
        return this.$store.getters.getCompanyConfig.Disclaimers.EConsent
      return null 
    }
  },
  mounted: function() {
    this.setInitSlide()

    // Create initial borrower
    if (this.$store.getters.getPrimaryBorrower === null)
      this.$store.dispatch('addBorrower')
  },
  methods: {
    setInitSlide: function () {
      if (this.$store.getters.getLoginAction === 'Login')
        this.activeSlide = this.SLIDE_LOGIN
      // If we have an officer already loaded from URL params then automatically select them
      else if (!!this.$store.getters.getOfficer)
        this.activeSlide = this.SLIDE_WELCOME
      else
        this.activeSlide = this.SLIDE_HAS_OFFICER
    },
    onHasOfficerContinue: function(value) {
      if (value.Value)
        this.activeSlide = this.SLIDE_OFFICER_SEARCH
      else {
        this.activeSlide = this.SLIDE_WELCOME
      }
    },
    onSelectOfficerContinue: function () {
      this.activeSlide = this.SLIDE_WELCOME
    },
    onSelectOfficerBack: function () {
      this.activeSlide = this.SLIDE_HAS_OFFICER
    },
    onWelcomeContinue: function() {
      this.activeSlide = this.SLIDE_PRIMARY_BORROWER
    },
    onWelcomeContinueLogin: function () {
      this.activeSlide = this.SLIDE_LOGIN
    },
    onPrimaryBorrowerContinue: function ($event) {
      if ($event === 'multiple') {
        if (this.$store.getters.getBorrowers.length < 2)
          this.$store.dispatch('addBorrower')

        this.isCoBorrowerTabHidden = false
        this.activeSlide = this.SLIDE_COBORROWERS
      }
      else
        this.registerBorrower()
    },
    onCoBorrowerContinue: function () {
      this.registerBorrower()
    },
    onCoBorrowerBack: function () {
      this.isCoBorrowerTabHidden = true
      this.activeSlide = this.SLIDE_PRIMARY_BORROWER
    },
    onCreatePasswordContinue: function () {
      this.loadForm()
    },
    onCreatePasswordBack: function () {
      if (this.$store.getters.getBorrowers.length > 1)
        this.activeSlide = this.SLIDE_COBORROWERS
      else
        this.activeSlide = this.SLIDE_PRIMARY_BORROWER
    },
    onLoginContinue: function (target) {
      if (target === 'login') {
        this.loadForm()
      }
      else if (target === 'forgot') {
        var arg = 'Origin=LoanApplication'

        arg = arg + '&CompanyID=' + this.$store.getters.getCompany.CompanyID

        if (this.$store.getters.getOfficer)
          arg = arg + '&UserID=' + this.$store.getters.getOfficer.UserID

        if (this.$store.getters.getRealtorId)
          arg = arg + '&RealtorID=' + this.$store.getters.getRealtorId

        window.open(process.env.VUE_APP_UI_BASE_URL + 'User/ForgotPassword?' + arg, "_blank")
      }
    },
    onLoginBack: function () {
      this.activeSlide = this.SLIDE_WELCOME
    },
    onForgotPasswordBack: function () {
      this.activeSlide = this.SLIDE_LOGIN
    },
    onResetPasswordContinue: function () {
      this.activeSlide = this.SLIDE_LOGIN
    },
    registerBorrower: function () {
      var $this = this
      var validation = true

      validation = validation && $this.$refs['primaryBorrower'].validate()
      validation = validation && $this.$refs['coBorrower'].validate()

      if (!validation)
        return

      $this.$store.commit('setLoading', true)

      if (this.$store.getters.getSessionToken) {
        $this.$store.dispatch('createLoan')
          .then(function (result) {
            $this.$store.commit('setLoanId', result.LoanID)
            $this.loadForm()
          })
        return
      }

      ApiClient.registerBorrower()
        .then(function (result) {
          switch ((result || {}).Code) {
            case 0:
              $this.$store.commit('setInvitationCode', result.InvitationCode)
              $this.activeSlide = $this.SLIDE_PASSWORD

              break

            case 1003:
              $this.$store.commit('openToast', {
                text: '{{s1003_errors_form_invalid}}',
                type: 'error'
              })

              break

            case 4001:
              $this.$store.commit('openToast', {
                text: '{{s1003_system_account_exists}}',
                type: 'error'
              })

              $this.activeSlide = $this.SLIDE_LOGIN

              break

            default:
              $this.$store.commit('openToast', {
                text: result.Message || '{{s1003_errors_api}}',
                type: 'error'
              })

              break
          }

          $this.$store.commit('setLoading', false)
        })
    },
    loadForm: function () {
      this.$store.dispatch('initForSession')
    },
    togglePrivacyPolicy: function () {
      this.privacyPolicyOpen = !this.privacyPolicyOpen
    },
    toggleEConsent: function () {
      this.eConsentOpen = !this.eConsentOpen
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  #page-logo {
    padding: 4.5rem 0 3rem;
    text-align: center;
    img {
      width: 200px;
    }
  }
}
</style>
