import ApiClient from '@/services/apiclient.js'

const vm = {
  state: {
    loanId: null
  },
  getters: {
    getLoanId: function (state) {
      return state.loanId
    }
  },
  mutations: {
    setLoanId: function (state, id) {
      state.loanId = id
    }
  },
  actions: {
    createLoan: function ({ }) {
      return ApiClient.createLoan()
    },
    getLoanById: function ({ }) {
      return ApiClient.getLoanExists()
    },
    getLoanBySearch: function ({ }) {
      return ApiClient.searchLoanExists()
    }
  }
}

export default vm
