import { createApp } from 'vue'
import Vuex from 'vuex'

import App from './App.vue'
import Router from './services/router'
import Store from './store/store'
import Terms from './services/terms'
//import Field from '@/components/Field.vue'
import './assets/scss/index.scss'

if (process.env.NODE_ENV != 'development') {
  window.onbeforeunload = function () {
    //if (valueChangeObserver() > valueChangeCheckpoint)
    //  return "You have unsaved changes.  Are you sure?";
    //if (self.submitting)
    //  return "Your loan application is in the process of being submitted.  Please press cancel to stay on the page.";
    if (Store.getters.getSubmitResult === null)
      return Terms.replace("{{s1003_system_beforeunload}}")
  }
}

const app = createApp(App)

//app.component('Field', Field)

app.use(Router)
//app.use(Vuex)
app.use(Store)

app.mount('#app')


//app.config.productionTip = false
