const vm = {
  state: {
    
  },
  getters: {
    
  },
  mutations: {
    
  },
  actions: {
    initForSession: function ({ getters, commit, dispatch }) {
      if (getters.getSessionToken)
        dispatch('initForLoan')
      else {
        commit('setReady', true)
        dispatch('go', 'start')
      }
    },
    initForLoan: function ({ getters, commit, dispatch }) {
      return new Promise((resolve, reject) => {
        if (getters.getLoanId) {
          return dispatch('getLoanById')
            .then((loan) => {
              if (loan)
                dispatch('initForLoanData', loan)
              else {
                commit('setReady', true)
                dispatch('go', 'submitcomplete')
              }
            })
        }
        else {
          return dispatch('getLoanBySearch')
            .then((loan) => {
              if (loan)
                dispatch('initForLoanData', loan)
              else {
                commit('setReady', true)
                dispatch('go', 'submitcomplete')
              }
            })
        }
      })
    },
    initForLoanData: function ({ getters, commit, dispatch }, loan) {
      return new Promise((resolve, reject) => {
        commit('setLoanId', loan.LoanID)

        if (loan.LoanOfficerID != (getters.getOfficer || {}).UserID) {
          // Make sure to wipe any stored officer in case there is no officer assigned, initOfficer will abort and leave an existing officer in place
          commit('setOfficer', { Officer: null, Theme: null, Config: null })
          dispatch('initOfficer', { CompanyID: loan.CompanyID, UserID: loan.LoanOfficerID })
            .then(() => { dispatch('loadForm') })
              .then(() => { 
                commit('setReady', true) 
                resolve()
              })
        }
        else
          dispatch('loadForm')
            .then(() => { 
              commit('setReady', true) 
              resolve()
            })
      })
    }
  }
}

export default vm
