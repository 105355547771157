<template>
  <div />
</template>

<script>
export default {
  name: 'PageInit',
  data: () => {
    return {
      params: {}
    }
  },

  mounted: function() {
    this.params = this.getParams()

    this.$store.commit('setLoginAction', this.params.Action)
    this.$store.commit('setRealtorId', this.params.RealtorID || null)
    this.$store.commit('setLoanId', this.params.LoanID)

    this.initCompanyConfiguration()
  },

  methods: {
    getParams: function () {
      var lookup = {}

      var href = window.location.href.replace(/^(#\/)+|(#\/)+$/gm, '')
      var query = href.split('?')

      if (query.length !== 2) return lookup

      var params = query[1].split('&')
      for (var i = 0; i < params.length; i++) {
        var keyVal = params[i].split('=')
        if (keyVal.length !== 2) continue

        keyVal[0] = decodeURIComponent(keyVal[0])
        keyVal[1] = decodeURIComponent(keyVal[1])

        lookup[keyVal[0]] = keyVal[1]
      }

      return lookup
    },
    initCompanyConfiguration: function () {
      var $this = this
      
      Promise.all([
        $this.$store.dispatch('initCompany', $this.params.CompanyID),
        $this.$store.dispatch('initOfficer', { CompanyID: $this.params.CompanyID, UserID: $this.params.OfficerID })
      ])
      .then(r => {
        var company = r[0]

        if (!company) {
          $this.$store.commit('setReady', true)
          $this.$store.dispatch('go', 'error')
        }
        else {
          $this.$store.dispatch('initLocalization')
          $this.initSession()
        }
      })
    },
    initSession: function () {
      var $this = this

      $this.initSessionFromCode()
        .then(() => { return $this.initSessionFromStorage() })
          .then(() => { return $this.$store.dispatch('initForSession') })
    },
    initSessionFromCode() {
      var $this = this

      return new Promise((resolve, reject) => {
        if ($this.$store.getters.getSessionToken)
          resolve()

        $this.$store.dispatch('loginWithAccessCode', $this.params.AccessCode).then(() => { resolve() })
      })
    },
    initSessionFromStorage() {
      var $this = this

      return new Promise((resolve, reject) => {
        if ($this.$store.getters.getSessionToken)
          resolve()

        $this.$store.dispatch('loginWithStoredCredentials').then(() => { resolve() })
      })
    }
  }
}
</script>