<template>
  <div class="page">
    <div class="container pt-4">
      <div class="page-form-row row">
        <div class="card main-card">
          <img
            class="brand-logo"
            :src="$store.getters.getLogo || '../assets/logo_tmp.png'"
          >
          <div class="text-center">
            <Localize :text="`{{s1003_system_application1}}`" />
          </div>
          <div class="text-center">
            <Localize :text="`{{s1003_system_application2}}`" />
          </div>
          <div class="button-grid">
            <button 
              class="btn btn-primary" 
              style="grid-column-start: 1; grid-column-end: span 1;"
              @click="goApplication"
            >
              <Localize :text="`{{s1003_system_application_start}}`" />
            </button>
            <button 
              class="btn btn-default" 
              style="grid-column-start: 2; grid-column-end: span 1;"
              @click="goPortal"
            >
              <Localize :text="`{{s1003_system_application_portal}}`" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiClient from '@/services/apiclient.js'
import Localize from '@/components/TextLocalize.vue'

export default {
  name: 'PageOutro',
  components: {
    Localize
  },
  data: () => {
    return {

    }
  },
  mounted: function() {

  },
  methods: {
    goApplication: function () {
      this.$store.dispatch('go', 'start')
    },
    goPortal: function () {
      ApiClient.APIGetDeviceRedirect()
        .then((result) => {
          if ((result || {}).Link)
            window.location = result.Link
          else {
            // should already show an error here so probably don't need to show another
          }
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/_variables.scss';

.container {
  display: flex;
  height: 100vh;
  align-items: center;
}

.card {
  text-align: center;
  padding: 40px 15px;
  font-size: 24px;
}

.brand-logo {
  margin-bottom: 30px;
  width: auto;
  max-height: 80px;
}

.page-form-row {
  line-height: normal;
}

.text-center {
  text-align: center;
}

.button-grid {
    @media only screen and (min-width: $break-laptop) {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: stretch;
      justify-items: stretch;
      row-gap: 10px;
      column-gap: 20px;
    }
  }
</style>
