import { createRouter, createWebHistory } from 'vue-router'
import PageStart from '@/views/PageStart.vue'
import PageForm from '@/views/PageForm.vue'
import PageError from '@/views/PageError.vue'
import PageInit from '@/views/PageInit.vue'
import PageOutro from '@/views/PageOutro.vue'
import PageSubmitComplete from '@/views/PageSubmitComplete'
import store from '../store/store'

const routes = [
  {
    path: '/',
    name: 'init',
    component: PageInit
  },
  {
    path: '/Start',
    name: 'start',
    component: PageStart
  },
  {
    path: '/Form',
    name: 'form',
    component: PageForm
  },
  {
    path: '/FormSubmit',
    name: 'outro',
    component: PageOutro
  },
  {
    path: '/FormComplete',
    name: 'submitcomplete',
    component: PageSubmitComplete
  },
  {
    path: '/Error',
    name: 'error',
    component: PageError
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes
})

router.beforeEach(function (to, from, next) {
    // If users try and load to a page directly, make sure they go through init first.
    if (!store.getters.getReady && to.path !== '/')
      return next({ name: 'init', params: to.params, query: to.query})

    return next()
})

export default router
