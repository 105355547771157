<template>
  <div
    class="input-wrapper"
    :class="{ invalid: !isValid }"
  >
    <label
      v-if="safeDefinition.Text && !safeDefinition._HideTitle"
      :for="indexedId"
      :title="localized(safeDefinition.Text)"
    ><Localize :text="safeDefinition.Text" /></label>
    <div class="input-dropdown-wrapper">
      <select
        :id="indexedId"
        :required="safeDefinition.Required"
        class="input-dropdown"
        :class="{ 'active-placeholder': (displayValue || '').length > 0 }"
        :value="displayValue"
        @input="onChange"
      >
        <option value="" />
        <option
          v-for="option in optionList"
          :key="option.Value"
          :value="option.Value"
          :disabled="option.Disabled"
        >
          <Localize :text="option.Text" />
        </option>
      </select>
      <i class="fa-solid fa-caret-down" />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

import Lib from '@/services/fields.js'
import Globals from '@/services/globals.js'
import Terms from '@/services/terms.js'
const Localize = defineAsyncComponent(function () { return import('@/components/TextLocalize.vue') })

export default {
  name: 'FieldMultipleChoice',
  components: {
    Localize
  },
  props: {
    definition: {
      required: true,
      validator: function (value) {
        if (process.env.NODE_ENV == 'development' && !value)
          return true

        if ((value || {}).Type !== Globals.FIELD_TYPES.FIELD_TYPE_MULTIPLE_CHOICE)
          return false

        if (typeof (value || {}).Id !== 'string')
          return false

        if (typeof(value || {}).Text !== 'string')
          return false

        if (!Array.isArray((value || {}).Options))
          if (typeof (value || {}).Options !== 'function')
            return false

        return true
      }
    },
    groupIndex: {
      type: Number,
      default: 0
    },
    lockedBorrower: {
      type: Number,
      default: 0
    },
    optionsOverride: {
      type: Function,
      default: null
    }
  },
  emits: [
    'update:value'
  ],
  data: () => {
    return {
      touched: false
    }
  },
  computed: {
    safeDefinition: function () {
      return Lib.safeDefinition(this)
    },
    isValid: function () {
      return Lib.isValid(this)
    },
    optionList: function () {
      if (this.optionsOverride !== null)
        return this.optionsOverride()
      else if (typeof this.safeDefinition.Options === 'function')
        return this.safeDefinition.Options()
      return this.safeDefinition.Options
    },
    displayValue: function () {
      return Lib.getDisplayValue(this.safeDefinition, this.groupIndex, this.lockedBorrower || this.$store.getters.getCurrentBorrowerId)
    },
    indexedId: function () {
      var index = this.groupIndex
      if (this.lockedBorrower)
        index = index + "_" + this.lockedBorrower

      return Lib.indexedId(this.safeDefinition, index)
    }
  },
  methods: {
    onChange: function($event) {
      Lib.updateDisplayValue(this.safeDefinition, this.groupIndex, $event.target.value, this.$emit, this.lockedBorrower || this.$store.getters.getCurrentBorrowerId)
      this.touched = true
    },
    localized: function (str) {
      return Terms.replace(str)
    }
  }
}
</script>