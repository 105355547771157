import ApiClient from '@/services/apiclient.js'

const vm = {
  state: {
    data: null,
    theme: null,
    config: null
  },
  getters: {
    getCompany: (state) => {
      return state.data
    },
    getCompanyTheme: (state) => {
      return state.theme
    },
    getCompanyConfig: (state) => {
      return state.config
    },
    getCompanyName: (state) => {
      if ((state.data || {}).ManagingCompany)
        return state.data.ManagingCompany.Name
      return state.data.Name
    }
  },
  mutations: {
    setCompany: (state, data) => {
      state.data = (data || {}).Company
      state.theme = (data || {}).Theme
      state.config = (data || {}).Config
    },
    setCompanyData: (state, data) => {
      state.data = data
    },
    setCompanyTheme: (state, theme) => {
      state.theme = theme
    },
    setCompanyConfig: (state, config) => {
      state.config = config
    }
  },
  actions: {
    initCompany: ({ commit }, companyId) => {
      if (!companyId)
        return new Promise(function (resolve, reject) {
          resolve()
        })

      return Promise.all([
        ApiClient.loadCompany(companyId),
        ApiClient.getConfig(companyId, null),
      ])
      .then(r => {
        var company = r[0]
        var config = r[1]

        if (!company)
          return null
		      
        // Set Colors
        if (company.Theme.App.Colors.Primary.Hex) {
          let customStylesheet = document.createElement('style')
          customStylesheet.type = 'text/css'
          const css = ':root { --color-primary: ' + company.Theme.App.Colors.Primary.Hex + '; }'
          customStylesheet.appendChild(document.createTextNode(css))

          document.head.appendChild(customStylesheet)
        }

        // Set Favicon
        if (company.Theme.App.Favicon) {
            var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
            link.type = 'image/x-icon'
            link.rel = 'shortcut icon'
            link.href = company.Theme.App.Favicon
            document.getElementsByTagName('head')[0].appendChild(link)
	      }

        commit('setCompany', { Company: company, Theme: company.Theme, Config: config })

        return company
      })
    }
  }
}

export default vm
