<template>
  <div
    class="input-wrapper"
    :class="{ invalid: !isValid }"
  >
    <label
      v-if="safeDefinition.Text && !safeDefinition._HideTitle"
      :for="indexedId"
      :title="localized(safeDefinition.Text)"
    ><Localize :text="safeDefinition.Text" /></label>
    <input
      :id="indexedId"
      :required="safeDefinition.Required"
      type="text"
      :placeholder="localized(safeDefinition.Placeholder)"
      class="input-date"
      :value="displayValue"
      maxlength="10"
      pattern="[0-9]{2}\/[0-9]{2}\/[0-9]{4}"
      @input="onChange"
    >
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

import Lib from '@/services/fields.js'
import Globals from '@/services/globals.js'
import Utils from '@/services/utils.js'
import Terms from '@/services/terms.js'
const Localize = defineAsyncComponent(function () { return import('@/components/TextLocalize.vue') })

import { DateTime } from 'luxon'

export default {
  name: 'FieldDate',
  components: {
    Localize
  },
  props: {
    definition: {
      required: true,
      validator: function (value) {
        if (process.env.NODE_ENV == 'development' && !value)
          return true

        if ((value || {}).Type !== Globals.FIELD_TYPES.FIELD_TYPE_DATE)
          return false

        if (typeof (value || {}).Id !== 'string')
          return false

        if (typeof(value || {}).Text !== 'string')
          return false

        return true
      }
    },
    groupIndex: {
      type: Number,
      default: 0
    }
  },
  emits: [
    'update:value'
  ],
  data: () => {
    return {
      touched: false
    }
  },
  computed: {
    safeDefinition: function () {
      return Lib.safeDefinition(this)
    },
    isValid: function () {
      return Lib.isValid(this)
    },
    displayValue: function () {
      var value = Lib.getDisplayValue(this.safeDefinition, this.groupIndex)
      var dt

      if ((value || "").length > 0 && (dt = DateTime.fromFormat(value, "yyyy-MM-dd'T'hh:mm:ss")).isValid)
        return dt.setLocale('en-US').toLocaleString(DateTime.DATE_SHORT)
      return value
    },
    indexedId: function () {
      return Lib.indexedId(this.safeDefinition, this.groupIndex)
    }
  },
  methods: {
    onChange: function ($event) {
      Lib.updateDisplayValue(this.safeDefinition, this.groupIndex, Utils.formatDate($event.target.value), this.$emit)
      this.touched = true
    },
    localized: function (str) {
      return Terms.replace(str)
    }
  }
}
</script>