<template>
  <Slide
    :active-slide="activeSlide"
    class="slide-welcome"
    :index="slideIndex"
    :disable-footer="true"
    :styles="slideStyles"
  >
    <div class="container">
      <div class="slide-left">
        <h2 class="bold">
          <Localize :text="`{{s1003_start_help}}`" />
        </h2>
        <!-- eslint-disable-next-line -->
        <p v-html="welcomeMessage" />
        <button
          type="button"
          class="btn-primary"
          @click="onCreate"
        >
          <Localize :text="`{{s1003_common_start}}`" />
        </button>
        <div class="welcome-footer-link">
          <a @click="onLogin"><Localize :text="`{{s1003_start_resume}}`" /></a>
        </div>
      </div>
      <div class="slide-right">
        <OfficerCard :intro-background="true" />
      </div>
    </div>
  </Slide>
</template>

<script>
import { defineAsyncComponent } from 'vue'

import Slide from '@/components/FormCarouselSlide.vue'
import OfficerCard from '@/components/OfficerCard.vue'
const Localize = defineAsyncComponent(function () { return import('@/components/TextLocalize.vue') })

export default {
  name: 'SlideWelcome',
  components: {
    Slide,
    OfficerCard,
    Localize
  },
  props: {
    activeSlide: {
      type: Number,
      default: 0
    },
    slideStyles: {
      type: Object,
      default: function () {
         return {}
      }
    },
    slideIndex: {
      type: Number,
      default: 0
    }
  },
  emits: [
    'update:create',
    'update:login'
  ],
  data: () => {
    return {

    }
  },
  computed: {
    welcomeMessage: function() {
      var localized = []

      if (this.$store.getters.getOfficerConfig)
        localized = this.$store.getters.getOfficerConfig.LoanApplication.Smart1003UserIntroTextLocalized
      else if (this.$store.getters.getCompanyConfig)
        localized = this.$store.getters.getCompanyConfig.LoanApplication.Smart1003CompanyIntroTextLocalized

      for (var i = 0; i < localized.length; i++)
        if (localized[i].Lang === this.$store.getters.getLocalization)
          return localized[i].Value

      return null 
    }
  },
  methods: {
    onCreate: function() {
      this.$emit('update:create')
    },
    onLogin: function () {
      this.$emit('update:login')
    }
  }
}
</script>

<style scoped lang="scss">
.slide-left, .slide-right {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}

.slide-left {
  padding-top: 1rem;
  padding-right: 1rem;
  button {
    margin-top: 10px;
  }
}

.slide-right {
  padding-left: 1rem;
}

.welcome-footer-link {
  cursor: pointer;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 600;
}
</style>
