<template>
  <div
    class="input-wrapper"
    :class="{ invalid: !isValid }"
  >
    <div class="input-boolean">
      <template v-if="safeDefinition.Display === 1">
        <button
          :id="indexedId"
          type="button"
          @click="onYes"
        >
          <i class="fa-duotone fa-envelope fa-3x" />
          <Localize :text="safeDefinition.Text" />
        </button>
      </template>

      <template v-else-if="safeDefinition.Display === 2">
        <div class="left">
          <button
            :id="indexedId"
            type="button"
            class="input-yes"
            :class="{ active: displayValue === true }"
            @click="onYes"
          >
            <i class="fa-duotone fa-thumbs-up fa-3x" />
          </button>
          <h4 class="label">
            <Localize :text="`{{s1003_common_yes}}`" />
          </h4>
        </div>
        <div class="right">
          <button
            type="button"
            class="input-no"
            :class="{ active: displayValue === false }"
            @click="onNo"
          >
            <i class="fa-duotone fa-thumbs-down fa-3x" />
          </button>
          <h4 class="label">
            <Localize :text="`{{s1003_common_no}}`" />
          </h4>
        </div>
      </template>

      <template v-else-if="safeDefinition.Display === 3">
        <label
          v-if="safeDefinition.Text && !safeDefinition._HideTitle"
          class="wrap"
          :for="indexedId"
          :title="localized(safeDefinition.Text)"
        >
          <div><Localize :text="safeDefinition.Text" /></div>
          <template v-if="safeDefinition.Description">
            <div class="boolean-description"><Localize :text="safeDefinition.Description" /></div>
          </template>
          <template v-if="showCreditAuthLink">
            <div class="m-t-10">
              <a class="clickable"><Localize :text="`{{s1003_review_credit_auth_link}}`" /></a>
            </div>
          </template>
        </label>
        <SliderCheckbox
          :id="indexedId"
          :value="displayValue"
          @update:value="onToggle"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

const Localize = defineAsyncComponent(function () { return import('@/components/TextLocalize.vue') })
import SliderCheckbox from '@/components/SliderCheckbox.vue'
import Lib from '@/services/fields.js'
import Globals from '@/services/globals.js'
import Terms from '@/services/terms.js'

export default {
  name: 'FieldBoolean',
  components: {
    Localize,
    SliderCheckbox
  },
  props: {
    definition: {
      required: true,
      validator: function (value) {
        if (process.env.NODE_ENV == 'development' && !value)
          return true

        if ((value || {}).Type !== Globals.FIELD_TYPES.FIELD_TYPE_BOOLEAN)
          return false

        if (typeof (value || {}).Id !== 'string')
          return false

        if (typeof (value || {}).Display !== 'number')
          return false

        var val = (value || {}).Value || {}
        for (var prop in val)
	        if (val[prop] !== null && typeof val[prop] !== 'boolean')
            return false

        return true
      }
    },
    groupIndex: {
      type: Number,
      default: 0
    },
    lockedBorrower: {
      type: Number,
      default: 0
    }
  },
  emits: [
    'update:value'
  ],
  data: () => {
    return {
      touched: false
    }
  },
  computed: {
    safeDefinition: function () {
      return Lib.safeDefinition(this)
    },
    isValid: function () {
      return Lib.isValid(this)
    },
    displayValue: function () {
      return Lib.getDisplayValue(this.safeDefinition, this.groupIndex, this.lockedBorrower || this.$store.getters.getCurrentBorrowerId)
    },
    indexedId: function () {
      var index = this.groupIndex
      if (this.lockedBorrower)
        index = index + "_" + this.lockedBorrower

      return Lib.indexedId(this.safeDefinition, index)
    },
    showCreditAuthLink: function () {
      return this.safeDefinition.Id === 'smart1003_borrower_authorization_credit'
    }
  },
  methods: {
    onToggle: function(value) {
      var val = false

      if (typeof value === 'boolean')
        val = value
      else
        val = !this.displayValue

      if (val)
        return this.onYes()
      this.onNo()
    },
    onYes: function() {
      Lib.updateDisplayValue(this.safeDefinition, this.groupIndex, true, this.$emit, this.lockedBorrower || this.$store.getters.getCurrentBorrowerId)
      this.touched = true
    },
    onNo: function() {
      Lib.updateDisplayValue(this.safeDefinition, this.groupIndex, false, this.$emit, this.lockedBorrower || this.$store.getters.getCurrentBorrowerId)
      this.touched = true
    },
    localized: function (str) {
      return Terms.replace(str)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/_variables.scss';
//@import '@/assets/scss/fontawesome/_variables.scss';
//@import '@/assets/scss/fontawesome/_mixins.scss';

.input-wrapper {
  .input-boolean {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 1rem 0;
    > .left,
    > .right {
      display: inline-block;
      width: 49%;
    }
    > .left {
      margin-right: 2%;
    }
    h4.label {
      display: block;
      width: 100%;
      margin: 0.5rem 0 0 0;
      text-align: center;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem 0;
      width: 100%;
      background-color: $white;
      border-radius: 6px;
      box-shadow: 0 0 2px 2px rgba(155, 155, 155, 0.2);
      color: $dark;
      i {
        margin: 0 1rem;

      --fa-primary-color: #4b6daa;
      --fa-secondary-color: #fbce9d;
      --fa-primary-opacity: 1;
      --fa-secondary-opacity: 1;
      }
    }
    label {
      &.wrap {
        width: calc(100% - 100px);
        white-space: normal;
      }
    }

    .wrap {
      font-size: 14px;
      text-transform: unset;
    }

    .active {
      background-color: #e6e6e6;
      border-color: #c2cbd2;
    }
  }
}
</style>
