<template>
  <div
    class="page"
    data-page="error"
  >
    <div class="container">
      <div class="row">
        <div class="col-2 sidebar-left" />
        <div
          id="error-container"
          ref="mainCard"
          class="col-8 card main-card"
        >
          <div id="error-card">
            Error - Your application appears to be misconfigured.  Please obtain a new link before trying again.
          </div>
        </div>
        <div class="col-2 sidebar-right" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageError',
  components: {
    
  },
  data: () => {
    return {

    }
  },

  mounted: function() {

  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
#error-card {
  padding: 30px 20px;
  color: red;
  font-weight: 600;
}

#error-container {
  margin-top: 80px;
}
</style>
