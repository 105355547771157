import ApiClient from '@/services/apiclient.js'
import Globals from '@/services/globals.js'

const vm = {
  state: {
    data: null,
    theme: null,
    config: null
  },
  getters: {
    getOfficer: (state) => {
      return state.data
    },
    getOfficerTheme: (state) => {
      return state.theme
    },
    getOfficerConfig: (state) => {
      return state.config
    },
  },
  mutations: {
    setOfficer: (state, data) => {
      state.data = (data || {}).Officer
      state.theme = (data || {}).Theme
      state.config = (data || {}).Config
    },
    setOfficerData: (state, data) => {
      state.data = data
    },
    setOfficerTheme: (state, theme) => {
      state.theme = theme
    },
    setOfficerConfig: (state, config) => {
      state.config = config
    }
  },
  actions: {
    initOfficer: ({ commit }, data) => {
      if (!(data || {}).CompanyID || !(data || {}).UserID)
        return new Promise(function (resolve, reject) {
          commit('setOfficer', { Officer: null, Theme: null, Config: null })
          resolve()
	      })

      return Promise.all([
        ApiClient.loadOfficer(data.CompanyID, data.UserID),
        ApiClient.getConfig(data.CompanyID, data.UserID)
      ])
      .then(r => {
        var officer = r[0] || {}
        var config = r[1] || {}

        if (((officer.Profile || {}).CompanyRole || 0) < Globals.AUTH_ROLE.LOANOFFICER) {
          localStorage.setItem("PAM_OID", 0)
          return null
        }

        commit('setOfficer', { Officer: officer.Profile, Theme: officer.Theme, Config: config })

        return officer.Profile
      })
    }
  }
}

export default vm
