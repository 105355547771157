import Globals from '@/services/globals.js'
import Terms from '@/services/terms.js'
import FieldLib from '@/services/fields.js'

export const START_FIELD_SEARCH_ZIP = {
  Text: "{{s1003_common_zip}}",
  Placeholder: "{{s1003_common_zip}}",
  _HideTitle: true,
  Id: "start_search_zip",
  Value: '',
  Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
  ValidationType: Globals.FIELD_TEXT_VALIDATION.FIELD_TEXT_VALIDATION_TYPE_ZIP
}


export const START_FIELD_PRIMARY_BORROWER_FIRST_NAME = {
  Id: "smart1003_borrower_info_first_name",
  _ParentId: "smart1003_borrower_info",
  _ParentValueKey: "FirstName",
  Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
  Value: null,
  Required: true,
  Text: "{{s1003_common_first_name}}",
  Placeholder: "{{s1003_common_first_name}}",
  _HideTitle: true
}
export const START_FIELD_PRIMARY_BORROWER_LAST_NAME = {
  Id: "smart1003_borrower_info_last_name",
  _ParentId: "smart1003_borrower_info",
  _ParentValueKey: "LastName",
  Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
  Value: null,
  Required: true,
  Text: "{{s1003_common_last_name}}",
  Placeholder: "{{s1003_common_last_name}}",
  _HideTitle: true
}
export const START_FIELD_PRIMARY_BORROWER_EMAIL = {
  Text: "{{s1003_common_email}}",
  Placeholder: "{{s1003_common_email}}",
  _HideTitle: true,
  Id: "smart1003_borrower_email",
  Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
  Value: null,
  Required: true,
  ValidationType: Globals.FIELD_TEXT_VALIDATION.FIELD_TEXT_VALIDATION_TYPE_EMAIL
}
export const START_FIELD_PRIMARY_BORROWER_PHONE = {
  Text: "{{s1003_common_phone}}",
  Placeholder: "{{s1003_common_phone}}",
  _HideTitle: true,
  Id: "smart1003_borrower_phone",
  Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
  Value: null,
  Required: true,
  ValidationType: Globals.FIELD_TEXT_VALIDATION.FIELD_TEXT_VALIDATION_TYPE_PHONE
}
export const START_FIELD_PRIMARY_BORROWER_LOAN_TYPE = {
  Text: "{{s1003_start_primary_borrower_loan_type}}",
  _HideTitle: true,
  Id: "smart1003_loan_details_loan_type",
  Type: Globals.FIELD_TYPES.FIELD_TYPE_MULTIPLE_CHOICE,
  Value: null,
  Required: true,
  Options: [{ Text: '{{s1003_common_purchase}}', Value: 'Purchase' }, { Text: '{{s1003_common_refinance}}', Value: 'Refinance' }, { Text: '{{s1003_common_construction}}', Value: 'Construction' }, { Text: '{{s1003_common_heloc}}', Value: 'HELOC' }]
}
export const START_FIELD_PRIMARY_BORROWER_COBORROWERS = {
  Text: "{{s1003_start_coborrower_count}}",
  _HideTitle: true,
  Id: "loan_borrower_count",
  Type: Globals.FIELD_TYPES.FIELD_TYPE_MULTIPLE_CHOICE,
  Value: null,
  Required: true,
  Options: [{ Text: '{{s1003_start_primary_borrower_sole}}', Value: 'single' }, { Text: '{{s1003_start_primary_borrower_jointly}}', Value: 'multiple' }]
}

export const START_FIELD_CREATE_PASSWORD_PASSWORD = {
  Text: "{{s1003_start_password}}",
  Placeholder: "{{s1003_start_password}}",
  Id: "start_create_password_password",
  Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
  Value: null,
  Required: true,
  ValidationType: Globals.FIELD_TEXT_VALIDATION.FIELD_TEXT_VALIDATION_TYPE_PASSWORD
}

export const START_FIELD_CREATE_PASSWORD_CONFIRM = {
  Text: "{{s1003_start_confirm_password}}",
  Placeholder: "{{s1003_start_confirm_password}}",
  Id: "start_create_password_confirm",
  Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
  Value: null,
  Required: true,
  ValidationType: Globals.FIELD_TEXT_VALIDATION.FIELD_TEXT_VALIDATION_TYPE_PASSWORD
}

export const START_FIELD_LOGIN_EMAIL = {
  Text: "{{s1003_common_email}}",
  Placeholder: "{{s1003_common_email}}",
  Id: "start_login_email",
  Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
  Value: null,
  Required: true,
  ValidationType: Globals.FIELD_TEXT_VALIDATION.FIELD_TEXT_VALIDATION_TYPE_EMAIL
}

export const START_FIELD_LOGIN_PASSWORD = {
  Text: "{{s1003_start_password}}",
  Placeholder: "{{s1003_start_password}}",
  Id: "start_login_password",
  Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
  Value: null,
  Required: true,
  ValidationType: Globals.FIELD_TEXT_VALIDATION.FIELD_TEXT_VALIDATION_TYPE_PASSWORD
}

export const START_FIELD_LOGIN_TWOFACTOR = {
  Text: "{{s1003_start_security_code}}",
  Placeholder: "{{s1003_start_security_code}}",
  Id: "start_login_twofactor",
  Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
  Value: null,
  Required: false
}

export const START_FIELD_FORGOT_PASSWORD_EMAIL = {
  Text: "{{s1003_common_email}}",
  Placeholder: "{{s1003_common_email}}",
  Id: "start_login_email",
  Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
  Value: null,
  Required: true,
  ValidationType: Globals.FIELD_TEXT_VALIDATION.FIELD_TEXT_VALIDATION_TYPE_EMAIL
}

export const START_FIELD_FORGOT_PASSWORD_TYPE = {
  Text: "{{s1003_start_action}}",
  Id: "start_forgot_password_type",
  Type: Globals.FIELD_TYPES.FIELD_TYPE_MULTIPLE_CHOICE,
  Value: null,
  Required: true,
  Options: [{ Text: '{{s1003_start_reset_reset}}', Value: 'reset' }, { Text: '{{s1003_start_reset_link}}', Value: 'link' }]
}

export const START_FIELD_FORGOT_PASSWORD_METHOD = {
  Text: "{{s1003_start_reset_method}}",
  Id: "start_forgot_password_method",
  Type: Globals.FIELD_TYPES.FIELD_TYPE_MULTIPLE_CHOICE,
  Value: null,
  Required: true,
  Options: [{ Text: '{{s1003_start_reset_method_sms}}', Value: 'text' }, { Text: '{{s1003_common_email}}', Value: 'email' }]
}

export const START_FIELD_RESET_PASSWORD_PASSWORD = {
  Text: "{{s1003_start_password}}",
  Placeholder: "{{s1003_start_password}}",
  Id: "start_reset_password_password",
  Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
  Value: null,
  Required: true,
  ValidationType: Globals.FIELD_TEXT_VALIDATION.FIELD_TEXT_VALIDATION_TYPE_PASSWORD
}

export const START_FIELD_RESET_PASSWORD_CONFIRM = {
  Text: "{{s1003_start_confirm_password}}",
  Placeholder: "{{s1003_start_confirm_password}}",
  Id: "start_reset_password_confirm",
  Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
  Value: null,
  Required: true,
  ValidationType: Globals.FIELD_TEXT_VALIDATION.FIELD_TEXT_VALIDATION_TYPE_PASSWORD
}

export default {
  start: {
    createSearchName: function () {
      return {
        Text: "{{s1003_start_search_name}}",
        Placeholder: "{{s1003_start_search_name}}",
        _HideTitle: true,
        Id: "start_search_name",
        Value: '',
        Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT
      }
    },
    createHasOfficer: function () {
      return {
        Text: "{{s1003_system_officer_search4}}",
        _HideTitle: true,
        Id: "start_has_officer",
        Display: 2,
        Value: null,
        Type: Globals.FIELD_TYPES.FIELD_TYPE_BOOLEAN,
        Required: true
      }
    },
    createCoborrowerFirstName: function () {
      return {
        Id: "smart1003_borrower_info_first_name",
        _ParentId: "smart1003_borrower_info",
        _ParentValueKey: "FirstName",
        Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
        Value: null,
        Required: true,
        Text: "{{s1003_common_first_name}}",
        Placeholder: "{{s1003_common_first_name}}",
        _HideTitle: true
      }
    },
    createCoborrowerMiddleName: function () {
      return {
        Id: "smart1003_borrower_info_middle_name",
        _ParentId: "smart1003_borrower_info",
        _ParentValueKey: "MiddleName",
        Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
        Value: null,
        Text: "{{s1003_personal_middle_name}}",
        Placeholder: "{{s1003_personal_middle_name}}",
        _HideTitle: true
      }
    },
    createCoborrowerLastName: function () {
      return {
        Id: "smart1003_borrower_info_last_name",
        _ParentId: "smart1003_borrower_info",
        _ParentValueKey: "LastName",
        Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
        Value: null,
        Required: true,
        Text: "{{s1003_common_last_name}}",
        Placeholder: "{{s1003_common_last_name}}",
        _HideTitle: true
      }
    },
    createCoborrowerSuffix: function () {
      return {
        Id: "smart1003_borrower_info_suffix",
        _ParentId: "smart1003_borrower_info",
        _ParentValueKey: "Suffix",
        Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
        Value: null,
        Text: "{{s1003_personal_suffix}}",
        Placeholder: "{{s1003_personal_suffix}}",
        _HideTitle: true
      }
    },
    createCoborrowerEmail: function () {
      return {
        Id: "smart1003_borrower_email",
        Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
        Value: null,
        Required: true,
        Text: "{{s1003_common_email}}",
        Placeholder: "{{s1003_common_email}}",
        _HideTitle: true,
        ValidationType: Globals.FIELD_TEXT_VALIDATION.FIELD_TEXT_VALIDATION_TYPE_EMAIL
      }
    },
    createCoborrowerPhone: function () {
      return {
        Id: "smart1003_borrower_phone",
        Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
        Value: null,
        Required: true,
        Text: "{{s1003_common_phone}}",
        Placeholder: "{{s1003_common_phone}}",
        _HideTitle: true,
        ValidationType: Globals.FIELD_TEXT_VALIDATION.FIELD_TEXT_VALIDATION_TYPE_PHONE
      }
    },
    createCoborrowerMarried: function () {
      return {
        Id: "smart1003_borrower_married_to",
        Type: Globals.FIELD_TYPES.FIELD_TYPE_MULTIPLE_CHOICE,
        Value: null,
        Required: true,
        Text: "{{s1003_system_coborrower_married_target}}",
        _HideTitle: true,
        Options: []
      }
    },
    createCoborrowerBehalf: function () {
      return {
        Id: "start_coborrower_behalf",
        Type: Globals.FIELD_TYPES.FIELD_TYPE_MULTIPLE_CHOICE,
        Value: null,
        //Required: true,
        Text: "{{s1003_start_primary_borrower_filling}}",
        _HideTitle: true,
        Options: [{ Text: '', Value: '' }, { Text: '{{s1003_start_primary_borrower_filling_yes}}', Value: 'yes' }, { Text: '{{s1003_start_primary_borrower_filling_no}}', Value: 'no' }]
      }
    }
  },
  address: {
    createStreet1: function (parentDefinition) {
      return {
        Id: parentDefinition.Id + "_street1",
        _ParentId: parentDefinition.Id,
        _ParentValueKey: "Street1",
        Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
        Value: null,
        Required: parentDefinition.Required,
        Text: "{{s1003_common_street_address}}",
        Grid: {
          X: 0,
          Y: 0,
          Span: 4
        }
      }
    },
    createStreet2: function (parentDefinition) {
      return {
        Id: parentDefinition.Id + "_street2",
        _ParentId: parentDefinition.Id,
        _ParentValueKey: "Street2",
        Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
        Value: null,
        Required: parentDefinition.Required,
        Text: "{{s1003_common_address_number}}",
        Grid: {
          X: 0,
          Y: 1,
          Span: 2
        }
      }
    },
    createCity: function (parentDefinition) {
      return {
        Id: parentDefinition.Id + "_city",
        _ParentId: parentDefinition.Id,
        _ParentValueKey: "City",
        Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
        Value: null,
        Required: parentDefinition.Required,
        Text: "{{s1003_common_city}}",
        Grid: {
          X: 2,
          Y: 1,
          Span: 2
        }
      }
    },
    createState: function (parentDefinition) {
      return {
        Id: parentDefinition.Id + "_state",
        _ParentId: parentDefinition.Id,
        _ParentValueKey: "State",
        Type: Globals.FIELD_TYPES.FIELD_TYPE_MULTIPLE_CHOICE,
        Value: null,
        Required: parentDefinition.Required,
        Text: "{{s1003_common_state}}",
        Options: Globals.LIST_STATES,
        Grid: {
          X: 0,
          Y: 2,
          Span: 2
        }
      }
    },
    createZip: function (parentDefinition) {
      return {
        Id: parentDefinition.Id + "_zip",
        _ParentId: parentDefinition.Id,
        _ParentValueKey: "Zip",
        Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
        Value: null,
        Required: parentDefinition.Required,
        Text: "{{s1003_common_zip}}",
        ValidationType: Globals.FIELD_TEXT_VALIDATION.FIELD_TEXT_VALIDATION_TYPE_ZIP,
        Grid: {
          X: 2,
          Y: 2,
          Span: 2
        }
      }
    }
  },
  basicInfo: {
    createFirstName: function (parentDefinition) {
      return {
        Id: parentDefinition.Id + "_first_name",
        _ParentId: parentDefinition.Id,
        _ParentValueKey: "FirstName",
        Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
        Value: null,
        Required: parentDefinition.Required,
        Text: "{{s1003_common_first_name}}",
        Placeholder: "{{s1003_common_first_name}}",
        Grid: {
          X: 0,
          Y: 0,
          Span: 1
        }
      }
    },
    createMiddleName: function (parentDefinition) {
      return {
        Id: parentDefinition.Id + "_middle_name",
        _ParentId: parentDefinition.Id,
        _ParentValueKey: "MiddleName",
        Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
        Value: null,
        Text: "{{s1003_personal_middle_name}}",
        Placeholder: "{{s1003_personal_middle_name}}",
        Grid: {
          X: 1,
          Y: 0,
          Span: 1
        }
      }
    },
    createLastName: function (parentDefinition) {
      return {
        Id: parentDefinition.Id + "_last_name",
        _ParentId: parentDefinition.Id,
        _ParentValueKey: "LastName",
        Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
        Value: null,
        Required: parentDefinition.Required,
        Text: "{{s1003_common_last_name}}",
        Placeholder: "{{s1003_common_last_name}}",
        Grid: {
          X: 2,
          Y: 0,
          Span: 1
        }
      }
    },
    createSuffix: function (parentDefinition) {
      return {
        Id: parentDefinition.Id + "_suffix",
        _ParentId: parentDefinition.Id,
        _ParentValueKey: "Suffix",
        Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
        Value: null,
        Text: "{{s1003_personal_suffix}}",
        Placeholder: "{{s1003_personal_suffix}}",
        Grid: {
          X: 3,
          Y: 0,
          Span: 1
        }
      }
    },
    createEmail: function (parentDefinition) {
      return {
        Id: parentDefinition.Id + "_email",
        _ParentId: parentDefinition.Id,
        _ParentValueKey: "Email",
        Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
        Value: null,
        Required: parentDefinition.Required,
        Text: "{{s1003_common_email}}",
        Placeholder: "{{s1003_common_email}}",
        ValidationType: Globals.FIELD_TEXT_VALIDATION.FIELD_TEXT_VALIDATION_TYPE_EMAIL,
        Grid: {
          X: 0,
          Y: 1,
          Span: 2
        }
      }
    },
    createPhone: function (parentDefinition) {
      return {
        Id: parentDefinition.Id + "_phone",
        _ParentId: parentDefinition.Id,
        _ParentValueKey: "Phone",
        Type: Globals.FIELD_TYPES.FIELD_TYPE_TEXT,
        Value: null,
        Required: parentDefinition.Required,
        Text: "{{s1003_common_phone}}",
        Placeholder: "{{s1003_common_phone}}",
        ValidationType: Globals.FIELD_TEXT_VALIDATION.FIELD_TEXT_VALIDATION_TYPE_PHONE,
        Grid: {
          X: 2,
          Y: 1,
          Span: 2
        }
      }
    }
  }
}