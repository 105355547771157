<template>
  <!-- eslint-disable-next-line -->
  <span v-html="displayText" />
</template>

<script>
import Terms from '@/services/terms.js'

export default {
  name: 'TextLocalize',
  components: {

  },
  props: {
    text: {
      type: String,
      default: ""
    }
  },
  computed: {
    displayText: function () {
      return Terms.replace(this.text)
    }
  }
}
</script>
