<template>
  <div class="field-basic-info">
    <Field
      :definition="firstName"
      :group-index="groupIndex"
      @update:value="onUpdateFirstName"
    />
    <Field
      :definition="middleName"
      :group-index="groupIndex"
      @update:value="onUpdateMiddleName"
    />
    <Field
      :definition="lastName"
      :group-index="groupIndex"
      @update:value="onUpdateLastName"
    />
    <Field
      :definition="suffix"
      :group-index="groupIndex"
      @update:value="onUpdateSuffix"
    />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

import Globals from '@/services/globals.js'
import Definitions from '@/services/definitions.js'
import FieldLib from '@/services/fields.js'
const Field = defineAsyncComponent(function () { return import('@/fields/FormField.vue') })

export default {
  name: 'FieldBasicInfo',
  components: {
    Field
  },
  props: {
    definition: {
      required: true,
      validator: function (value) {
        if (process.env.NODE_ENV == 'development' && !value)
          return true
          
        if ((value || {}).Type !== Globals.FIELD_TYPES.FIELD_TYPE_USER_INFO)
          return false

        if (typeof (value || {}).Id !== 'string')
          return false

        return true
      }
    },
    groupIndex: {
      type: Number,
      default: 0
    }
  },
  emits: [
    'update:value'
  ],
  data: () => {
    return {
      firstName: null,
      middleName: null,
      lastName: null,
      suffix: null,
      //email: null,
      //phone: null
    }
  },
  computed: {
    safeDefinition: function () {
      return FieldLib.safeDefinition(this)
    },
  },
  mounted: function() {
    this.firstName = Definitions.basicInfo.createFirstName(this.safeDefinition)
    this.middleName = Definitions.basicInfo.createMiddleName(this.safeDefinition)
    this.lastName = Definitions.basicInfo.createLastName(this.safeDefinition)
    this.suffix = Definitions.basicInfo.createSuffix(this.safeDefinition)
    //this.email = Definitions.basicInfo.createEmail(this.safeDefinition)
    //this.phone = Definitions.basicInfo.createPhone(this.safeDefinition)
  },
  methods: {
    onUpdateFirstName: function ($event) {
      this.setValue({...this.getValue(), ...{ FirstName: $event.Value }})
    },
    onUpdateMiddleName: function ($event) {
      this.setValue({...this.getValue(), ...{ MiddleName: $event.Value }})
    },
    onUpdateLastName: function ($event) {
      this.setValue({...this.getValue(), ...{ LastName: $event.Value }})
    },
    onUpdateSuffix: function ($event) {
      this.setValue({...this.getValue(), ...{ Suffix: $event.Value }})
    },
    onUpdateEmail: function ($event) {
      this.setValue({...this.getValue(), ...{ Email: $event.Value }})
    },
    onUpdatePhone: function ($event) {
      this.setValue({...this.getValue(), ...{ Phone: $event.Value }})
    },
    getValue: function () {
      return this.$store.getters.getFormValueAtIndex(this.safeDefinition.Id, this.groupIndex) || {
        FirstName: null,
        MiddleName: null,
        LastName: null,
        Suffix: null,
        Email: null,
        Phone: null
      }
    },
    setValue: function (value) {
      FieldLib.updateDisplayValue(this.safeDefinition, this.groupIndex, value, this.$emit)
      this.$store.dispatch('syncBorrower')
    }
  }
}
</script>