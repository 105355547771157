import Client from '@/services/apiclient.js'
import Globals from '@/services/globals.js'

const vm = {
  state: {
    profile: null,
    config: null,
    theme: null,
    sessionId: null,
    invitationCode: null
  },
  getters: {
    getSessionProfile: function (state) {
      return state.profile
    },
    getSessionFirstName: function (state) {
      return (state.profile || {}).FirstName
    },
    getSessionLastName: function (state) {
      return (state.profile || {}).LastName
    },
    getSessionEmail: function (state) {
      return (state.profile || {}).Email
    },
    getSessionPhone: function (state) {
      return (state.profile || {}).Phone
    },
    getSessionToken: function (state) {
      return state.sessionId
    },
    getInvitationCode: function (state) {
      return state.invitationCode
    }
  },
  mutations: {
    setSessionToken: function (state, token) {
      state.sessionId = token || null
      localStorage.setItem("PAM_S", token || null)
    },
    setSessionProfile: function (state, profile) {
      state.profile = profile
    },
    setSessionConfig: function (state, config) {
      state.config = config
    },
    setSessionTheme: function (state, theme) {
      state.theme = theme
    },
    setInvitationCode: function (state, code) {
      state.invitationCode = code
    }
  },
  actions: {
    loginWithStoredCredentials: function ({ commit, getters, dispatch }) {
      return new Promise((resolve, reject) => {
        commit('setSessionToken', localStorage.getItem("PAM_S"))

        if (!getters.getSessionToken) {
          resolve()
          return
        }

        dispatch('updateSession')
          .then(function (result) {
            resolve(result)
          })
      })
    },
    loginWithUserCredentials: function ({ commit, dispatch }, credentials) {
      return new Promise((resolve, reject) => {
        Client.login(credentials.Username, credentials.Password, credentials.TwoFactor)
          .then(function (loginResult) {
            if ((loginResult || {}).SessionID) {
              commit('setSessionToken', loginResult.SessionID)

              dispatch('updateSession')
                .then(function (updateResult) {
                  resolve(updateResult)
                })
            }
            else {
              commit('setSessionToken', null)
              commit('setSessionProfile', null)
              commit('setSessionConfig', null)
              commit('setSessionTheme', null)

              resolve(loginResult)
            }
          })
      })
    },
    loginWithAccessCode: function ({ commit, dispatch }, code) {
      return new Promise((resolve, reject) => {
        if (!code) {
          resolve()
          return
        }

        Client.loginWithCode(code)
          .then(function (loginResult) {
            if (loginResult.SessionID) {
              commit('setSessionToken', loginResult.SessionID)
              dispatch('updateSession').then(updateResult => resolve(updateResult))
            }
            else
              resolve()
          })
      })
    },
    updateSession: function ({ commit, getters }) {
      return Client.getSessionSummary()
        .then(function (result) {
          var profile = (result || {}).Profile || {}
          var hasEmail = !!profile.Email
          var hasCompany = profile.CompanyID == (getters.getCompany || {}).CompanyID
          var hasRole = (profile.CompanyRole || Globals.AUTH_ROLE.NONE) > Globals.AUTH_ROLE.NONE

          if (hasEmail && hasCompany && hasRole) {
            commit('setSessionProfile', result.Profile)
            commit('setSessionConfig', result.Config)
            commit('setSessionTheme', result.Theme)
          }
          else {
            commit('setSessionToken', null)
            commit('setSessionProfile', null)
            commit('setSessionConfig', null)
            commit('setSessionTheme', null)
          }

          return result
        })
    },
    logout: function ({ commit }) {
      return Client.logout()
        .then(function (result) {
          commit('setSessionToken', null)
          commit('setSessionProfile', null)
          commit('setSessionConfig', null)
          commit('setSessionTheme', null)

          return result
        })
    }
  }
}

export default vm
