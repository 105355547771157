import store from '../store/store'

var terms = {
  replace: function(str) {
    if (typeof str !== 'string')
      return str

    return terms.replaceDynamic(terms.replaceLocalization(str))
  },
  replaceLocalization: function (str) {
    var config = (store.getters.getOfficerConfig || {}).Localization ||
      (store.getters.getCompanyConfig || {}).Localization || {}
    var table = (config.LocalizationTable || {})[store.getters.getLocalization]

    return str.replace(/{{([a-zA-Z0-9\-_\.\']*)}}/g, function (match, key) {
      if (typeof table[key] !== 'undefined')
        return table[key]
      return key
    })
  },
  replaceDynamic: function (str) {
    var terms = (store.getters.getOfficerTheme || {}).Terms ||
      (store.getters.getCompanyTheme || {}).Terms || {}
    var lookup = (terms || {}).Lookup || {}

    // Handle references to form fields
    lookup.FirstName = (store.getters.getCurrentBorrower || {}).FirstName || ''
    lookup.EmployerName = (store.getters.getCurrentBorrower || {}).EmployerName || ''

    var company = store.getters.getCompany || {}
    lookup.CompanyName = (company.ManagingCompany || {}).Name || company.Name || ''

    return str.replace(/{{([a-zA-Z0-9\-_\.\']*)}}/g, function (match, key) {
      if (typeof lookup[key] !== 'undefined')
        return lookup[key]
      return key
    })
  }
}

export default terms