<template>
  <div class="field-group">
    <legend>
      <span><Localize :text="safeDefinition.Text" /></span>
      <div
        v-if="(removeprompt || '').length > 0"
        class="group-remove"
      >
        <a
          href="#"
          @click="onRemove"
        >
          <i class="fa-solid fa-minus-circle" /> <Localize :text="removeprompt" /></a>
      </div>
    </legend>
    <div
      v-if="!safeDefinition.AlwaysVisible"
      class="group-header"
      :class="{ 'expanded': expanded }"
      @click="onExpand"
    >
      <span><Localize :text="headerLabel" /></span>
      <i :class="headerIcon" />
    </div>
    <div 
      class="group-body"
      :class="{ 'expanded': expanded }"
    >
      <div 
        class="group-body-wrapper"
        :class="{ 'expanded': expanded }"
      >
        <template
          v-for="field in (safeDefinition.Fields || [])"
          :key="'field_' + field.Id"
        >
          <Field
            :definition="field"
            :group-index="groupIndex"
            @update:value="onUpdateValue"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

import FieldLib from '@/services/fields.js'
import Globals from '@/services/globals.js'
const Field = defineAsyncComponent(function () { return import('@/fields/FormField.vue') })
const Localize = defineAsyncComponent(function () { return import('@/components/TextLocalize.vue') })

export default {
  name: 'FieldGroup',
  components: {
    Localize,
    Field
  },
  props: {
    definition: {
      required: true,
      validator: function (value) {
        if (process.env.NODE_ENV == 'development' && !value)
          return true

        if ((value || {}).Type !== Globals.FIELD_TYPES.FIELD_TYPE_GROUP)
          return false

        if (typeof (value || {}).Id !== 'string')
          return false

        return true
      }
    },
    removeprompt: {
      type: String,
      default: ''
    },
    expanded: {
      type: Boolean,
      default: true
    },
    groupIndex: {
      type: Number,
      default: 0
    },
  },
  emits: [
    'update:remove',
    'update:expanded',
    'update:value'
  ],
  data: () => {
    return {
      
    }
  },
  computed: {
    safeDefinition: function () {
      return FieldLib.safeDefinition(this)
    },
    headerLabel: function () {
      if ((this.safeDefinition.Fields || []).length > 0) {
        var val = this.$store.getters.getFormValue(this.safeDefinition.Fields[0].Id)[this.groupIndex] || ''

        if (val && typeof val === 'object')
          for (var prop in val)
            return val[prop]
        return val
      }
      return ''
    },
    headerIcon: function () {
      if (this.expanded)
        return 'fa-solid fa-caret-left'
      return 'fa-solid fa-caret-down'
    }
  },
  methods: {
    onExpand: function () {
      this.$emit('update:expanded', !this.expanded)
    },
    onRemove: function () {
      this.$emit('update:remove')
    },
    onUpdateValue: function ($event) {
      this.$emit('update:value', $event)
    }
  }
}
</script>
