<template>
  <div
    ref="backdrop"
    class="drawer-container"
    :class="position"
    @click="backdropClick"
  >
    <div
      class="drawer"
      :class="position"
      :style="drawerPosition"
      @click="drawerClick"
    >
      <div class="close-drawer">
        <i
          class="fa-solid fa-times"
          @click="backdropClick"
        />
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormDrawer',
  components: {
    
  },
  props: {
    position: {
      type: String,
      default: 'right'
    },
    size: {
      type: Number,
      default: 100
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'update:position',
    'toggle:drawer'
  ],
  data: () => {
    return {
      watchTimer: null
    }
  },
  computed: {
    drawerPosition: function () {
      var style = {}

      if (this.position === 'right' || this.position === 'left')
        style.width = this.size + 'px'
      else
        style.height = this.size + 'px'

      if (this.open) {
        style[this.position] = '0px'
        style['opacity'] = 1 
        style['visibility'] = 'visible'
      }
      else
        style[this.position] = '-' + this.size + 'px'

      return style
    }
  },
  watch: {
    open: function (isOpen) {
      var vm = this

      if (isOpen) {
        if (vm.position === 'right' || vm.position === 'left')
          vm.$refs.backdrop.style.width = '100vw'
        else
          vm.$refs.backdrop.style.height = '100vh'

        vm.$refs.backdrop.style.background = 'rgba(78, 78, 78, 0.3)'
        vm.$refs.backdrop.style.backdropFilter = 'blur(2px)'
      }
      else if (!vm.watchTimer) {
        vm.$refs.backdrop.style.background = 'rgba(78, 78, 78, 0)'
        vm.$refs.backdrop.style.backdropFilter = 'none'

        vm.watchTimer = setTimeout(function () {
          // Check that we are still in the right state
          if (!vm.open) {
            if (vm.position === 'right' || vm.position === 'left')
              vm.$refs.backdrop.style.width = '0px'
            else
              vm.$refs.backdrop.style.height = '0px'

          }

          vm.watchTimer = null
        }, 400)
      }
    }
  },
  mounted: function () {
    if (this.position !== 'left' && this.position !== 'right' && this.position !== 'top' && this.position !== 'bottom')
      this.$emit('update:position', 'right')
  },
  methods: {
    backdropClick: function () {
      this.$emit('toggle:drawer')
    },
    drawerClick: function (evt) {
      evt.stopPropagation()
    }
  }
}
</script>

<style scoped lang="scss">
.drawer-container {
  position: absolute;
  transition: background 0.4s;
  transition: backdrop-filter 0.4s;
  overflow: hidden;
  //background: rgba(0, 0, 0, 0.2);

  &.left {
    top: 0px;
    bottom: 0px;
    left: 0px;
  }

  &.right {
    top: 0px;
    bottom: 0px;
    right: 0px;
  }

  &.top {
    left: 0px;
    right: 0px;
    top: 0px;
  }

  &.bottom {
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
}

.drawer {
  background-color: white;
  position: absolute;
  transition: 0.4s all;
  opacity: 0;
  visibility: hidden;
  //box-shadow: 0 0 6px 4px #d8dada;

  &.left {
    top: 0px;
    bottom: 0px;
  }

  &.right {
    top: 0px;
    bottom: 0px;
  }

  &.top {
    left: 0px;
    right: 0px;
  }

  &.bottom {
    left: 0px;
    right: 0px;
  }
}

.close-drawer {
  position: absolute; 
  right: 5px; 
  top: 5px; 
  padding: 5px; 
  z-index: 1;
  font-size: 20px;
  //color: red;
}
</style>
