<template>
  <div class="field-repeating-group">
    <div class="grid-form">
      <legend v-if="safeDefinition.Text">
        <span><Localize :text="safeDefinition.Text" /></span>
      </legend>
    </div>
    <template
      v-for="field in (safeDefinition.InitialFields || [])"
      :key="field.Id"
    >
      <Field
        :definition="field"
        :group-index="0"
        @update:value="onUpdateInitial($event)"
      />
    </template>
    <template
      v-for="groupIndex in subsequentCount"
      :key="groupIndex"
    >
      <template
        v-for="field in (safeDefinition.SubsequentFields || safeDefinition.InitialFields || [])"
        :key="field.Id"
      >
        <Field
          :definition="field"
          :group-index="groupIndex"
          :removeprompt="removePrompt"
          @update:value="onUpdateSubsequent($event)"
          @update:remove="onRemove(groupIndex)"
        />
      </template>
    </template>
    <div
      v-if="allowAdd"
      class="repeating-group-add-link"
    >
      <a @click="onAdd">
        <Localize :text="addPrompt" />
      </a>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

const Localize = defineAsyncComponent(function () { return import('@/components/TextLocalize.vue') })
const Field = defineAsyncComponent(function () { return import('@/fields/FormField.vue') })
import FieldLib from '@/services/fields.js'
import Globals from '@/services/globals.js'

export default {
  name: 'FieldRepeatingGroup',
  components: {
    Localize,
    Field
  },
  props: {
    definition: {
      required: true,
      validator: function (value) {
        if (process.env.NODE_ENV == 'development' && !value)
          return true

        var typeCheck = false

        if ((value || {}).Type === Globals.FIELD_TYPES.FIELD_TYPE_REPEATING_GROUP)
          typeCheck = true
        if ((value || {}).Type === Globals.FIELD_TYPES.FIELD_TYPE_RESIDENCE_HISTORY)
          typeCheck = true
        if ((value || {}).Type === Globals.FIELD_TYPES.FIELD_TYPE_EMPLOYMENT_HISTORY)
          typeCheck = true

        if (!typeCheck)
          return false

        if (typeof (value || {}).Id !== 'string')
          return false

        if (!Array.isArray((value || {}).InitialFields || []))
          return false

        if (!Array.isArray((value || {}).SubsequentFields || []))
          return false

        return true
      }
    },
    allowAdd: {
      type: Boolean,
      default: true
    },
    allowRemove: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    'update:value'
  ],
  data: () => {
    return {
      
    }
  },
  computed: {
    safeDefinition: function () {
      return FieldLib.safeDefinition(this)
    },
    addPrompt: function () {
      return "{{s1003_common_prompt_add_prefix}} " + this.safeDefinition.ListExpansionPrompt
    },
    removePrompt: function () {
      if (this.allowRemove)
        return "{{s1003_common_prompt_remove_prefix}} " + this.safeDefinition.ListExpansionPrompt
      return null
    },
    subsequentCount: function () {
      return FieldLib.getDisplayValue(this.safeDefinition, 0)
    }
  },
  methods: {
    onAdd: function() {
      var fields = FieldLib.unwrapGroupFields(this.safeDefinition.SubsequentFields)
      if ((fields || []).length === 0)
        fields = FieldLib.unwrapGroupFields(this.safeDefinition.InitialFields)

      // Add blank values for child fields
      for (var i = 0; i < fields.length; i++) {
        var values = this.$store.getters.getFormValue(fields[i].Id)
        values.push(null)
        this.$store.commit('setFormValue', { BorrowerID: this.$store.getters.getCurrentBorrowerId, FieldID: fields[i].Id, Value: values })
      }

      FieldLib.updateDisplayValue(this.safeDefinition, 0, this.subsequentCount + 1, this.$emit)
    },
    onRemove: function(index) {
      var fields = FieldLib.unwrapGroupFields(this.safeDefinition.SubsequentFields)
      if ((fields || []).length === 0)
        fields = FieldLib.unwrapGroupFields(this.safeDefinition.InitialFields)

      // Remove value for child fields
      for (var i = 0; i < fields.length; i++) {
        var values = this.$store.getters.getFormValue(fields[i].Id)
        values.splice(index, 1)
        this.$store.commit('setFormValue', { BorrowerID: this.$store.getters.getCurrentBorrowerId, FieldID: fields[i].Id, Value: values })
      }

      FieldLib.updateDisplayValue(this.safeDefinition, 0, this.subsequentCount - 1, this.$emit)
    },
    onUpdateInitial: function ($event) {
      this.$emit('update:value', $event)
    },
    onUpdateSubsequent: function ($event) {
      this.$emit('update:value', $event)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/_variables.scss';

.field-repeating-group {
  width: 100%;

  &.container {
    width: $card-container-width;
  }

  .repeating-group-add-link {
    cursor: pointer;
    text-align: right;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
</style>
