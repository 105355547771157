<template>
  <div class="page">
    <div class="container pt-4">
      <div class="page-form-row row">
        <div class="card main-card">
          <img
            class="brand-logo"
            :src="$store.getters.getLogo || '../assets/logo_tmp.png'"
          >
          
          <div class="submit-text">
            &#127881; <Localize :text="`{{s1003_outro_line1}}`" /> &#127881;
          </div>
          <div class="submit-text">
            <Localize :text="`{{s1003_outro_line2}}`" />
          </div>

          <div class="loading-dots">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

const Localize = defineAsyncComponent(function () { return import('@/components/TextLocalize.vue') })

export default {
  name: 'PageOutro',
  components: {
    Localize
  },
  data: () => {
    return {

    }
  },
  computed: {
    submitResult: function () {
      return this.$store.getters.getSubmitResult
    }
  },
  mounted: function() {
    var link = this.submitResult.Link

    if (link)
      setTimeout(function () {
        window.location = link
      }, 5000)
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/_variables.scss';

.container {
  display: flex;
  height: 100vh;
  align-items: center;
}

.card {
  text-align: center;
  padding: 40px 15px;
  font-size: 24px;
}

.brand-logo {
  margin-bottom: 30px;
  width: auto;
  max-height: 80px;
}

.page-form-row {
  line-height: normal;
}

.submit-text {
  text-align: center;
  margin: 10px 0;
}
</style>
