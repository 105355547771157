const vm = {
  state: {
    data: null,
  },
  getters: {
     getRealtorId: (state) => {
      return state.data
    },
  },
  mutations: {
    setRealtorId: (state, data) => {
      state.data = data
    }
  },
  actions: {

  }
}

export default vm
