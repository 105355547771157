const vm = {
  state: {
    toasts: [],
  },
  getters: {
    getToasts: (state) => {
      return state.toasts
    }
  },
  mutations: {
    closeToast: (state, toastid) => {
      if (!toastid)
        return

      var index = -1
      for (let i = 0; i < state.toasts.length; i++)
        if (state.toasts[i].id == toastid)
          index = i

      if (index !== -1)
        state.toasts.splice(index, 1)
    },
    openToast: (state, { text, type, options }) => {
      if (!text)
        return
      
      // Load options
      options = (typeof options == 'object') ? options : {}
      type = (type == 'info' || type == 'warning' || type == 'error' || type == 'success') ? type : 'default'
      const defaultOptions = {
        title: false
      }
      options = { ...defaultOptions, ...options }

      state.toasts.push({
	      id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
        text: text,
        type: type,
        timeout: new Date().getTime() + 6000,
        options: options
      })
    }
  },
  actions: {
    toastHeartbeat: ({ state, commit }) => {
      return new Promise((resolve, reject) => {
        var timestamp = new Date().getTime()
        var continueLoop = false

        do
        {
          continueLoop = false

          for (var i = 0; i < state.toasts.length; i++) {
            if (state.toasts[i].timeout === null)
              continue

            if (state.toasts[i].timeout < timestamp) {
              commit('closeToast', state.toasts[i].id)
              continueLoop = true
              break
	          }
	        }
        } while (continueLoop)

        resolve()
      })
    }
  }
}

export default vm
