<template>
  <div class="field-address">
    <Field
      :definition="street1"
      :suggestions="suggestionList"
      :group-index="groupIndex"
      @update:value="onUpdateStreet1"
      @update:suggestion="onSuggestion"
    />
    <Field
      :definition="street2"
      :group-index="groupIndex"
      @update:value="onUpdateStreet2"
    />
    <Field
      :definition="city"
      :group-index="groupIndex"
      @update:value="onUpdateCity"
    />
    <Field
      :definition="state"
      :group-index="groupIndex"
      @update:value="onUpdateState"
    />
    <Field
      :definition="zip"
      :group-index="groupIndex"
      @update:value="onUpdateZip"
    />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

import ApiClient from '@/services/apiclient.js'
import DefinitionLib from '@/services/definitions.js'
import FieldLib from '@/services/fields.js'
import Globals from '@/services/globals.js'
const Field = defineAsyncComponent(function () { return import('@/fields/FormField.vue') })

export default {
  name: 'FieldAddress',
  components: {
    Field
  },
  props: {
    definition: {
      required: true,
      validator: function (value) {
        if (process.env.NODE_ENV == 'development' && !value)
          return true
          
        if ((value || {}).Type !== Globals.FIELD_TYPES.FIELD_TYPE_ADDRESS)
          return false

        if (typeof (value || {}).Id !== 'string')
          return false

        return true
      }
    },
    groupIndex: {
      type: Number,
      default: 0
    }
  },
  emits: [
    'update:value'
  ],
  data: () => {
    return {
      street1: null,
      street2: null,
      city: null,
      state: null,
      zip: null,

      geolocation: {
        lat: null,
        lon: null
      },

      suggestBuffer: null,
      suggestionList: []
    }
  },
  computed: {
    safeDefinition: function () {
      return FieldLib.safeDefinition(this)
    },
    componentTest: function () {
      return this.suggestionList.length
    }
  },
  mounted: function() {
    this.street1 = DefinitionLib.address.createStreet1(this.safeDefinition)
    this.street2 = DefinitionLib.address.createStreet2(this.safeDefinition)
    this.city = DefinitionLib.address.createCity(this.safeDefinition)
    this.state = DefinitionLib.address.createState(this.safeDefinition)
    this.zip = DefinitionLib.address.createZip(this.safeDefinition)

    this.geolocate()
  },
  methods: {
    onUpdateStreet1: function($event) {
      if (this.suggestBuffer)
        clearTimeout(this.suggestBuffer)

      if (($event.Value || "").length > 1 && ($event.Value || "").indexOf(" ") !== -1)
        this.suggestBuffer = setTimeout(this.suggestAddress, 650)

      this.setValue({...this.getValue(), ...{ Street1: $event.Value }})
    },
    onUpdateStreet2: function($event) {
      this.setValue({...this.getValue(), ...{ Street2: $event.Value }})
    },
    onUpdateCity: function($event) {
      this.setValue({...this.getValue(), ...{ City: $event.Value }})
    },
    onUpdateState: function($event) {
      this.setValue({...this.getValue(), ...{ State: $event.Value }})
    },
    onUpdateZip: function($event) {
      this.setValue({...this.getValue(), ...{ Zip: $event.Value }})
    },
    getValue: function () {
      return this.$store.getters.getFormValueAtIndex(this.safeDefinition.Id, this.groupIndex) || {
        Street1: null,
        Street2: null,
        City: null,
        State: null,
        Zip: null
      }
    },
    setValue: function (value) {
      FieldLib.updateDisplayValue(this.safeDefinition, this.groupIndex, value, this.$emit)
    },
    onSuggestion: function (suggestion) {
      this.setValue({...this.getValue(), ...{ 
        Street1: suggestion.streetNumber + ' ' + suggestion.streetName,
        City: suggestion.municipality,
        State: suggestion.countrySubdivision,
        Zip: suggestion.postalCode
      }})
    },
    geolocate: function() {
      if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            this.geolocation.lat = position.coords.latitude
            this.geolocation.lon = position.coords.longitude
          }
        )
      }
    },
    suggestAddress: function() {
      var $this = this

      ApiClient.searchAddressFragment($this.getValue().Street1, this.geolocation.lat, this.geolocation.lon)
        .then(function (response) {
          $this.suggestionList = response || []
        })
    }
  }
}
</script>